import { useSelector } from "react-redux";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { getSessionToken } from "../dto/selectors";

import { QuizDetailService, Quiz, SubmitQuizBody } from "../../domain/services/courseQuiz.service";
import { useProfileDropDown } from "../../ui/components/ProfileDropDown/useProfileDropDown";
const baseUrl = process.env.REACT_APP_API_BASE

export function QuizDetailDataService(): QuizDetailService {
    const { user } = useProfileDropDown();
    const token = useSelector(getSessionToken);
    const { post } = httpService();
    return {
        async getQuiz({ success, error, quizId }: Quiz) {
            fetch(user?.roles[0] && user?.roles[0].slug === "professor" ? `${baseUrl}admin/${Endpoints.quizzes}/${quizId}` : `${baseUrl}${Endpoints.quizzes}/${quizId}/details`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        error(e);
                    }
                });

        },
        async submitQuiz({ success, error, body }: SubmitQuizBody) {
            try {
                const response = await post(`${Endpoints.answers}/process`, body)
                if (response) {
                    if (success) {
                        success(response.data.data)
                    }
                }
            } catch (err: any) {
                if (error) {
                    error(err.response.data.data)
                }
            }
        },

    }
}