import { useSelector } from "react-redux";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { getSessionToken } from "../dto/selectors";

import {
    CoursesDetailService, CoursesDetail,
    StartCourse, Assessment, Quiz, Question, Answer,
    ChapterDetails, DeleteQuestion, CourseGrade,
    StudentQuizGrade,
    PutSendFeeBack,
    GetStudentCourseQuiz,
    GetResntedUsers,
    ResgisterStudentToCourse,
    DeleteQUiz, ResetQUiz, QuestionAnswer
} from "../../domain/services/coursesDetail.service";
const baseUrl = process.env.REACT_APP_API_BASE

export function CourseDetailsDataService(): CoursesDetailService {
    const token = useSelector(getSessionToken);
    const { post, put, deleteRequest, get } = httpService();

    return {
        async getCourseDetails({ success, error, courseUuid, perPage, currentPage }: CoursesDetail) {
            fetch(`${baseUrl}${Endpoints.courses}/${courseUuid}/details?perPage=${perPage}&&currentPage=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {

                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        error(e);
                    }
                });

        },
        async getCharpterDetails({ success, error, chapterId, courseId }: ChapterDetails) {
            try {
                const response = await get(`/${Endpoints.courses}/${courseId}/chapters/${chapterId}`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async PostRentCourse({ success, error, courseUuid }: StartCourse) {
            fetch(`${baseUrl}${Endpoints.courses}/${courseUuid}/rent`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        window.location.href = data.data.url
                        success(data.data)
                    }
                })
                .catch(e => {
                    console.log(e)
                    if (error) {
                        error(e);
                    }
                });
        },

        async submitAssessment({ success, error, courseUuid, assementId, body }: Assessment) {
            try {
                const response = assementId ? await put(`/${Endpoints.admin}/assessments/${assementId}`, {
                    title: body.title,
                }) : await post(`/${Endpoints.admin}/assessments`, {
                    title: body.title, course_uuid: courseUuid
                });
                if (success) {
                    success(response.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async submitQuiz({ success, error, quizId, body }: Quiz) {
            try {
                const response = quizId ? await put(`/${Endpoints.admin}/quizzes/${quizId}`, {
                    title: body.title,
                }) : await post(`/${Endpoints.admin}/quizzes`, body)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },

        async submitQuestion({ success, error, questionId, questioBody }: Question) {
            try {
                const response = questionId ? await put(`/${Endpoints.admin}/${Endpoints.questions}/${questionId}`, { type: questioBody.type, quiz_id: questioBody.quizId, text: questioBody.title }) : await post(`/${Endpoints.admin}/${Endpoints.questions}`, { type: questioBody.type, quiz_id: questioBody.quizId, text: questioBody.title })
                if (success) {
                    success(response.data.data);
                    return response.data
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async deleteQuestion({ success, error, questionId }: DeleteQuestion) {
            try {
                const response = await deleteRequest(`/${Endpoints.admin}/${Endpoints.questions}/${questionId}`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async submitAnswer({ success, error, questionId, answerId, answerBody }: Answer) {
            try {
                const response = questionId ? await put(`/${Endpoints.admin}/${Endpoints.answers}/question/${questionId}`, answerBody) : await post(`/${Endpoints.admin}/${Endpoints.answers}`, answerBody)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async getCourseGrades({ success, error, courseId, currentPage }: CourseGrade) {
            try {
                const response = await get(`/${Endpoints.courses}/${courseId}/users?currentPage=${currentPage}`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async getStudentQuizGrades({ success, error, courseId, studentId }: StudentQuizGrade) {
            try {
                const response = await get(`/${Endpoints.admin}/${Endpoints.courses}/${courseId}/users/${studentId}`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async putSendFeeBack({ success, error, body, gradeId }: PutSendFeeBack) {
            try {
                const response = await put(`/${Endpoints.admin}/${Endpoints.quizzes}/${Endpoints.grades}/${gradeId}`, body)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        }, async getStudentCourse({ success, error, currentPage }) {
            try {
                const response = await get(`${Endpoints.courses}/my-courses?currentPage=${currentPage}`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async getStudentCourseQuiz({ success, error, courseId }: GetStudentCourseQuiz) {
            try {
                const response = await get(`/${Endpoints.courses}/my-courses/${courseId}/quizzes`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },

        async getRentedUsers({ success, error, courseId, currentPage }: GetResntedUsers) {
            try {
                const response = await get(`/${Endpoints.admin}/${Endpoints.courses}/${courseId}/rent/users?currentPage=${currentPage}`)
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async postRegisterStudentToCourse({ success, error, courseId, studentIds }: ResgisterStudentToCourse) {
            try {
                const response = await post(`/${Endpoints.admin}/${Endpoints.courses}/${courseId}/users`, {
                    user_ids: studentIds
                })
                if (success) {
                    success(response.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async deleteQuiz({ success, error, quizId }: DeleteQUiz) {
            try {
                const response = await deleteRequest(`/${Endpoints.admin}/${Endpoints.quizzes}/${quizId}`)
                if (success) {
                    success(response.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async resetQuiz({ success, error, quizId }: ResetQUiz) {
            try {
                const response = await deleteRequest(`/${Endpoints.admin}/${Endpoints.quizzes}/${quizId}`)
                if (success) {
                    success(response.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async deleteAnswer({ success, error, answerId }: QuestionAnswer) {
            try {
                const response = await deleteRequest(`/${Endpoints.admin}/${Endpoints.answers}/${answerId}`)
                if (success) {
                    success(response.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
    }
}