import { sesstionCardOptionEntity } from "../../../domain/entities";
import { useCaseLearningPathSessions } from "../../../domain/useCases/administratorSessionPage";
import { administratorSessionDataService } from "../../../data/services/administratorGetLearningPathSession";
import { useCustomEffect, useNavigation } from "../../hooks";
import { AxiosError } from "axios";
import { GroupLearningPathSessionEntity, LearniningPathSessionEntity } from "../../../data/models/learningPathSessionEntity.model";
import toast from "react-hot-toast";
import { useState } from "react";
import dayjs from "dayjs";
import { useProfileDropDown } from "../../components/ProfileDropDown/useProfileDropDown";
export const administratorSessionPageViewModel = () => {
    const { navigateTo } = useNavigation()
    const { user } = useProfileDropDown();

    const { getListLearningPathsSessions } = useCaseLearningPathSessions(administratorSessionDataService())
    const options: sesstionCardOptionEntity[] = [
        { name: "Editar", url: "/admin-learning-path-session", action: () => { } },
    ];
    const [getListOfGrpoupLearningPathsSession, setGetListGrpoupOfLearningPathSession] = useState<GroupLearningPathSessionEntity[]>([])


    function groupSessionsByLearningPath(sessions: LearniningPathSessionEntity[]) {
        const grouped: any = {};

        // Group sessions by learning_path_uuid
        sessions.forEach(session => {
            const uuid = session.learning_path_uuid;
            if (!grouped[uuid]) {
                grouped[uuid] = [];
            }
            grouped[uuid].push(session);
        });


        const result = Object.keys(grouped).map(uuid => {
            return {
                name: grouped[uuid][0].name,
                sessions: grouped[uuid]
            };
        });

        return result;
    }
    const sessessGetListLearningPathsSessions = (sessions: LearniningPathSessionEntity[]) => {
        // Show session that start time has pass current time
        const groupedSessions: any = groupSessionsByLearningPath(sessions.filter((session) => dayjs(session.starts_at) >= dayjs()));
        setGetListGrpoupOfLearningPathSession(groupedSessions)
    }

    const errorGetListLearningPathsSessions = (error: AxiosError) => {
        toast.error(error.message)
    }

    useCustomEffect(() => {
        if (user?.roles[0] &&
            user?.roles[0].slug !== "professor") {
            navigateTo("/")
        } else {
            getListLearningPathsSessions({
                success: sessessGetListLearningPathsSessions,
                error: errorGetListLearningPathsSessions,
            })
        }

    }, [])
    return {
        options,
        getListOfGrpoupLearningPathsSession
    }
}

