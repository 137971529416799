import React from "react";
import { SEO } from "../../components";
import { useViewAppModel } from "../../viewAppModel";
import { AppRoutes } from "../Routes";
import { useRouting } from "./useRouting";

export const Routing = () => {
  const { ScrollToTop, generalSEOData } = useRouting();
  ScrollToTop();
  useViewAppModel();
  return <AppRoutes />;
};
