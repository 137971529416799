import { AxiosError } from "axios";
import { useLayoutEffect, useRef, useState } from "react";
import { useCaseCourseDetail, useCaseDashboard, useCaseProductions } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useSelector } from "react-redux";
import { getSessionToken, getSubscriptionStatus, getUser } from "../../../data/dto/selectors";
import { ChapterEntity, CourseSession, ICoursesData, LearningPathDetailEntity, PaginationDataI, PriceEntity, ProductionEntity, QuizEntity, SeasonEntity } from "../../../domain/entities";
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { dashboardDataService, productionsDataService } from "../../../data/services";
import toast from "react-hot-toast";
import { ProductionCommentsModel } from "../../../data/models";
import { useDispatch } from "react-redux";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { CourseDetailModel } from "../../../data/models/courseDetail.model";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { assessmentSchema } from "../../validators";

export function useCourseDetailsDetailViewModel() {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(assessmentSchema) });

  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);
  const { getCourseDetails, postPostRentCourse, submitAssessment, getCharpterDetails, deleteQuiz, resetQuiz } =
    useCaseCourseDetail(CourseDetailsDataService());
  const { postChapterProgressDashboard } =
    useCaseDashboard(dashboardDataService());
  const { getById, getChapter, } = useCaseProductions(
    productionsDataService()
  );
  const playerRef = useRef<VideoJsPlayer | null>(null);
  const { getQueryParam, navigateTo } = useNavigation();
  const [openSubcriptionModal, setOpenSubcriptionModal] = useState(false)
  const [detailCourse, setDetailCourse] =
    useState<ICoursesData>({} as ICoursesData);
  const [currentProduction, setCurrentProduction] = useState<ProductionEntity>()
  const [savedUSerCurrentPgae, setSaveUserCurrentPage] = useState<number>(1)
  const [previousPageItems, setPreviousPageItems] = useState<CourseSession[]>([])
  const [assessment, setAsseement] = useState<CourseSession>({} as CourseSession)
  const [paymentSuccess, setPatmentSuccess] =
    useState<boolean>(false);
  const [videoPlayerCurrentTime, setVideoPlayerCurrentTime] =
    useState<number>(0);
  const [showLogInModal, setShowLoginModal] = useState(false);
  const [showAssesmentform, setShowAssesmentForm] = useState<boolean>(false)

  const [showStartPathModal, setShowStartPathModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [nextChapter, setNextChapter] = useState<ChapterEntity>()
  const [currentChapter, setCurrentChapter] = useState<ChapterEntity>({} as any)
  const [paginationData, setPaginationData] = useState<PaginationDataI | null>()
  const [roles, setRole] = useState<string>("")
  const courseuuid = getQueryParam("uuid");
  const paymentStatus = getQueryParam("status");
  const [filteredUserComments, setFilteredUserComments] = useState<ProductionCommentsModel>({} as ProductionCommentsModel)
  const [videoOptions, setVideoOptions] = useState<VideoJsPlayerOptions>({
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      fullscreenToggle: false,
      currentTimeDisplay: true,
    },
    responsive: true,
    fluid: true,
  });


  const itemPerPage = 5

  const [videoTimeLeft, setVideoTimeLeft] = useState<number>(0);
  const [courseDuration, setCourseDuration] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [disableFrom, setDisableFrom] = useState<number>(1)
  const [courseImagePreview, setCourseImagePreview] = useState("")
  const [changePage, setChangePage] = useState(false)
  const [getCurrentChapter, setGetCurrentChapter] = useState(true)
  const [showNotSubscribedModal, setShowNotSubscribedModal] = useState(false);
  const [transactionFinalStatus, setTransactionFinalStatus] = useState<
    "none" | "success" | "error"
  >("none");
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const successSubmitAssesment = async (assessment: any) => {
    const assessmentQuiz = localStorage.getItem("assessmentQuiz")
    navigateTo(`/quiz?courseId=${courseuuid}&production=&quiz=${assessmentQuiz !== "undefined" || assessmentQuiz !== null ? assessmentQuiz : ""}&type=assessment`)
    localStorage.removeItem("assessmentQuiz")
  }
  const errorSubmitAssesment = async (error: AxiosError) => {
  }


  const onSubmit = async (data: FieldValues) => {
    submitAssessment({
      success: successSubmitAssesment,
      error: errorSubmitAssesment,
      courseUuid: courseuuid,
      assementId: detailCourse.assessment?.id ? detailCourse.assessment?.id : null, body: { couseId: courseuuid, title: data.title }
    })
  };

  // learning paths chapter
  function successGetProductionChapter(chapter: ChapterEntity) {
    setCurrentChapter(chapter)
    if (chapter) {
      setCurrentChapter(chapter)


      handleNewVideoSource(chapter.videoUrl, chapter.imgPreview)
    }
  }

  function errorGetProductionChapter(error: any) {
    if (error.response?.data.code === 403) {
      toast.error("Por favor, complete primero las producciones anteriores.")
    }

  }

  const changeProduction = (productionNumber: number) => {
  }

  const onGetChapter = (chapterUuid: string) => {
    getCharpterDetails({
      success: successGetProductionChapter,
      error: errorGetProductionChapter,
      courseId: courseuuid,
      chapterId: chapterUuid
    })
  }

  const handleNewVideoSource = (source: string, poster?: string) => {
    if (source) {
      setVideoOptions({
        ...videoOptions,
        autoplay: "play",
        sources: [
          {
            src: source,
            type: "application/x-mpegURL",
          },
        ],
        poster: poster ? poster : videoOptions.poster,
      });

    } else {
      setVideoOptions({
        ...videoOptions,
        poster: poster ? poster : videoOptions.poster,
      });
    }
    window.scrollTo({
      top: 500,
      left: 0,
      behavior: "smooth",
    });
  };

  function getPageNumber(itemIndex: number, itemsPerPage: number, totalItems: number) {
    if (itemIndex < 0 || itemIndex >= totalItems) {
      return "Invalid item index."; // Or throw an error
    }

    const pageNumber = Math.floor(itemIndex / itemsPerPage) + 1;
    return pageNumber;
  }

  const handleShowAssesmentForm = () => {
    setShowAssesmentForm(!showAssesmentform)
  }

  function handleNotSubscribedModal(value: boolean) {
    setShowNotSubscribedModal(value);
  }

  function handleStartPath() {
    if (token) {
      setShowStartPathModal(true);
    } else {
      setShowLoginModal(true);
    }
  }

  function successPostCourseDetails(e: any) {
  }

  function errorPostCourse(e: AxiosError) {
    if (e?.response?.status === 401) {
      setShowLoginModal(true);
    } else if (e?.response?.status === 403) {
      setShowNotSubscribedModal(true);
    }
  }

  const handleStartCourse = () => {
    if (token) {
      postPostRentCourse({
        success: successPostCourseDetails,
        error: errorPostCourse,
        courseUuid: courseuuid,
      });
    } else {
      navigateTo("/login")
    }
  };


  const handleShowModal = () => {
    setOpenSubcriptionModal(!openSubcriptionModal)
  }

  function handleRedirectToFirstProduction() {
    setSuccessModal(false);
  }

  const handlePlayerReady = (player: VideoJsPlayer) => {
    let intervalProgress: number;
    let intervalCurrentTime: number;
    playerRef.current = player;
    if (userSubscription?.stripeStatus !== "active") {
      player.controlBar.addClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    } else {
      player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    }
    // You can handle player events here, for example:
    player.on("pause", () => {
      const currentTime = player.currentTime();
      const timeLeft = player.duration() - player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      setVideoTimeLeft(timeLeft);
      player.clearInterval(intervalProgress);
      // player.clearInterval(intervalCurrentTime);
    });


    player.on("play", () => {
      if (userSubscription?.stripeStatus !== "active") {

        player.controlBar.addClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.addClass("vjs-hidden-on-unsubscribe");
      } else {
        player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.removeClass("vjs-hidden-on-unsubscribe");
      }

      intervalProgress = player.setInterval(() => {
        const currentTime = player.currentTime();
        setVideoPlayerCurrentTime(currentTime);
      }, 5000);
    });

    player.on("dispose", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });
    player.on("ended", () => {
      const userCurrentCoursePage = localStorage.getItem("currentPage")
      handleGetCourseDetails(currentPage)
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
      if (userCurrentCoursePage) {
        handleGetCourseDetails(parseInt(userCurrentCoursePage))
        setCurrentPage(parseInt(userCurrentCoursePage))
      } else {
        handleGetCourseDetails(currentPage)
      }
    });


    player.on("timeupdate", () => {
      const timeLeft = player.duration() - player.currentTime();
      setVideoTimeLeft(timeLeft);
    });
  };

  const updateAvailability = (items: any[]) => {
    if (previousPageItems.length < 1) {
      const sessionArray = items.map((item, index, array) => {
        if (index === 0) {
          return { ...item, available: 0 };
        }
        const previousItem = array[index - 1];
        const isPreviousCompleted = previousItem.completed === 1;
        const isPreviousQuizCompleted = previousItem.quiz ? previousItem.quiz?.completed === true : previousItem.completed ? true : false;
        return { ...item, available: isPreviousCompleted && isPreviousQuizCompleted ? 0 : 0 };
      });
      setPreviousPageItems(sessionArray)
      return sessionArray
    } else {

      const sessionArray = items.map((item, index, array) => {
        if (index === 0) {
          return { ...item, available: 0 };
        }
        const previousItem = array[index - 1];
        const isPreviousCompleted = previousItem.completed === 1;
        const isPreviousQuizCompleted = previousItem.quiz ? previousItem.quiz?.completed === true : previousItem.completed ? true : false;
        return { ...item, available: isPreviousCompleted && isPreviousQuizCompleted ? 1 : 0 };
      });
      setPreviousPageItems(sessionArray)
      return sessionArray
    }
  };

  const handleTransactionModal = () => {
    navigateTo(`/course-detail?uuid=${courseuuid}`)
  }


  const successGetCouresDestails = (courseDetails: CourseDetailModel) => {
    setPaginationData({
      currentPage: courseDetails.productions.current_page, hasMorePages: courseDetails.productions.has_more_pages,
      perPage: courseDetails.productions.per_page, totalItems: courseDetails.productions.total_items, totalPages: courseDetails.productions.total_pages
    })
    let courseSection: any = []
    const courseProductions: any[] = courseDetails.productions.items.map((production, index) => {
      return {
        id: production.course_production_id ? production.course_production_id : 0,
        title: production.title, duration: production.duration, description: production.description,
        chapter: production.chapters, quiz: production.quiz, accessmentType: "production", link: "",
        imageUrl: production.imgPreview,
        completed: production.completed ? production.completed : 0,
        available: 0
      }
    })
    courseDetails.productions.items.length > 0 && setCurrentProduction(courseDetails.productions.items[0])
    const courseAssessment: any = courseDetails.assessment !== null ? { completed: 0, id: courseDetails.assessment.id, title: courseDetails.assessment.title, duration: 0, description: "", chapter: [], quiz: courseDetails.assessment.quiz, accessmentType: "assessment", link: "", imageUrl: "" } : null
    setAsseement(courseAssessment)

    let courseDuration = 0
    courseDetails.productions.items.forEach(item => {
      courseDuration += item.duration || 0;
    });
    if (courseAssessment !== null) {
      courseSection = updateAvailability([...courseProductions])
    } else {
      courseSection = updateAvailability([...courseProductions])
    }
    const data = {
      uuid: courseDetails.uuid,
      title: courseDetails.title,
      progress: courseDetails.progress,
      duration: 0,
      image: courseDetails.image,
      courseSection: courseSection,
      assessment: courseDetails.assessment,
      canWatch: courseDetails.canWatch,
      registered: courseDetails.registered,
      price: courseDetails.price,
      assessmentPresent: courseDetails.assessment ? true : false
    }
    setDetailCourse(data)
    setValue("title", courseDetails.assessment ? courseDetails.assessment.title : "");
    if (courseDetails.productions.items[0].chapters![0].uuid) {
      const lastAvailableItem = courseSection
        .filter((item: any) => item.available === 1)
        .pop()
      // if (getCurrentChapter) {
      //   courseDetails.canWatch && courseDetails.registered && onGetChapter(lastAvailableItem.chapter[0].uuid)
      //   setGetCurrentChapter(false)
      // }
    }

    // const productionYetCompleted = courseProductions.filter((item) => !item.completed)
    // const productionWithQuiz = courseProductions.filter((item) => item.quiz)
    // const productionWithQuizYetCompleted = productionWithQuiz.filter((item) => !item.quiz.completed)

    // if (productionWithQuizYetCompleted.length < 1 && productionYetCompleted.length < 1) {
    //   if (!changePage) {
    //     handleGetCourseDetails(currentPage + 1)
    //     setDisableFrom(currentPage + 1)
    //     setCurrentPage(currentPage + 1)
    //   }
    // } else {
    //   setDisableFrom(currentPage)
    // }
  }

  const handleGetCourseDetails = async (currentPage: number) => {
    setCurrentPage(currentPage)
    getCourseDetails({
      success: successGetCouresDestails,
      error: errorGetCouresDestails, courseUuid: courseuuid, perPage: itemPerPage, currentPage
    })
  }
  const errorGetCouresDestails = (error: any) => { }
  useLayoutEffect(() => {
    const userCurrentCoursePage = localStorage.getItem("currentPage")
    if (userCurrentCoursePage) {
      handleGetCourseDetails(parseInt(userCurrentCoursePage))
      setCurrentPage(parseInt(userCurrentCoursePage))
    } else {
      handleGetCourseDetails(currentPage)
    }

  }, [])

  const handleAllTimeRangesArray = (previewsTimeRanges: number[][]) => {
    const playerOut = playerRef.current;
    const timeRangesLength = playerOut?.played().length
      ? playerOut.played().length
      : 0;
    let currentTimeRanges = [[0, 1]];
    if (timeRangesLength) {
      for (let index = 0; index < timeRangesLength; index++) {
        const currentTimeRange = [
          playerOut?.played().start(index) as number,
          playerOut?.played().end(index) as number,
        ];
        currentTimeRange.length && currentTimeRanges.push(currentTimeRange);
      }
    }
    const finalTimeRangeArray = currentTimeRanges.length
      ? [...currentTimeRanges, ...previewsTimeRanges].sort(
        (a, b) => a[0] - b[0]
      )
      : previewsTimeRanges.sort((a, b) => a[0] - b[0]);

    let finalTimeRangeArraySummary = [];
    if (finalTimeRangeArray.length > 1 && finalTimeRangeArray[0].length > 1) {
      let previousRange = finalTimeRangeArray[0];
      for (let index = 1; index < finalTimeRangeArray.length; index++) {
        const currentRange = finalTimeRangeArray[index];
        if (currentRange[0] <= previousRange[1]) {
          previousRange = [
            previousRange[0],
            Math.max(previousRange[1], currentRange[1]),
          ];
        } else {
          finalTimeRangeArraySummary.push(previousRange);
          previousRange = currentRange;
        }
      }
      finalTimeRangeArraySummary.push(previousRange);
    }
    return {
      timeRanges: finalTimeRangeArraySummary,
    };
  };

  const deleteQuizSuccess = async (data: any) => {
    handleGetCourseDetails(currentPage)
  }
  const deleteQuizError = async (data: any) => {
    toast.dismiss()
    toast.error(data?.response?.data?.message)
  }

  const handleDeleteQuiz = (quizId: number) => {
    deleteQuiz({ success: deleteQuizSuccess, error: deleteQuizError, quizId: quizId })
  }
  const resetQuizSuccess = async (data: any) => {
    handleGetCourseDetails(currentPage)
  }
  const resetQuizError = async (data: any) => {
    toast.dismiss()
    toast.error(data?.response?.data?.message)
  }

  const handleResetQuiz = (quizId: number) => {
    deleteQuiz({ success: resetQuizSuccess, error: resetQuizError, quizId: quizId })
  }

  useCustomEffect(() => {
    if (currentChapter?.uuid && playerRef?.current?.player_) {
      const playerOut = playerRef.current;
      playerOut.ready(function () {
        var promise = playerOut.play();
        if (promise !== undefined) {
          promise
            .then(function () {
            })
            .catch(function (error) {
            });
        }
      });
      playerOut.currentTime(currentChapter.progress);
    }
  }, [currentChapter]);


  useCustomEffect(() => {
    if (currentChapter?.uuid && detailCourse.canWatch && currentChapter.uuid) {
      const timeRangesChapter = currentChapter.timeRanges
        ? currentChapter.timeRanges
        : [[0, 1]];
      const { timeRanges } = handleAllTimeRangesArray(timeRangesChapter);
      postChapterProgressDashboard({
        progress: Math.floor(videoPlayerCurrentTime),
        chapterUuid: currentChapter.uuid,
        timeRanges,
      });
    }
    localStorage.removeItem("currentProdction")
  }, [videoPlayerCurrentTime]);


  return {
    roles,
    userSubscription,
    showLogInModal,
    setShowLoginModal,
    showStartPathModal,
    setShowStartPathModal,
    showSuccessModal,
    navigateTo,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
    videoOptions,
    handleNewVideoSource,
    nextChapter,
    handlePlayerReady,
    videoTimeLeft,
    onChapterClick: onGetChapter,
    changeProduction,
    filteredUserComments,
    handleSubmit,
    assessment,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,
    token,
    handleStartCourse,
    detailCourse,
    paginationData,
    openSubcriptionModal,
    handleShowModal,
    courseDuration,
    currentProduction,
    currentChapter,
    handleShowAssesmentForm,
    showAssesmentform,
    paymentStatus,
    handleGetCourseDetails,
    handleDeleteQuiz,
    handleResetQuiz,
    disableFrom,
    courseImagePreview,
    currentPage

  };
}
