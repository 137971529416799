import tw from "twin.macro";
import styled from "styled-components";
export interface ButtonProps {
  color?: "primary" | "secondary" | "third";
  colorText?: string;
  className?: string;
}

export const Button = styled.button.attrs((props) => ({
  className: props.className,
}))<ButtonProps>`
  ${tw`
      inline-flex
      flex-row
      justify-center
      items-center
      p-3
      rounded-lg
      outline-none
      bg-primary-100
      
      text-white
      text-base
      font-primarySemibold
      leading-6
      
      lg:(px-6)
    `}

  ${({ color }) => color === "secondary" && tw`bg-transparent`}
  ${({ color }) => color === "third" && tw`bg-gray-500`}
  ${({ colorText }) => colorText && `color:${colorText} !important`}
  ${({ disabled }) => disabled && tw`opacity-50`}
`;
