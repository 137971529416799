import { useSelector } from "react-redux";
import { learningPathDetailInput, LearningPathDetailService, payMedalInput, startLearningPathInput } from "../../domain/services/learningPathDetail.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { BillingPortalModel } from "../models";
import { LearningPathDetailModel } from "../models/learningPathDetail.model";
import { Endpoints } from "./endpoints";
import { getSessionToken } from "../dto/selectors";
const baseUrl = process.env.REACT_APP_API_BASE

export interface listLearningPathsToRentDetailResponse {
    data: {
        data: LearningPathDetailModel;
    }
}

export interface PayMedalResponse {
    data: {
        data: BillingPortalModel;
    };
}

export function LearningPathDetailDataService(): LearningPathDetailService {
    const token = useSelector(getSessionToken);

    const { get, post } = httpService();
    return {
        async getListDetailLearningPaths({ success, error, learningPathUuid }: learningPathDetailInput) {
            fetch(`${baseUrl}${Endpoints.listLearningPathsToRent}/${learningPathUuid}/details`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        error(e);
                        console.log(error)
                    }
                });

        },
        async postLearningPath({ success, error, learningPathUuid }: startLearningPathInput) {
            const formData = new FormData();
            formData.append('learningPathUuid', learningPathUuid);
            fetch(`${baseUrl}${Endpoints.listLearningPathsToRent}/${learningPathUuid}/rent`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        window.location.href = data.data.url
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        error(e);
                    }
                });
        },
        async postPayMedal({ success, error, learningPathUuid, medalId }: payMedalInput) {
            try {
                const response: PayMedalResponse = await post(`${Endpoints.listLearningPathsToRent}/${learningPathUuid}/medals/${medalId}/payment`, { learningPathUuid, medalId });
                if (success) {
                    success(response.data.data.url);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        }
    }
}