import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useCaseCourses } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { CoursesDataService } from "../../../data/services/course.data.service";
import { CoursesEntity } from "../../../domain/entities";

interface courseResponseI {
  courses: CoursesEntity[],
  total_items: number
  total_pages: number
  current_page: number;
}

export function useCoursesViewModel() {
  const { getCurrentPathName, navigateTo } = useNavigation();
  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });
  const [coursesToRent, setCoursesToRent] = useState<
    Array<CoursesEntity>
  >([]);

  const { getListCoursesToRent, getListOrRentedCourse } = useCaseCourses(
    CoursesDataService()
  );

  function successGetCoursesToRent(
    courseResponse: courseResponseI
  ) {
    setCoursesToRent(courseResponse?.courses ? courseResponse.courses : []);
  }

  function errorGetListOfCoursesToRent(e: AxiosError) {
    console.log("errorGetListOfCoursesToRent", e);
  }

  const handleStartCourse = (uuid: string) => {
  };

  useCustomEffect(() => {
    getListOrRentedCourse({
      success: successGetCoursesToRent,
      error: errorGetListOfCoursesToRent,
    });
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setSizeScreen({ x: window.innerWidth, y: window.innerHeight });
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    sizeScreen,
    getCurrentPathName,
    navigateTo,
    handleStartCourse,
    coursesToRent
  };
}
