import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useStopwatch } from "react-timer-hook";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { userLoginSchema } from "../../validators";
import { useCaseSignIn } from "../../../domain/useCases";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { getSessionToken, getUser } from "../../../data/dto/selectors/session.selector";
import { useCustomEffect, useNavigation, useObjectToArray } from "../../hooks";
import { SessionModel } from "../../../data/models";

export function useLoginViewModel() {
  const { seconds, minutes, isRunning, pause, reset } = useStopwatch({
    autoStart: false,
  });

  const [newLocationState, setNewLocationState] = useState<{
    [key: string]: any;
  }>({});
  const { run } = useCaseSignIn(authenticationDataService());
  const token = useSelector(getSessionToken);
  const user = useSelector(getUser);
  const { sendVerificationLink } = authenticationDataService();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({ mode: "onChange", resolver: yupResolver(userLoginSchema) });
  const { navigateTo, getStateParam } = useNavigation();
  const [emailVerified, setEmailVerified] = useState(true);

  const onLogInError = (error: any) => {
    if (error?.response?.status != 422) {
      toast.error(error?.response?.data?.message ? error?.response?.data?.message : "An error occured");
    } else {
      const arrayOfErrors = useObjectToArray(error.response.data.errors);
      arrayOfErrors.forEach((currentError) => {
        setError(currentError.key, {
          type: "custom",
          message: currentError.value,
        });
      });
    }
  };

  const onSendLink = () => {
    const emailData = {
      email: user?.email || "",
      success: () => reset(),
      error: onLogInError,
    };
    sendVerificationLink(emailData);
  };

  const handleOnSuccessLogin = (data: SessionModel) => {
    if (!data.token && !data.user?.email_verified_at) {
      setEmailVerified(false);

      return;
    }
    if (!!newLocationState.nextPathname) {
      navigateTo(newLocationState.nextPathname, { previousPathname: "/login" });
    } else {
      navigateTo("/", { previousPathname: "/login" });
    }
  };
  const onSubmit = (data: FieldValues) => {
    const signInData = {
      email: data.email,
      password: data.password,
      success: handleOnSuccessLogin,
      error: onLogInError,
    };
    run(signInData);
  };

  useCustomEffect(() => {
    const nextPathname = getStateParam("nextPathname");
    const previousPathname = getStateParam("previousPathname");
    setNewLocationState({ nextPathname, previousPathname });

    if (token) navigateTo("/profile");
  }, []);

  useCustomEffect(() => {
    minutes === 1 && pause();
  }, [minutes]);

  return {
    handleSubmit,
    control,
    errors,
    emailVerified,
    isRunning,
    seconds,
    onSubmit,
    onSendLink,
  };
}
