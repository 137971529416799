import { DefaultLayout } from "../../layouts";
import { CourseSection } from "../studentAcessmentDashboard/components";

export const Courses = () => {
  return (
    <DefaultLayout>
      <div>
        <div className="w-full lg:px-28 px-0 ">
          <CourseSection />
        </div>
      </div>
    </DefaultLayout>
  );
};
