import { FC, useEffect, useRef } from "react";
import { useForm, Controller, FieldValues } from "react-hook-form";
import { Button, CheckBox, TextField } from "../../../components";
import toast from "react-hot-toast";
import { Trash2 } from "lucide-react";

interface MultipleSelectionFormProps {
  submitFormHandler: (data: any) => void;
  handlePrevious: () => void;
  quizQuestion: any[];
  currentQuestionIndex: number;
  handleDelete: (
    questionId: string,
    answerIndex: number,
    questionType: string
  ) => void;
}

export const MultipleSelectionForm: FC<MultipleSelectionFormProps> = ({
  submitFormHandler,
  quizQuestion,
  currentQuestionIndex,
  handlePrevious,
  handleDelete,
}) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      options: [""],
      correctAnswers: [] as string[],
    },
  });

  const formRef = useRef<(() => void) | null>(null);
  formRef.current = handleSubmit((data) => {
    submitFormHandler(data);
  });

  const submitForm = (data: FieldValues) => {
    if (data.options.some((option: string) => option.trim() === "")) {
      toast.error("Todas las opciones deben completarse antes de enviar.");
      return;
    }

    const optionSet = new Set(data.options.map((opt: string) => opt.trim()));
    if (optionSet.size !== data.options.length) {
      toast.error("Las opciones no pueden ser idénticas.");
      return;
    }

    if (data.correctAnswers.length === 0) {
      toast.error("Por favor seleccione una respuesta correcta.");
      return;
    }
    const newOptions = data.options.map((item: string) => ({
      content: item,
      is_correct: data.correctAnswers.includes(item),
    }));

    submitFormHandler({ type: "multiple_selection", answers: newOptions });
  };

  useEffect(() => {
    quizQuestion[currentQuestionIndex].answers;
    if (quizQuestion && quizQuestion[currentQuestionIndex]?.answers) {
      let options: string[] = [];
      const correctAnswers: string[] = [];
      quizQuestion[currentQuestionIndex].answers.forEach((item: any) => {
        options.push(item.content);
        if (item.is_correct) {
          correctAnswers.push(item.content);
        }
      });
      setValue("options", options.length >= 1 ? options : [""]);
      setValue("correctAnswers", correctAnswers);
    }
  }, [currentQuestionIndex]);

  const addOption = () => {
    setValue("options", [...getValues("options"), ""]);
  };

  const removeOption = (index: number) => {
    const currentOptions = getValues("options");
    if (currentOptions.length > 1) {
      setValue(
        "options",
        currentOptions.filter((_, i) => i !== index)
      );
    }
  };

  return (
    <div>
      <form className="bg-black px-6 rounded-lg shadow-md space-y-4 py-2">
        <div className="space-y-3">
          <div className="space-y-8">
            {watch("options").map((_, index) => (
              <div key={index} className="flex items-center space-x-2">
                <Controller
                  name="correctAnswers"
                  control={control}
                  render={({ field }) => (
                    <CheckBox
                      onChange={() => {
                        const selectedOption = getValues("options")[index];
                        const updatedCorrectAnswers = field.value.includes(
                          selectedOption
                        )
                          ? field.value.filter((opt) => opt !== selectedOption)
                          : [...field.value, selectedOption];

                        field.onChange(updatedCorrectAnswers);
                      }}
                      checked={field.value.includes(
                        getValues("options")[index]
                      )}
                    />
                  )}
                />
                <TextField
                  {...register(`options.${index}`, {
                    required: "La opción es obligatoria",
                    validate: (value) => {
                      const currentOptions = getValues("options").map((opt) =>
                        opt.trim()
                      );
                      if (
                        currentOptions.filter((opt) => opt === value.trim())
                          .length > 1
                      ) {
                        return "Las opciones no pueden ser idénticas.";
                      }
                      return true;
                    },
                  })}
                  className="w-full rounded-lg border-[1px] outline-none text-base font-primaryRegular text-black placeholder-gray-500"
                  placeholder={`Introducir opción ${index + 1}`}
                />
                {watch("options").length > 1 && (
                  <button
                    type="button"
                    onClick={() => {
                      handleDelete(
                        quizQuestion[currentQuestionIndex].question_id,
                        index,
                        "multiple_selection"
                      );
                      removeOption(index);
                    }}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <Trash2 size={20} />
                  </button>
                )}
                {errors.options?.[index] && (
                  <p className="text-red-500 text-sm">
                    {errors.options[index]?.message}
                  </p>
                )}
              </div>
            ))}
          </div>
          {!quizQuestion[currentQuestionIndex]?.question_id && (
            <Button
              type="button"
              onClick={addOption}
              color="secondary"
              className="text-primary-50 my-0"
            >
              Agregar opción
            </Button>
          )}
        </div>
        <div className="flex items-center justify-center gap-8 py-2">
          <Button
            onClick={handlePrevious}
            className="w-full"
            color="third"
            type="button"
            disabled={currentQuestionIndex < 1}
          >
            Previa
          </Button>
          <Button onClick={handleSubmit(submitForm)} className="w-full">
            {currentQuestionIndex + 1 < quizQuestion.length
              ? "Próxima"
              : "Entregar"}
          </Button>
        </div>
      </form>
    </div>
  );
};
