import { Dispatch, FC, SetStateAction } from "react";
import { Button, Modal } from "../../../../components";
import { useNavigation } from "../../../../hooks";
import { ReactComponent as RocketLogo } from "../../../../assets/images/rocketLogo.svg";
import { ReactComponent as RocketLogoDown } from "../../../../assets/images/rocketLogoDown.svg";

export interface ModalsLearningPathProps {
  showLogInModal: boolean;
  showStartPathModal: boolean;
  showSuccessModal: boolean;
  setShowStartPathModal: Dispatch<SetStateAction<boolean>>;
  handleStartLearningPath: (uuid: string) => void;
  learningPathId: string;
  learningPathTitle: string;
  medalImage?: string;
  setShowLoginModal: Dispatch<SetStateAction<boolean>>;
  handleRedirectToFirstProduction: () => void;
  handleNotSubscribedModal: (value: boolean) => void;
  showNotSubscribedModal: boolean;
  showTransactionModal: boolean;
  handleTransactionModal: () => void;
  transactionFinalStatus: "none" | "success" | "error";
}

export const ModalsLearningPath: FC<ModalsLearningPathProps> = ({
  showLogInModal,
  showStartPathModal,
  setShowStartPathModal,
  handleStartLearningPath,
  learningPathId,
  learningPathTitle,
  setShowLoginModal,
  showSuccessModal,
  medalImage,
  handleRedirectToFirstProduction,
  handleNotSubscribedModal,
  showNotSubscribedModal,
  showTransactionModal,
  handleTransactionModal,
  transactionFinalStatus,
}) => {
  const { navigateTo } = useNavigation();
  return (
    <div>
      <Modal
        showModal={showLogInModal}
        onClose={() => {
          setShowLoginModal(false);
        }}
      >
        <div className="text-center lg:px-12 ">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">¡Necesitas loguearte!</h3>
          <p className="font-primaryBook text-sm mb-4 lg:text-base lg:w-96">
            Antes de iniciar un Camino de Aprendizaje debes ingresar a tu cuenta
          </p>
          <p className="font-primaryBook text-sm lg:text-base mb-6">
            ¿Aún no cuentas con una?{" "}
            <span
              className="font-primaryBold text-primary-100"
              onClick={() => {
                navigateTo(`/signup`);
              }}
            >
              Regístrate aquí
            </span>
          </p>
          <Button
            onClick={() => {
              navigateTo(`/login`);
            }}
          >
            Ingresar
          </Button>
        </div>
      </Modal>
      <Modal
        showModal={showNotSubscribedModal}
        onClose={() => {
          handleNotSubscribedModal(false);
        }}
      >
        <div className="text-center lg:px-12 ">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">¡Necesitas suscribirte!</h3>
          <p className="font-primaryBook text-sm mb-4 lg:text-base lg:w-96">
            Antes de iniciar un Camino de Aprendizaje debes registrarte
          </p>
          <Button
            onClick={() => {
              navigateTo(`/subscriptions`);
            }}
          >
            Suscribirme
          </Button>
        </div>
      </Modal>

      <Modal showModal={showStartPathModal}>
        <div className="text-center lg:px-12 lg:py-8">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">
            ¡Elegiste iniciar un camino <br /> de aprendizaje!
          </h3>
          <div className="flex justify-center">
            {!!medalImage && <img src={medalImage} alt="BlueMedal" title="BlueMedal" />}
          </div>
          <p className="font-primaryBook text-sm mb-6 mt-4 lg:text-base">
            Al pulsar <span className="font-primaryBold">“Aceptar”</span> iniciarás el camino de aprendizaje <br />
            <span className="font-primaryBold">“{learningPathTitle}”</span>
            {/* fase <span className="font-primaryBold">Blue.</span> */}
          </p>
          <p></p>
          <div className="flex flex-row justify-around">
            <Button
              color="third"
              onClick={() => {
                setShowStartPathModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleStartLearningPath(learningPathId);
              }}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Modal>

      <Modal showModal={showSuccessModal}>
        <div className="text-center px-4 lg:px-12 lg:py-8">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">
            Iniciaste el camino de aprendizaje
          </h3>
          <h4 className="font-primaryBold text-primary-100 text-base mb-4 lg:text-base">“{learningPathTitle}”</h4>
          <p className="font-primaryBook text-sm mb-4 lg:text-base">
            Ya puedes iniciar tu proceso de certificación <span className="font-primaryBold">“Blue”</span>
          </p>
          <Button onClick={handleRedirectToFirstProduction}>Ver contenido del Paso 1</Button>
        </div>
      </Modal>

      <Modal showModal={showTransactionModal}>
        {transactionFinalStatus === "success" ? (
          <div className="max-w-xl text-center px-4 lg:px-12 lg:py-8">
            <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">Tu pago ha sido exitoso!</h3>
            <div className="flex justify-center">
              <RocketLogo />
            </div>
            <p className="font-primaryBook text-sm mb-4 lg:text-base">Puedes continuar con el camino de aprendizaje</p>
            <Button onClick={handleTransactionModal}>Aceptar</Button>
          </div>
        ) : (
          <div className="max-w-xl text-center px-4 lg:px-12 lg:py-8">
            <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">¡Ups! Ocurrió un error</h3>
            <div className="flex justify-center">
              <RocketLogoDown />
            </div>
            <p className="font-primaryBook text-sm mb-4 lg:text-base">
              No hemos podido completar tu solicitud de pago, por favor revisa los datos ingresados e inténtalo de nuevo
            </p>
            <Button onClick={handleTransactionModal}>Aceptar</Button>
          </div>
        )}
      </Modal>
    </div>
  );
};
