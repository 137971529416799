import { FC } from "react";
import { Button, Modal, TextFieldControlled } from "../../../../components";
import {
  useTeacherViewModel,
  useUpdateStudentScoreViewModel,
} from "../../../../viewModels";

interface GradeTableItemProps {
  id: number;
  name: string;
  email: string;
  completed: false;
  productionScore: number;
  assessmentScore: number;
  finalGrade: any;
  feedback: string;
  courseUserId: number;
  currentCourse: string;
}

export const GradeTableItem: FC<GradeTableItemProps> = ({
  id,
  name,
  email,
  completed,
  productionScore,
  assessmentScore,
  currentCourse,
  finalGrade,
}) => {
  const {
    navigateTo,
    handleShowModal,
    handleCloseModal,
    showModal,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    setError,
    getValues,
    errors,
  } = useUpdateStudentScoreViewModel();
  return (
    <tr key={`${id}-${id}`} className="hover:bg-gray-700">
      <td className="px-6 py-4 whitespace-nowrap">
        <div>
          <div className="text-sm font-medium text-white">{name}</div>
          <div className="text-sm text-white">{email}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {productionScore}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {assessmentScore}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {finalGrade ? finalGrade : 0}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {`${completed}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap"></td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        <span
          className="text-primary cursor-pointer text-sm font-base"
          onClick={() => {
            navigateTo(
              `/teacher/student/quiz?course=${currentCourse}&student=${id}`
            );
          }}
        >
          Todo el cuestionario
        </span>
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          backgroundColor="black"
        >
          <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-inputForm">
            <TextFieldControlled
              id={"feedback"}
              name="feedback"
              label="Comentario"
              placeholder=""
              error={!!errors.feedback}
              helperText={errors?.feedback?.message}
              control={control}
              required
            />

            <TextFieldControlled
              id={"score"}
              name="score"
              label="Puntaje"
              placeholder=""
              error={!!errors.score}
              helperText={errors?.score?.message}
              control={control}
              required
              type="number"
            />
            <Button className="w-full" onClick={handleSubmit(onSubmit)}>
              Entregar
            </Button>
          </div>
        </Modal>
      </td>
    </tr>
  );
};
