import { CoursesService } from "../../services/courses.service";
import { handleResponse } from "../../shared";

export function useCaseCourses(service: CoursesService) {
    return {
        getListCoursesToRent({ success, error }: handleResponse) {
            return service.getlistCoursesToRentToRent({ success, error });
        },
        getListOrRentedCourse({ success, error }: handleResponse) {
            return service.getlistOfRentedCourses({ success, error });
        },
    }
}