import { FC } from "react";
import { StyledPodcastContainer } from "./PodcastProductionsSection.styles";

import { ProductionEntity } from "../../../../../domain/entities";
import { Carousel, PodcastCard } from "../../../../components";

export interface PodcastProductionsSectionProps {
  podcastProductions: Array<ProductionEntity>;
}

export const PodcastProductionsSection: FC<PodcastProductionsSectionProps> = ({ podcastProductions }) => {
  return (
    <StyledPodcastContainer>
      <h3>Podcast</h3>
      <div className="w-full flex lg:pt-8">
        <Carousel
          slidesToShow={5}
          slidesToScroll={5}
          infinite={false}
          dots={true}
          arrows={false}
          centerMode={false}
          responsive={[
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                centerMode: false,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                centerPadding: "0px",
              },
            },
          ]}
        >
          {podcastProductions.map((currentPodcast, index) => (
            <PodcastCard className={"h-full mx-auto"} key={`P-${index}`} whitSeeMore podcastData={currentPodcast} />
          ))}
        </Carousel>
      </div>
    </StyledPodcastContainer>
  );
};
