import { FC } from "react";
import { Button, Modal, TextFieldControlled } from "../../../../components";
import { useTeacherViewModel } from "../../../../viewModels";
import dayjs from "dayjs";

interface StudentCourseQuizTableItemProps {
  id: number;
  title: string;
  description: string;
  course_uuid: string;
  quiz_type: string;
  difficulty_level: string;
  passing_score: number;
  duration: number;
  is_published: boolean;
  taken_at: string;
  grade: number;
  grade_id: any;
  questions_count: number;
  correct_answers: number;
  feedback: string | null;
}

export const StudentCourseQuizTableItem: FC<
  StudentCourseQuizTableItemProps
> = ({
  id,
  title,
  description,
  course_uuid,
  quiz_type,
  difficulty_level,
  passing_score,
  duration,
  is_published,
  taken_at,
  grade,
  grade_id,
  questions_count,
  correct_answers,
  feedback,
}) => {
  return (
    <tr key={`${id}-${id}`} className="hover:bg-gray-700">
      <td className="px-6 py-4 whitespace-nowrap">
        <div>
          <div className="text-sm font-medium text-white">{title}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {quiz_type}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {passing_score}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {grade ? grade : "Nil"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {questions_count}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {`${correct_answers}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {`${feedback != null ? feedback : "Nulo"}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {`${dayjs(taken_at).format("DD-MM-YYYY")}`}
      </td>
    </tr>
  );
};
