import { useSelector } from "react-redux";
import { BookOpen, Clock4, Play, Users } from "lucide-react";
import { DefaultLayout } from "../../layouts";
import { CourseAssessmentCard, CourseContentCard } from "./components";
import { VideoPreview } from "../preview/components";
import { convertToMinutesAndSeconds, useNavigation, useWindowSize } from "../../hooks";
import { useCourseDetailsDetailViewModel } from "../../viewModels";
import { Button, Modal, PageLoader, Paginator, TextFieldControlled } from "../../components";
import { StyledBlurWall } from "../preview/preview.styles";
import { useProfileDropDown } from "../../components/ProfileDropDown/useProfileDropDown";
import { ReactComponent as RocketLogo } from "../../assets/images/rocketLogo.svg";
import { ReactComponent as RocketLogoDown } from "../../assets/images/rocketLogoDown.svg";
import { getUser } from "../../../data/dto/selectors/session.selector";

export const CourseDetailsPage = () => {
  const user = useSelector(getUser);
  const { navigateTo } = useNavigation();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,
    token,
    detailCourse,
    handlePlayerReady,
    handleStartCourse,
    openSubcriptionModal,
    handleShowModal,
    showAssesmentform,
    handleShowAssesmentForm,
    currentChapter,
    currentProduction,
    videoOptions,
    onChapterClick,
    assessment,
    paymentStatus,
    handleTransactionModal,
    paginationData,
    handleGetCourseDetails,
    disableFrom,
    videoTimeLeft,
    courseImagePreview,
    handleDeleteQuiz,
    handleResetQuiz,
    currentPage,
  } = useCourseDetailsDetailViewModel();
  const { isDesktop } = useWindowSize();
  const getTotalChapterDuration = (items: any[]) => {
    return items.reduce((total, item) => {
      const chaptersDuration =
        item.chapter?.reduce((sum: number, chapter: any) => sum + (chapter.duration || 0), 0) || 0;
      return total + chaptersDuration;
    }, 0);
  };

  return (
    <DefaultLayout>
      {detailCourse.uuid ? (
        <div>
          <div
            className="relative bg-secondary-300 h-[25rem]"
            style={{
              backgroundImage: `url(${detailCourse.image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "400px",
            }}
          >
            <div
              className=" w-full top-0 left-0  flex items-end bg-gradient-to-t from-secondary-300 to-transparent"
              style={{
                height: "400px",
              }}
            >
              <div className="flex flex-col gap-2 px-8 lg:px-12 pb-8">
                <div className="flex items-center gap-4">
                  <div className="flex items-center justify-center">
                    <BookOpen className="text-white h-10 w-full" />
                  </div>
                  <h1 className="text-3xl font-bold text-white">{detailCourse.title}</h1>
                </div>
                <p className="text-white text-base font-primaryBook lg:text-lg">
                  {currentChapter.description && currentChapter.description}
                </p>
                <div className="flex gap-8 items-center">
                  {/* <div className="flex gap-2">
                    <p className="text-primary-50 font-primarySemibold">
                      Duración del curso:
                      <span className="text-white text-sm">
                        {currentChapter.duration
                          ? convertToMinutesAndSeconds(currentChapter.duration)
                          : convertToMinutesAndSeconds(
                              getTotalChapterDuration(detailCourse.courseSection)
                            )}
                      </span>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* course section */}
          <div className="lg:px-14 pt-12 pb-6  lg:flex gap-6 bg-secondary-300 rounded-xl">
            <div className="w-full lg:w-2/3 relative" style={{ width: isDesktop ? "60%" : "100%" }}>
              <VideoPreview
                videoOptions={videoOptions}
                imgPreview={`${currentChapter.imgPreview ? currentChapter.imgPreview : detailCourse.image}`}
                format={"Paquetes"}
                terms={[
                  {
                    uuid: "",
                    slug: "",
                    title: `${detailCourse.title}`,
                  },
                ]}
                duration={
                  currentChapter.duration
                    ? currentChapter.duration
                    : getTotalChapterDuration(detailCourse.courseSection)
                }
                handlePlayerReady={handlePlayerReady}
                timeLeft={videoTimeLeft}
                chapterName={currentChapter.name}
                productionName={"Producción"}
                currentSeasonNumber={1}
                nextChapterName={""}
                nextChapterImage={""}
                similarProductions={[]}
              />
              {!detailCourse.canWatch && (
                <p className="text-primary-50 text-2xl font-primaryRegular flex items-center justify-end w-full py-2 px-4">
                  <strong>
                    {detailCourse.price?.currency == "eur" ? "€" : "$"}{" "}
                    {detailCourse.price?.unitAmount ? detailCourse.price?.unitAmount / 100 : ""}
                  </strong>
                </p>
              )}
              <div className="grid grid-col gap-2 px-6 lg:p-6 place-items-start place-content-start pt-6">
                <h2 className="text-white font-bold text-lg lg:text-3xl">
                  {currentChapter.name ? currentChapter.name : detailCourse.title}
                </h2>

                <p className="text-white text-lg font-primaryBook lg:text-xl">
                  {currentChapter.description && currentChapter.description}
                </p>

                <div>
                  {user?.roles[0] &&
                    user?.roles[0]?.slug &&
                    user?.roles[0].slug !== "professor" &&
                    (detailCourse.canWatch === true && detailCourse.registered !== true ? (
                      <p className="text-primary-50">Pendiente de aprobación por el profesor </p>
                    ) : null)}
                </div>

                <div className="flex items-center justify-center">
                  <div>
                    {!detailCourse.canWatch && user?.roles[0]?.slug !== "professor" && (
                      <Button className="w-full lg:w-60" onClick={handleShowModal}>
                        Curso de alquiler
                      </Button>
                    )}
                  </div>
                  {user?.roles[0]?.slug === "professor" && (
                    <Button
                      className="w-full lg:w-80"
                      onClick={() => {
                        navigateTo(`/register-users-to-course?course=${detailCourse.uuid}`);
                      }}
                    >
                      Asignar curso a la estudiante
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div
              className="w-full mt-6 lg:mt-0 bg-black rounded-md px-4 relative"
              style={{ width: isDesktop ? "40%" : "100%" }}
            >
              {!token && <StyledBlurWall />}

              {(!detailCourse.canWatch || !detailCourse.registered) &&
                user?.roles[0] &&
                user?.roles[0]?.slug &&
                user?.roles[0].slug !== "professor" && <StyledBlurWall />}
              <h2 className="text-lg text-white font-bold  py-6">Contenido del curso</h2>
              {detailCourse.courseSection &&
                detailCourse.courseSection.map((recentActivity, index: number) => {
                  return (
                    <CourseContentCard
                      currentPage={currentPage}
                      handleResetQuiz={handleResetQuiz}
                      available={recentActivity.available}
                      courseProgress={detailCourse.progress}
                      handleStartChapter={onChapterClick}
                      previousCompleted={index > 0 ? detailCourse.courseSection[index - 1]!.completed : 0}
                      assessmentExist={detailCourse.assessment ? true : false}
                      chapters={recentActivity.chapter}
                      isCompleted={recentActivity.completed}
                      courseId={detailCourse.uuid}
                      productionId={recentActivity.id}
                      quiz={recentActivity.quiz}
                      title={recentActivity.title}
                      duration={recentActivity.duration}
                      accessmentType={recentActivity.accessmentType}
                      link={recentActivity.link}
                      imageUrl={recentActivity.imageUrl ? recentActivity.imageUrl : ""}
                      handleDeleteQuiz={handleDeleteQuiz}
                      key={index}
                      description={recentActivity.description}
                    />
                  );
                })}

              {detailCourse.courseSection && detailCourse.courseSection.length < 1 && (
                <h1 className="font-primaryRegular text-primary-50">El curso no tiene contenido.</h1>
              )}
              {paginationData && paginationData?.totalPages > 1 && (
                <Paginator
                  className="mx-auto mt-8"
                  page={paginationData?.currentPage}
                  totalPages={paginationData ? paginationData?.totalPages : 1}
                  onPageChange={(event, page) => {
                    handleGetCourseDetails(page);
                  }}
                />
              )}
              <div className="pt-6">
                <h2 className="text-lg text-white font-bold  py-6">Evaluación del curso</h2>
              </div>
              {!showAssesmentform && detailCourse.assessment && (
                <CourseAssessmentCard
                  available={detailCourse.progress && parseInt(detailCourse.progress) > 98 ? 1 : 0}
                  handleDeleteQuiz={handleDeleteQuiz}
                  courseProgress={detailCourse.progress}
                  handleStartChapter={onChapterClick}
                  previousCompleted={1}
                  assessmentExist={detailCourse.assessment ? true : false}
                  isCompleted={1}
                  courseId={detailCourse.uuid}
                  quiz={assessment.quiz}
                  title={assessment.title}
                  duration={assessment.duration}
                  accessmentType={assessment.accessmentType}
                  link={assessment.link}
                  imageUrl={""}
                  description={assessment.description}
                  handleShowAssementForm={() => {
                    localStorage.setItem("assessmentQuiz", `${assessment!.quiz?.id}`);
                    handleShowAssesmentForm();
                  }}
                />
              )}

              {showAssesmentform && (
                <div className="flex flex-col gap-6 w-full ">
                  <TextFieldControlled
                    id={"title"}
                    name="title"
                    label="Título de la evaluación"
                    placeholder=""
                    error={!!errors.title}
                    helperText={errors?.title?.message}
                    control={control}
                    required
                  />

                  <div className="flex flex-col items-center gap-4 w-full ">
                    <Button className="w-full" onClick={handleSubmit(onSubmit)}>
                      Crear evaluación
                    </Button>
                    <Button className="w-full" color="third" onClick={handleShowAssesmentForm}>
                      Cancelar
                    </Button>
                  </div>
                </div>
              )}

              {user?.roles[0] && user?.roles[0]?.slug && user?.roles[0].slug == "professor" && !assessment && (
                <div>
                  {!showAssesmentform && (
                    <div className="flex items-center gap-4">
                      <Button
                        className="w-full lg:w-60"
                        onClick={() => {
                          handleShowAssesmentForm();
                        }}
                      >
                        {detailCourse?.assessmentPresent ? "Actualizar evaluación" : "Crear evaluación"}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
      <Modal showModal={openSubcriptionModal} onClose={handleShowModal} backgroundColor="white">
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-inputForm">
          <h3 className="text-2 font-primaryBold text-primary-100 tracking-tight">
            {token ? `${"¡Has elegido comenzar este curso!"}` : "¡Necesitas loguearte!"}
          </h3>
          <p className="font-primaryBook text-xs tracking-tight lg:text-base">
            {token
              ? `Al hacer clic en “Aceptar” comenzarás el Curso “ ${detailCourse.title} ”`
              : `Antes de elegir una de nuestras suscripciones debes ingresar a tu
            cuenta.`}
          </p>
          {token ? (
            <div className="flex flex-row justify-around mt-2 w-full">
              <Button
                color="third"
                onClick={() => {
                  handleShowModal();
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  handleStartCourse();
                }}
              >
                Aceptar
              </Button>
            </div>
          ) : (
            <div>
              <p className="flex gap-x-2 font-primaryBook text-xs tracking-tight lg:text-base mt-4">
                ¿Aún no cuentas con una?
                <span
                  className="text-primary-100 cursor-pointer font-primaryBold"
                  onClick={() => navigateTo("/signup")}
                >
                  Regístrate aquí
                </span>
              </p>
              <Button onClick={() => navigateTo("/login")}>Ingresar</Button>
            </div>
          )}
        </div>
      </Modal>

      <Modal showModal={paymentStatus === "success" ? true : false}>
        {paymentStatus === "success" ? (
          <div className="max-w-xl text-center px-4 lg:px-12 lg:py-8">
            <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">Tu pago ha sido exitoso!</h3>
            <div className="flex justify-center">
              <RocketLogo />
            </div>
            <p className="font-primaryBook text-sm mb-4 lg:text-base">Puedes continuar con el camino de aprendizaje</p>
            <Button onClick={handleTransactionModal}>Aceptar</Button>
          </div>
        ) : (
          <div className="max-w-xl text-center px-4 lg:px-12 lg:py-8">
            <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2">¡Ups! Ocurrió un error</h3>
            <div className="flex justify-center">
              <RocketLogoDown />
            </div>
            <p className="font-primaryBook text-sm mb-4 lg:text-base">
              No hemos podido completar tu solicitud de pago, por favor revisa los datos ingresados e inténtalo de nuevo
            </p>
            <Button onClick={handleTransactionModal}>Aceptar</Button>
          </div>
        )}
      </Modal>
    </DefaultLayout>
  );
};
