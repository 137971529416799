import { SelectChangeEvent, Tooltip } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { ChangeEvent, useState } from "react";
import { RentalsModel } from "../../../data/models";
import { rentalsDataService } from "../../../data/services/rentals.data.service";
import { RentalEntity, PillEntity, FormatEntity, OptionsSelectEntity } from "../../../domain/entities";
import { useCaseRentals } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { StyledSelectTable } from "../../pages/rentals/rentals.styles";
import { useModal } from "../../context/ModalContext";

enum CurrencySymbol {
  eur = "€",
  usd = "$",
}

interface CellValuesRental {
  row: RentalEntity;
}

export function useRentalsViewModel() {
  const { navigateTo } = useNavigation();
  const { showLoading, hideLoading } = useModal();

  const [rentals, setRentals] = useState<RentalsModel>({} as RentalsModel);
  const [rows, setRows] = useState<Array<RentalEntity> | []>([]);
  const [formats, setFormats] = useState<Array<FormatEntity> | []>([]);
  const [formatOptions, setFormatOptions] = useState<Array<OptionsSelectEntity>>([{ id: "reset", name: "FORMATO" }]);
  const [formatOptionsMobile, setFormatOptionsMobile] = useState<Array<OptionsSelectEntity>>([
    { id: "reset", name: "BUSCAR POR FORMATO" },
  ]);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    ProductionType: "",
  });
  const { getFormats, getRentalsFiltered } = useCaseRentals(rentalsDataService());

  const columns: GridColumns = [
    {
      field: "title",
      flex: 1,
      headerName: "ALQUILER PRODUCCIONES",
    },
    {
      field: "terms",
      flex: 1,
      headerName: "ÁREA TEMÁTICA",
      renderCell: (cellValues: CellValuesRental) => {
        if (!!cellValues.row.terms?.length) {
          const termsRow = cellValues.row.terms;
          const finalTitlesArray = termsRow.map((term) => term.title);
          const finalTitlesString = finalTitlesArray.toString().replace(/,/g, " - ");
          return (
            <Tooltip title={finalTitlesString}>
              <p className="flex flex-col text-center">
                <span className="capitalize">{termsRow[0]?.title.toLowerCase()}</span>
                {termsRow[1]?.title && <span className="capitalize">{termsRow[1]?.title.toLowerCase()}</span>}
              </p>
            </Tooltip>
          );
        } else {
          return (
            <p className="flex flex-col text-center">
              <span className="capitalize"></span>
            </p>
          );
        }
      },
      sortComparator: (v1: PillEntity[], v2: PillEntity[]) => {
        const finalTitlesArray1 = v1.map((term) => term.title);
        const finalTitlesArray2 = v2.map((term) => term.title);
        const finalTitlesString1 = finalTitlesArray1.toString().replace(/,/g, " - ");
        const finalTitlesString2 = finalTitlesArray2.toString().replace(/,/g, " - ");
        return finalTitlesString1.localeCompare(finalTitlesString2);
      },
    },
    {
      field: "format",
      flex: 0.7,
      headerName: "FORMATO",
      renderHeader: () => (
        <StyledSelectTable
          id={"contactMatter"}
          name="contactMatter"
          placeholder="FORMATO"
          onBlur={(e) => e.stopPropagation()}
          onChange={handleFormatFilterChange}
          options={formatOptions}
          selectColor="secondary"
          defaultValue={"reset"}
        />
      ),
    },
    {
      field: "units",
      headerName: "UNIDADES",
      flex: 1,
      renderCell: (cellValues: CellValuesRental) => {
        if (cellValues.row.units) {
          const unitsArray = cellValues.row.units.split(/[,+]/);
          return (
            <Tooltip title={`${cellValues.row.units}`}>
              <p className="flex flex-col justify-center text-center">
                {unitsArray[0] && <span className="capitalize">{unitsArray[0].toLowerCase()}</span>}
                {unitsArray[1] && <span className="capitalize">{unitsArray[1].toLowerCase()}</span>}
              </p>
            </Tooltip>
          );
        } else {
          return (
            <p className="flex flex-col text-center">
              <span></span>
            </p>
          );
        }
      },
      sortComparator: (v1: string, v2: string) => {
        let unitsAmountArray1 = [0];
        let unitsAmountArray2 = [0];

        if (v1) {
          unitsAmountArray1 = v1.split(/[,+]/).map((currentUnit: string) => {
            const currentAmount = currentUnit?.match(/(\d+)/);
            if (currentAmount) {
              return +currentAmount[0]; // "+" operator converts string to number
            }
            return 0;
          });
        }

        if (v2) {
          unitsAmountArray2 = v2.split(/[,+]/).map((currentUnit: string) => {
            const currentAmount = currentUnit?.match(/(\d+)/);
            if (currentAmount) {
              return +currentAmount[0]; // "+" operator converts string to number
            }
            return 0;
          });
        }

        const totalUnits1 = unitsAmountArray1.reduce((partialSum, a) => partialSum + a, 0);
        const totalUnits2 = unitsAmountArray2.reduce((partialSum, a) => partialSum + a, 0);

        if (totalUnits2 > totalUnits1) return 1;
        else if (totalUnits2 < totalUnits1) return -1;
        else return 0;
      },
    },
    {
      field: "duration",
      headerName: "DURACIÓN",
      flex: 0.5,
      renderCell: (cellValues: CellValuesRental) => {
        return `${Math.round(cellValues.row.duration / 60)} min.`;
      },
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "price",
      headerName: "PRECIO",
      flex: 0.4,
      renderCell: (cellValues: CellValuesRental) => {
        const finalPrice = cellValues.row.price
          ? (cellValues.row.price?.unitAmount / 100.0).toString().replace(".", ",")
          : "";
        return `${CurrencySymbol[cellValues.row.price?.currency]} ${finalPrice}`;
      },
      sortComparator: (v1, v2) => parseInt(v1.unitAmount) - parseInt(v2.unitAmount),
    },
    {
      field: "callToAction",
      sortable: false,
      headerName: "",
      flex: 0.4,
      renderCell: (cellValues: any) => {
        return (
          <div
            style={{
              cursor: "pointer",
              color: "#E30613",
              fontFamily: "Carnero-Regular, sans-serif",
              fontSize: "16px",
            }}
            onClick={(e: any) => navigateTo(`/rentals?uuid=${cellValues.row.uuid}`)}
          >
            Ver más
          </div>
        );
      },
    },
  ];

  /**Handlers**/

  //request Rentals
  const handleSuccessRentalsFiltered = (rentals: RentalsModel) => {
    hideLoading();
    setRentals(rentals);
    rentals.items.length ? setRows(rentals.items) : setRows([]);
  };
  const handleErrorRentalsFiltered = (e: AxiosError) => {
    hideLoading();
  };

  //Filter
  const handleFormatFilterChange = (event: SelectChangeEvent<unknown>) => {
    if (event.target.value === "reset") {
      setFilter({ ...filter, ProductionType: "", page: 1 });
    } else {
      const optionSelected = formatOptions.find((formatOption) => formatOption.id === event.target.value);
      const formatSelected = formats.find((format) => format.title === optionSelected?.name);

      formatSelected && setFilter({ ...filter, ProductionType: formatSelected?.machineName, page: 1 });
    }
  };

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setFilter({ ...filter, page: page });
  };

  //Formats List
  const handleSuccessFormatsList = (formats: Array<FormatEntity>) => {
    const finalFormatsOptions = formats.map((currentFormat, index) => ({
      id: index + 1,
      name: currentFormat.title,
    }));
    setFormats(formats);
    setFormatOptions([{ id: "reset", name: "FORMATO" }, ...finalFormatsOptions]);
    setFormatOptionsMobile([{ id: "reset", name: "BUSCAR POR FORMATO" }, ...finalFormatsOptions]);
  };

  useCustomEffect(() => {
    showLoading();
    getRentalsFiltered({
      filter: filter,
      success: handleSuccessRentalsFiltered,
      error: handleErrorRentalsFiltered,
    });
  }, [filter]);

  useCustomEffect(() => {
    getFormats({
      success: handleSuccessFormatsList,
    });
  }, []);
  return {
    rentals,
    rows,
    columns,
    formatOptions,
    formatOptionsMobile,
    handleFormatFilterChange,
    handlePageChange,
  };
}
