import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { useSelector } from "react-redux";
import { getSessionToken } from "../dto/selectors";
import { CoursesService } from "../../domain/services/courses.service";
import { useProfileDropDown } from "../../ui/components/ProfileDropDown/useProfileDropDown";
const baseUrl = process.env.REACT_APP_API_BASE

export function CoursesDataService(): CoursesService {
    const { user } = useProfileDropDown();
    const token = useSelector(getSessionToken);
    const { get } = httpService();
    return {
        async getlistOfRentedCourses({ success, error }: handleResponse) {
            fetch(`${baseUrl}${Endpoints.courses}/rent`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        console.log(e)
                        error(e);
                    }
                });
        },
        async getlistCoursesToRentToRent({ success, error }: handleResponse) {
            // Axios was unable to format the data response from the backend
            fetch(`${baseUrl}${Endpoints.listLearningPathsToRent}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        console.log(e)
                        error(e);
                    }
                });
        }
    }
}