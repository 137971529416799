import { useState } from "react";
import { Breadcrumb, Button, Carousel, Navbar } from "../../components";
import { DefaultLayout } from "../../layouts";
import ConfirmModalSession from "./components/ConfirmationSessionModal/ConfirmModalSession";
import SessionCard from "./components/sessionCard/SessionCard";
import { sessionPageViewModel } from "../../viewModels/SessionPageViewModel";
import AttendanceSessionModal from "./components/AttendanceSessionModal/AttendanceSessionModal";

const SessionPage = () => {
  const {
    learningPathId,
    learningPathSessions,
    selectedSession,
    handleRegisterForLearningPathSession,
    registeredSession,
    handleDownloadCertificate,
    showAttendace,
    closeModalHandler,
    showModal,
    handleOpenModal,
    handleUnRegisterForLearningPathSession,
  } = sessionPageViewModel();

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Caminos de Aprendizaje",
      url: "/learning-path",
    },
    {
      name: "Camino",
      url: `/learning-path-detail?uuid=/${learningPathId}`,
    },
    {
      name: "Sesión",
    },
  ];

  function openGmail(email: string) {
    const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    window.open(gmailURL, "_blank");
  }

  return (
    <DefaultLayout isAbsolute>
      <div className="relative  w-full">
        <div className="pt-24 lg:pt-36 bg-secondary-300 ">
          <div className="my-8 mx-12">
            <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
          </div>
          <div className="relative flex size-full min-h-screen flex-col px-8 w-full max-w-[90rem] mx-auto">
            <div className="flex items-center p-4 pb-2 justify-between">
              <h2 className="text-primary-50 text-2xl lg:text-3xl font-bold  flex-1 text-center pr-12">
                Sesión programada
              </h2>
            </div>
            <div className="mx-8 ">
              {learningPathSessions.length > 0 ? (
                !registeredSession.id ? (
                  <Carousel
                    slidesToShow={3}
                    slidesToScroll={1}
                    infinite={false}
                    dots={false}
                    arrows={true}
                    responsive={[
                      {
                        breakpoint: 1280,
                        settings: {
                          slidesToShow: 2.5,
                          slidesToScroll: 1,
                          infinite: false,
                          dots: false,
                          arrows: true,
                          centerMode: false,
                        },
                      },
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 1.5,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: false,
                          arrows: true,
                          centerMode: true,
                        },
                      },
                      {
                        breakpoint: 900,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: false,
                          arrows: true,
                          centerMode: true,
                        },
                      },
                    ]}
                  >
                    {learningPathSessions.map((session) => (
                      <SessionCard learningPathSession={session} handleOpenModal={handleOpenModal} />
                    ))}
                  </Carousel>
                ) : (
                  <div className="max-w-[67.5rem] lg:mx-auto ">
                    <SessionCard learningPathSession={registeredSession} handleOpenModal={handleOpenModal} />
                  </div>
                )
              ) : (
                <div className="my-4">
                  <h2 className="font-primaryBold text-lg lg:text-2xl text-center text-white">
                    No hay sesiones disponibles para esta ruta de aprendizaje{" "}
                  </h2>
                  <p className="text-center text-white font-medium py-2">
                    Contacta con nosotras en{"  "}
                    <a
                      className="text-primary-50 font-medium cursor-pointer"
                      // onClick={() => {
                      //   openGmail("info@storytraining");
                      // }}
                    >
                      info@storytraining
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <ConfirmModalSession
            learningPathSession={selectedSession}
            isRegistered={registeredSession.id ? true : false}
            onClose={closeModalHandler}
            showModal={showModal}
            handleRegisterForLearningPathSession={handleRegisterForLearningPathSession}
            handleUnRegisterForLearningPathSession={handleUnRegisterForLearningPathSession}
          />
        )}

        <AttendanceSessionModal
          learningPathSession={selectedSession}
          isRegistered={registeredSession.id ? true : false}
          onClose={closeModalHandler}
          showModal={showAttendace}
          handleDownloadCertificate={handleDownloadCertificate}
        />
      </div>
    </DefaultLayout>
  );
};

export default SessionPage;
