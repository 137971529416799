import { EllipsisVertical } from "lucide-react";
import { FC, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { url } from "inspector";
import { sesstionCardOptionEntity } from "../../../../domain/entities";
import { LearniningPathSessionEntity } from "../../../../data/models/learningPathSessionEntity.model";
import dayjs from "dayjs";
import { Button } from "../../../components";
import { useNavigation } from "../../../hooks";

interface learningPathSessionCardProps {
  options: sesstionCardOptionEntity[];
  session: LearniningPathSessionEntity;
}
const LearningPathSessionCard: FC<learningPathSessionCardProps> = ({
  options,
  session,
}) => {
  const { navigateTo } = useNavigation();
  const [showOption, setShowOption] = useState(false);
  return (
    <div className="flex items-center flex-col mx-4">
      <ClickAwayListener onClickAway={() => setShowOption(false)}>
        <div className="relative flex flex-col items-start gap-2  px-5 min-h-[72px] py-4 justify-between bg-white rounded-2xl  w-full">
          <button
            className="absolute top-2 right-2 hover:bg-gray-200 p-2 flex items-center justify-center w-[42px] h-[42px] rounded-full"
            onClick={() => setShowOption(!showOption)}
          >
            <EllipsisVertical color="black" />
          </button>
          {showOption && (
            <div className="absolute right-8 top-6 bg-white w-28 shadow-md">
              {options.map((item, index: number) => (
                <button
                  key={index}
                  className="px-4 py-2 flex item-center justify-start border-b-[1px] border-solid border-gray-200 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    navigateTo(
                      `/admin-learning-path-session/?uuid=${session.id}`
                    );
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}

          <div className="flex items-center gap-4">
            <div className="flex flex-col justify-center">
              <h3 className=" text-2xl font-bold leading-normal line-clamp-1">
                Sesión
              </h3>
            </div>
          </div>
          <div className="flex flex-start gap-4">
            <div className="flex flex-col  gap-2">
              <div>
                <p className="py-0">Estado</p>
                <span className="text-sm text-primary-50 py-0">
                  {session.status === "confirmed" ? "Confirmada" : "Cancelada"}
                </span>
              </div>
              <div className="">
                <p className="py-0 text-sm">Empezar en</p>
                <span className="text-sm text-primary-50 py-0">
                  {`${dayjs(session.date).format("dddd")}`.slice(0, 3)},{" "}
                  {`${dayjs(session.date).format("DD")}`.slice(0, 3)}{" "}
                  {`${dayjs(session.date).format("MMMM")}`.slice(0, 3)}{" "}
                  {dayjs(session.date).format("YYYY")}
                </span>
              </div>
            </div>
            <div className="flex flex-col  justify-start gap-2">
              <div>
                <p className="py-0">recuento de usuarios</p>
                <span className="text-sm text-primary-50 py-0">
                  {session.users_count}
                </span>
              </div>
              <div className="mb-4">
                <p className="py-0 text-sm">Tiempo</p>
                <span className="text-sm text-primary-50 py-0">
                  {dayjs(session.starts_at).format("HH:mm A")}
                  {" - "}
                  {dayjs(session.ends_at).format("HH:mm A")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ClickAwayListener>
      <div className="flex item-center justify-center mt-4">
        <Button
          className="w-32"
          onClick={() => {
            navigateTo(
              `/session-details?uuid=${session.learning_path_uuid}&&session=${session.id}`
            );
          }}
        >
          Ver más
        </Button>
      </div>
    </div>
  );
};

export default LearningPathSessionCard;
