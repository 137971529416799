import { FC } from "react";
import { StyledSelectField } from "../../../../preview/components/SeasonsSection/SeasonsSection.styles";
import { ChapterCard } from "../../../../../components";
import { ChapterEntity, LearningPathDetailEntity, SeasonInterface } from "../../../../../../domain/entities";
import { LearningPathProductionModel } from "../../../../../../data/models/learningPathProduction.model";
const LearningPathProduction: FC<LearningPathProductionModel> = ({
  production,
  onChapterClick,
  changeProduction,
  productionOptions,
  currentProductionNumber,
}) => {
  return (
    <section id="SeasonsSection" className=" px-4 max-w-preview mx-auto w-full flex flex-col">
      {!!production && (
        <div>
          <StyledSelectField
            value={currentProductionNumber}
            options={productionOptions}
            onChange={(e) => {
              changeProduction(e.target.value);
            }}
          />
          <div className="flex flex-col gap-y-6">
            {production.productionType === "serie" ? (
              production.seasons.length > 0 ? (
                production.seasons.map((season: SeasonInterface, i: number) => {
                  return (
                    <div key={`season-${i}`}>
                      <p className="font-primaryBold text-lg lg:text-2xl text-center text-white pb-4">{season.title}</p>

                      {season.chapters.length > 0
                        ? season.chapters.map((chapter: ChapterEntity, index: number) => (
                            <ChapterCard
                              onClick={onChapterClick ? () => onChapterClick(chapter.uuid, 0) : () => {}}
                              key={`card${index}`}
                              chapterData={chapter}
                            />
                          ))
                        : null}
                    </div>
                  );
                })
              ) : (
                <p className="font-primaryBold text-lg lg:text-2xl text-center text-white">
                  Esta producción no tiene capítulo.
                </p>
              )
            ) : (
              <div>
                {production?.chapters &&
                  production.chapters.map((chapter: ChapterEntity, i: number) => {
                    return (
                      <ChapterCard
                        onClick={onChapterClick ? () => onChapterClick(chapter.uuid, 0) : () => {}}
                        key={`card${i}`}
                        chapterData={chapter}
                      />
                    );
                  })}
                {parseInt(String(production.chapters!.length)) < 1 && (
                  <p className="font-primaryBold text-lg lg:text-2xl text-center text-white">
                    Esta producción no tiene capítulo.
                  </p>
                )}
              </div>
            )}

            {/* <div>
              {production?.seasons &&
                production?.seasons?.chapters.map((chapter) => {
                  return <div></div>;
                })}
            </div> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default LearningPathProduction;
