import { FC, useState } from "react";
import { DefaultLayout } from "../../layouts";
import { Breadcrumb, AvatarFieldControlled, Button, Navbar, Modal } from "../../components";
import { useProfileViewModel } from "../../viewModels";
import {
  StyledProfileContainer,
  StyledProfileInfoSection,
  StyledUserInfoContainer,
  StyledProductionsInfoContainer,
  StyledButtonEdit,
  StyledProfileSubscriptionSection,
  StyledSubscriptionImage,
} from "./profile.styles";
import { ReactComponent as EmailIcon } from "../../assets/images/emailIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/locationIcon.svg";
import { ReactComponent as ProfileProductionsSavedIcon } from "../../assets/images/profileProductionsSavedIcon.svg";
import { ProductionsIcon } from "../../assets/Icons";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import { NotificationSection } from "./components/NotificationSection";
import { EditProfileSection } from "./components/EditProfileSection";

export const Profile: FC = () => {
  const {
    breadcrumbItemsElements,
    currentSubscription,
    userAvatar,
    userData,
    userSubscription,
    handleBillingCallToAction,

    handleSubmit,
    control,
    errors,
    editProfile,
    handleEditProfile,

    notifications,
    handleDeleteNotification,
    handleGetNotifications,

    countriesData,
    handleSubmitAboutMe,
    controlAboutMe,
    errorsAboutMe,
    navigateTo,
    handleSubmitPassword,
    controlPassword,
    errorsPassword,
    handleEditProfilePassword,
    handleEditProfileAboutMe,

    showConfirmationModal,
    handleCloseEditSection,

    useGetLeftDaysHours,
  } = useProfileViewModel();

  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      <div className="px-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledProfileContainer>
        <StyledProfileInfoSection>
          <div className="w-full max-w-theme mx-auto flex flex-col lg:flex-row lg:justify-between items-center">
            <StyledUserInfoContainer>
              <h1>MI PERFIL</h1>
              <AvatarFieldControlled
                id="avatar"
                name="avatar"
                control={control}
                editAvatar={editProfile}
                defaultAvatar={userAvatar?.fileUrl}
              />
              {/* <Avatar sx={{ width: 148, height: 148 }} src={avatarIcon}></Avatar> */}
              <div className="flex flex-col gap-y-4">
                <h3>{userData?.name}</h3>
                <div className="flex flex-col gap-y-4">
                  <p>
                    <span>
                      <EmailIcon />
                    </span>
                    {userData?.email}
                  </p>
                  <p>
                    <span>
                      <LocationIcon />
                    </span>
                    {userData?.country.name}
                  </p>
                  {editProfile ? (
                    <StyledButtonEdit onClick={handleSubmit(handleEditProfile)} color="secondary" colorText="#C1101A">
                      Guardar cambios
                    </StyledButtonEdit>
                  ) : (
                    <StyledButtonEdit onClick={handleEditProfile} color="secondary" colorText="#C1101A">
                      Editar
                    </StyledButtonEdit>
                  )}
                </div>
              </div>
            </StyledUserInfoContainer>
            {userData?.roles[0]?.name !== "professor" ? (
              <StyledProductionsInfoContainer>
                <div className="flex flex-col items-center">
                  <h4>0</h4>
                  <p>
                    <span>
                      <ProductionsIcon />
                    </span>
                    Producciones
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <h4>{userData?.productionsSaved}</h4>
                  <p>
                    <span>
                      <ProfileProductionsSavedIcon />
                    </span>
                    Guardados
                  </p>
                </div>
              </StyledProductionsInfoContainer>
            ) : null}
          </div>
        </StyledProfileInfoSection>

        {!editProfile ? (
          userData?.roles[0]?.name !== "professor" ? (
            <div>
              <StyledProfileSubscriptionSection>
                <div className="mx-auto w-full max-w-theme flex flex-col items-end lg:items-start">
                  <div>
                    <h2>
                      MI SUSCRIPCIÓN
                      <span>
                        <ProductionsIcon className="hidden lg:block" width="40px" height="40px" />
                        <ProductionsIcon className="block lg:hidden" width="18px" height="18px" />
                      </span>
                    </h2>
                    {userSubscription?.stripeStatus === "active" ? (
                      <p className={`flex ${userSubscription?.onTrial ? "flex-col lg:flex-row" : ""}`}>
                        {currentSubscription.rest}
                        <span>{currentSubscription.lastWord.toLowerCase()}</span>
                      </p>
                    ) : (
                      <p>
                        {userSubscription?.stripeStatus === "inactive" && !!userSubscription.endsAt
                          ? `Prueba gratuita ${useGetLeftDaysHours(userSubscription.endsAt)}`
                          : "Sin suscripción activa"}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative w-full max-w-theme flex justify-center mx-auto">
                  <StyledSubscriptionImage src={subscriptionsUfo} />

                  {!userSubscription?.onTrial && (
                    <Button
                      className="mx-auto top-14 lg:top-0 z-10 absolute lg:relative"
                      onClick={handleBillingCallToAction}
                    >
                      {userSubscription?.stripeStatus === "active"
                        ? "Administrar mi suscripción"
                        : "Ir a suscripciones"}
                    </Button>
                  )}
                </div>
              </StyledProfileSubscriptionSection>
              <NotificationSection
                notifications={notifications}
                handleGetNotifications={handleGetNotifications}
                handleDeleteNotification={handleDeleteNotification}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center p-10">
              <Button
                className="w-full lg:w-64"
                onClick={() => {
                  navigateTo(`/admin-learning-path-session`);
                }}
              >
                Crear sesión
              </Button>
            </div>
          )
        ) : (
          <EditProfileSection
            countriesData={countriesData}
            handleSubmitAboutMe={handleSubmitAboutMe}
            controlAboutMe={controlAboutMe}
            errorsAboutMe={errorsAboutMe}
            editProfile={editProfile}
            handleEditProfile={handleEditProfile}
            handleSubmitPassword={handleSubmitPassword}
            controlPassword={controlPassword}
            errorsPassword={errorsPassword}
            handleEditProfilePassword={handleEditProfilePassword}
            handleEditProfileAboutMe={handleEditProfileAboutMe}
            showConfirmationModal={showConfirmationModal}
            handleCloseEditSection={handleCloseEditSection}
          />
        )}
      </StyledProfileContainer>
    </DefaultLayout>
  );
};
