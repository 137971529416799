import tw from "twin.macro";
import styled from "styled-components";

interface StyledLearningPathsSectionProps {
  className?: string;
}

export const StyledLearningPathsLandingContainer = styled.section.attrs({
  className: "StyledLearningPathsLandingContainer",
})`
  ${tw`text-white text-center`}
  h1,h2 {
    ${tw`font-primaryBold text-[32px]`}
  }
  p {
    ${tw`font-primaryRegular `}
  }
`;

export const StyledMedalsSection = styled.div.attrs({
  className: "StyledMedalsSection",
})`
  ${tw`lg:(bg-secondary-300)`}
  h1 {
    ${tw`lg:(pt-16 pb-8)`}
  }
  p {
    ${tw`lg:(pb-8)`}
  }
`;

export const StyledLearningPathsSection = styled.div.attrs<{ className?: string }>((props: any) => ({
  className: `StyledLearningPathsSection ${props.className}`,
}))`
  h2 {
    ${tw`pt-[40px] pb-[24px] lg:(py-16)`}
  }
`;
