import { QuizDetailService, Quiz, SubmitQuizBody } from "../../services/courseQuiz.service";

export function useCaseCourseQuiz(service: QuizDetailService) {
    return {
        async getQuizDetails({ success, error, quizId }: Quiz) {
            return service.getQuiz({ success, error, quizId });
        },
        async submitQuiz({ success, error, body }: SubmitQuizBody) {
            return service.submitQuiz({ success, error, body });
        },
    }
}