import * as yup from "yup";
import { alphaLetterSchema, emailSchema, passwordSchema } from "../commonSchemas";
import { string } from "yup/lib/locale";

export const userSignUpSchema = yup.object({
  email: emailSchema.required("Campo requerido"),
  name: alphaLetterSchema.required("Debes escribir al menos un nombre"),
  lastName: alphaLetterSchema.required("Debes escribir al menos un Apellido"),
  isBusinessUser: yup.boolean().optional(),
  invitationCode: yup
    .string()
    .max(15)
    .when("isBusinessUser", {
      is: true,
      then: (schema) => schema.required("El código de invitación es obligatorio para usuarios empresariales"),
      otherwise: (schema) => schema.optional(),
    }),
  country: yup.string().notOneOf(["none"], "Campo requerido").required("Campo requerido"),
  password: passwordSchema,
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Debes aceptar los términos y condiciones")
    .required("Campo requerido"),
  privacyPolicy: yup
    .boolean()
    .oneOf([true], "Debes aceptar la política de consulta y tratamiento de datos personales")
    .required("Campo requerido"),
});
