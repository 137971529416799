import { FC } from "react";
import { StyledPodcastContainer } from "./Podcast.styles";

import { CategoryEntity } from "../../../../../domain/entities";
import { Carousel, PodcastCard } from "../../../../components";

export interface PodcastSectionProps {
  podcastProductions: CategoryEntity;
}

export const Podcast: FC<PodcastSectionProps> = ({ podcastProductions }) => {
  const { title, description, productions } = podcastProductions;
  return (
    <StyledPodcastContainer>
      <h3>{title.toUpperCase()}</h3>
      <p>{description}</p>

      <div className="w-full flex lg:pt-8">
        <Carousel
          slidesToShow={5}
          slidesToScroll={5}
          infinite={false}
          dots={true}
          arrows={false}
          centerMode={false}
          responsive={[
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                centerMode: false,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                centerPadding: "0px",
              },
            },
          ]}
        >
          {productions.map((currentPodcast, index) => (
            <PodcastCard
              className={"h-full mx-auto"}
              key={`Podcast-${index}`}
              whitSeeMore
              podcastData={currentPodcast}
            />
          ))}
        </Carousel>
      </div>
    </StyledPodcastContainer>
  );
};
