import { useState } from "react";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useForm, FieldValues } from "react-hook-form";
import { useCaseCoupons, useCaseProfile } from "../../../domain/useCases";
import {
  authenticationDataService,
  couponsDataService,
} from "../../../data/services";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";
import { CouponsRedemptionModel } from "../../../data/models";
import { CouponDataEntity } from "../../../domain/entities";

export function useCodeValidationViewModel() {
  const { navigateTo, getStateParam } = useNavigation();
  const token = useSelector(getSessionToken);

  const userSubscription = useSelector(getSubscriptionStatus);

  const [showModalStatusCode, setShowModalStatusCode] = useState(false);
  const [showModalProductionRedemption, setShowModalProductionRedemption] =
    useState(false);
  const [codeRedemptionData, setCodeRedemptionData] =
    useState<CouponDataEntity>();
  const [finalStatusCode, setFinalStatusCode] = useState<
    "none" | "success" | "error"
  >("none");
  const [codeError, setCodeError] = useState(0);
  const [breadcrumbItemsElements, setBreadcrumbItemsElements] = useState([
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Suscripciones",
      url: "/subscriptions",
    },
    {
      name: "Validar Código",
    },
  ]);
  const [showGoToLoginModal, setShowGoToLoginModal] = useState(false);
  const [newLocationState, setNewLocationState] = useState<{
    [key: string]: any;
  }>({ previousPathname: "/code-validation" });

  const { redeem } = useCaseCoupons(couponsDataService());
  const { getProfile } = useCaseProfile(authenticationDataService());

  /*Code Validation */

  const handleCloseGoToLoginModal = () => {
    setShowGoToLoginModal(false);
  };

  const handleCloseStatusModal = () => {
    setShowModalStatusCode(false);
    setFinalStatusCode("none");
  };

  const handleCloseStatusModalOnSuccess = (url: string) => {
    navigateTo(url);
    setFinalStatusCode("none");
    setShowModalStatusCode(false);
    getProfile({});
  };

  const handleSuccessCodeValidation = (couponData: CouponsRedemptionModel) => {
    // navigateTo(url);
    // setFinalStatusCode("none");
    // setShowModalStatusCode(fals
    if (couponData.success && couponData.data?.type === "subscriptionTrial") {
      getProfile({});
      setFinalStatusCode("success");
      setShowModalStatusCode(true);
    } else {
      setCodeRedemptionData(couponData.data);
      setShowModalProductionRedemption(true);
    }
  };

  const handleErrorCodeValidation = (data: any) => {
    setCodeError(data.response.data.code);
    setFinalStatusCode("error");
    setShowModalStatusCode(true);
  };

  const onSubmitValidationCode = (data: FieldValues) => {
    if (token) {
      redeem({
        coupon: data.codeValidation,
        success: handleSuccessCodeValidation,
        error: handleErrorCodeValidation,
      });
    } else {
      setNewLocationState({
        ...newLocationState,
        nextPathname: "/code-validation",
      });
      setShowGoToLoginModal(true);
    }
  };

  const handleValidationCodeForm = () => {
    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({ mode: "onChange" });
    return {
      handleSubmit,
      control,
      errors,
    };
  };

  useCustomEffect(() => {
    const previousPathname = getStateParam("previousPathname");
    if (userSubscription?.stripeStatus === "active") {
      navigateTo("/profile");
    }

    if (previousPathname === "/login") {
      setBreadcrumbItemsElements([
        {
          name: "Inicio",
          url: "/",
        },
        {
          name: "Inicia Sesión",
          url: "/profile",
        },
        {
          name: "Validar Código",
        },
      ]);
    } else if (previousPathname === "") {
      setBreadcrumbItemsElements([
        {
          name: "Inicio",
          url: "/",
        },
        {
          name: "Validar Código",
        },
      ]);
    }
  }, []);

  return {
    navigateTo,
    breadcrumbItemsElements,

    showModalStatusCode,
    finalStatusCode,
    handleCloseStatusModal,
    handleCloseStatusModalOnSuccess,
    onSubmitValidationCode,
    handleValidationCodeForm,

    showModalProductionRedemption,
    codeRedemptionData,
    codeError,

    showGoToLoginModal,
    handleCloseGoToLoginModal,
    newLocationState,
  };
}
