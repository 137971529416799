import { CheckCircle, XCircle } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "../../../../components";
import { result } from "lodash";

interface QuizResultsProps {
  score: number;
  totalQuestions: number;
  correctAnswers?: number;
  incorrectAnswers?: number;
  failedMessage: string;
  passingScore: number;
}

export function QuizResults({
  score,
  totalQuestions,
  correctAnswers,
  incorrectAnswers,
  failedMessage,
  passingScore,
}: QuizResultsProps) {
  const passed = score >= 75;
  const navigate = useNavigate();

  return (
    <div className="bg-secondary-200 rounded-lg p-8 text-white">
      <div className="text-center mb-8">
        {passed ? (
          <CheckCircle className="h-16 w-16 text-green-500 mx-auto mb-4" />
        ) : (
          <XCircle className="h-16 w-16 text-red-500 mx-auto mb-4" />
        )}
        <h2 className="text-2xl font-bold mb-2">{passed ? "Enhorabuena" : failedMessage}</h2>
        <div className="text-gray-400">
          {!failedMessage && (
            <p>
              <span className="text-primary font-medium">{score}% </span>de aciertos
            </p>
          )}
        </div>
      </div>
      {!failedMessage && (
        <div className="flex flex-col w-full items-center justify-center gap-4 mb-8">
          <div className="flex items-center gap-6">
            <div className="bg-black p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-green-500">75</p>
              <p className="text-sm text-gray-400">Puntuación de aprobación</p>
            </div>
            <div className="bg-black p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-primary-50">{score}</p>
              <p className="text-sm text-gray-400">Tu calificación</p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div className="bg-black p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-green-500">{correctAnswers}</p>
              <p className="text-sm text-gray-400">Respuestas correctas</p>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center space-x-4">
        <Button
          color="third"
          onClick={() => {
            navigate(-1);
          }}
        >
          Volver al curso
        </Button>
        {!passed && (
          <Button
            onClick={() => {
              location.reload();
            }}
          >
            Intentar otra vez
          </Button>
        )}
      </div>
    </div>
  );
}
