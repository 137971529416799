import { useState } from "react";
import { SelectionOptionButton } from "../SelectionOptionButton";

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface QuizQuestionProps {
  id: string;
  questionType: "multiple-choice" | "single-choice" | "matching-choice";
  question: string;
  options: any[];
  onAnswer: (questionId: string, questionType: string, answer: any) => void;
}

export function QuizQuestion({
  id,
  question,
  options,
  questionType,
  onAnswer,
}: QuizQuestionProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<any>({
    id: "",
    content: "",
  });

  const handleAnswer = (
    questionId: string,
    questionType: string,
    answer: any
  ) => {
    onAnswer(questionId, questionType, [answer]);
    setSelectedAnswer(answer);
  };
  return (
    <div className="bg-black rounded-lg p-6">
      <h3 className="text-2xl font-medium mb-6">{question}</h3>
      <div className="space-y-4">
        {options.map((option, index) => (
          <SelectionOptionButton
            options={options}
            key={index}
            correct={
              selectedAnswer && selectedAnswer.answer_id === option.id
                ? true
                : false
            }
            option={option}
            questionId={id}
            questionType={questionType}
            index={index}
            onAnswer={handleAnswer}
          />
        ))}
      </div>
    </div>
  );
}
