import { FC } from "react";
import { useNavigation } from "../../hooks";
import { StatusCard } from "../StatusCard";
import {
  StyledDescriptionGold,
  StyledDescriptionGreen,
  StyledMedalCard,
  StyledStepContainer,
} from "./MedalCard.styles";
import checkedCircle from "./../../assets/images/checkCircle.svg";
import { ReactComponent as Sparkle } from "./../../assets/images/sparkle.svg";
import { PriceEntity } from "../../../domain/entities";
import { Rating } from "../Rating";
import { Button } from "../Button";

export interface MedalCardProps {
  title: string;
  description?: string;
  image: string;
  size: string;
  path?: Array<any>;
  location?: string;
  status?: string;
  startedPath?: boolean;
  navigateTo: (url: string, state?: { [key: string]: any } | undefined) => void;
  handlePayMedal?: (learningPathId: string, medalId: string) => void;
  learningPathId?: string;
  medalId?: string;
  certifiedBy?: Array<{ name: string }>;
  price?: PriceEntity;
  currentPath?: string;
  scheduled?: {};
}

enum CurrencySymbol {
  eur = "€",
  usd = "$",
}

export const MedalCard: FC<MedalCardProps> = ({
  title,
  description,
  image,
  size,
  path,
  location,
  status,
  startedPath,
  navigateTo,
  handlePayMedal,
  learningPathId,
  medalId,
  certifiedBy,
  price,
  currentPath,
  scheduled,
}: MedalCardProps) => {
  return (
    <StyledMedalCard backgroundColor={title} size={size} location={location} status={status} startedPath={startedPath}>
      <div className=" flex flex-col justify-center items-start h-full pb-4">
        <div className="relative mt-4 flex flex-col items-center justify-center w-full ">
          <div className="relative  w-full">
            <img src={image} />
            {/* <div
              className="w-full h-96 border-[1px] border-solid border-gray-200 rounded-xl"
              style={{
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div> */}
            <Sparkle className="Sparkle" />
          </div>
          <h2 className="text-center my-4">{title?.toLocaleUpperCase()}</h2>
        </div>
        {currentPath === "/learning-path" ? (
          <div className="flex items-center justify-center w-full border-primary border-solid border-1">
            <Button onClick={() => navigateTo("/subscriptions")}>Sobar más</Button>
          </div>
        ) : !path && medalId !== "blue_4eC39Hq" ? (
          <div>
            {!!price && (
              <p className="font-primarySemibold text-xl mb-2">{`${CurrencySymbol[price.currency]}${
                price.unitAmount / 100
              } ${price.currency.toUpperCase()}`}</p>
            )}
            <p className="font-primaryBold text-base">
              {medalId === "gold_4eC39Hq" ? "Asistencia Curso" : "Prueba de conocimientos"}
            </p>
            <StyledDescriptionGold
              className="mb-2"
              dangerouslySetInnerHTML={{
                __html: description ? description : "",
              }}
            />
            {!!certifiedBy?.length && (
              <div className="mb-6">
                <p className="font-primaryBold text-sm">Certificado Por:</p>
                {certifiedBy.map((university) => {
                  return <p className="font-primaryRegular text-xs">{university.name}</p>;
                })}
              </div>
            )}
          </div>
        ) : (
          !!path?.length &&
          path.map((step) => {
            return (
              <StyledStepContainer className="text-left flex flex-row">
                {step.isChecked && startedPath && <img src={checkedCircle} />}
                <div className="stepInfo flex flex-col w-64 mb-6">
                  <p className="font-bold text-base">Paso {step.step}:</p>
                  <p
                    className="font-primaryRegular text-sm cursor-pointer"
                    onClick={() => {
                      if (startedPath && !step.isChecked) {
                        navigateTo(`/preview?uuid=${step.id}`);
                      }
                      if (!startedPath && step.isChecked) {
                        navigateTo(`/preview?uuid=${step.id}`);
                      }
                    }}
                  >
                    {step.title}
                  </p>
                </div>
              </StyledStepContainer>
            );
          })
        )}
        {location === "detail" && status && medalId && (
          <StatusCard
            medalId={medalId}
            status={status}
            isScheduled={!!scheduled}
            learningPathId={learningPathId}
            handlePayMedal={handlePayMedal}
          />
        )}
      </div>
    </StyledMedalCard>
  );
};
