import { ThreeDots } from "react-loader-spinner";
import { ReactComponent as StoryTrainingLogo } from "../../assets/images/storyTrainingLogo.svg";

export const PageLoader = () => {
  return (
    <div className="h-full lg:h-96 w-full flex items-center justify-center flex-col">
      <ThreeDots
        visible={true}
        height="20"
        width="80"
        color={"#E30613"}
        radius="10"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
