import { useState } from "react";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useCaseSubscriptions, useCaseProfile } from "../../../domain/useCases";
import {
  subscriptionsDataService,
  authenticationDataService,
} from "../../../data/services";
import { SubscriptionEntity } from "../../../domain/entities";
import companySubscription from "../../assets/images/companySubscription.svg";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";

export function useSubscriptionDetailViewModel() {
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Suscripciones",
      url: "/subscriptions",
    },
    {
      name: "Mensual",
    },
  ];

  const { get, getCheckout } = useCaseSubscriptions(subscriptionsDataService());
  const { getProfile } = useCaseProfile(authenticationDataService());
  const { navigateTo, getQueryParam } = useNavigation();
  const selectedPriceId = getQueryParam("priceId");
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);
  const [subscriptionsList, setSubscriptionsList] = useState<
    Array<SubscriptionEntity> | []
  >([]);
  const [transactionFinalStatus, setTransactionFinalStatus] = useState<
    "none" | "success" | "error"
  >("none");

  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const onSuccessSubscriptionList = (data: Array<SubscriptionEntity>) => {
    setSubscriptionsList([
      ...data.map((item: any) => ({
        name: item.name,
        description: item.description || "Sin descripción",
        images:
          item.images.length > 0
            ? item.images
            : [
                "https://files.stripe.com/links/MDB8YWNjdF8xTTJoeVpLZThGN0dHYktmfGZsX3Rlc3RfMlZKVHM2SHZMZFNlTzAyVU5Nank1YVk000A74faIZd",
              ],
        tag:
          `${item.default_price.recurring.interval_count} ${item.default_price.recurring.interval}` ||
          "",
        tyc: "content/terminos-y-condiciones",
        items: [
          item.description ||
            "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
        ],
        price: item.default_price
          ? {
              id: item.default_price.id,
              unitAmount: item.default_price.unit_amount,
              currency: item.default_price.currency,
              interval: item.default_price.recurring?.interval || null,
            }
          : undefined,
      })),
      {
        name: "Empresas",
        description:
          "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
        items: [
          "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
        ],
        images: [companySubscription],
        tag: "",
        tyc: "terminos-y-condiciones",
        minimumTermLabel: "",
      },
    ]);
  };

  const handleTransactionStatus = (status: string) => {
    switch (status) {
      case "success":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      case "error":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      default:
        setTransactionFinalStatus("none");
        setShowTransactionModal(false);
        break;
    }
  };

  const handleSuccessSubscription = (url: string) => {
    getProfile({
      success: () => navigateTo(url),
    });
  };

  const handleCallToActionCard = (priceId: string) => {
    getCheckout({
      priceId,
      success: (url) => {
        window.location.replace(url);
      },
    });
  };

  useCustomEffect(() => {
    if (!token) {
      navigateTo("/subscriptions");
    }
    if (userSubscription?.stripeStatus === "active") {
      navigateTo("/profile");
    } else {
      get({
        success: onSuccessSubscriptionList,
        error: () => navigateTo("/subscriptions"),
      });

      handleTransactionStatus(getQueryParam("status"));
    }
  }, []);

  return {
    showTransactionModal,
    transactionFinalStatus,
    breadcrumbItemsElements,
    subscriptionSelected: subscriptionsList.find(
      (item) => selectedPriceId === item?.price?.id
    ) as SubscriptionEntity,
    subscriptionsList,
    handleCallToActionCard,
    handleSuccessSubscription,
  };
}
