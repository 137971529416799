import {
  CheckCircle,
  XCircle,
  AlertTriangle,
  SortAsc,
  SortDesc,
} from "lucide-react";
import { StudentCourseTableItem } from "../StudentCourseTableItem";

interface courseInterface {
  uuid: string;
  title: string;
  description: string;
  production_num: string;
  completed: string;
  production_score: number;
  assessment_score: number;
  final_grade: number;
  feedback: number;
}

interface CoursebookTableProps {
  courses: courseInterface[];
  onUpdateGrade: (
    studentId: string,
    assessmentId: string,
    score: number
  ) => void;
  sortConfig: {
    key: string;
    direction: "asc" | "desc";
  };
  onSort: (config: any) => void;
}

export default function StudentCoursesTable({
  courses,
  onUpdateGrade,
  sortConfig,
  onSort,
}: CoursebookTableProps) {
  const handleSort = (key: string) => {
    onSort({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <SortAsc className="h-4 w-4" />
    ) : (
      <SortDesc className="h-4 w-4" />
    );
  };

  return (
    <div className="bg-secondary-100 rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-x-auto">
        {courses.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-700">
            <thead>
              <tr className="bg-primary-50">
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer"
                  onClick={() => handleSort("name")}
                >
                  <div className="flex items-center space-x-1">
                    <span>Estudiante</span>
                    {renderSortIcon("name")}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Puntuación de producción
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Puntuación de la evaluación{" "}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Calificación final
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Terminada{" "}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {courses.map((course) => (
                <StudentCourseTableItem {...course} key={course.uuid} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex items-center justify-center h-60 font-primaryBold text-white text-xl">
            <p>No tienes curso</p>
          </div>
        )}
      </div>
    </div>
  );
}
