import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { useHomeViewModel } from "../../viewModels";
import { Button, Carousel, CategoryCarousel, LearningPathCard } from "../../components";
import { Banner, GoSubscribe, Subscriptions, Podcast, GameSection } from "./components";
import { StyledHomeContainer, StyledSection } from "./home.styles";
import { StyledLearningPathsSection } from "../learningPathsLanding/learningPathsLanding.styles";
import { CourseSection } from "../studentAcessmentDashboard/components";
export const Home: FC = () => {
  const {
    userSubscription,
    token,
    homeData,
    productions,
    podcastProductions,
    gameProductions,
    subscriptionsList,
    handleCallToActionCard,
    navigateTo,
    listLearningPathsToRent,
    onGetGameById,
  } = useHomeViewModel();

  return (
    <DefaultLayout isAbsolute>
      <StyledHomeContainer>
        <Banner
          title={homeData?.banner?.title}
          description={homeData?.banner?.description}
          video={homeData?.banner?.video}
          production={homeData?.banner?.production}
          subscribed={userSubscription?.stripeStatus}
        />

        <div className="gradient-bg text-white">
          <div className="mx-auto px-4 py-20 flex flex-col items-center gap-4">
            <h1 className="text-5xl font-primaryBold mb-6 text-center">Aprender nunca había sido tan emocionante</h1>
            <p className="text-xl mb-8 text-center max-w-[70%]">
              Descubre cómo transformar las habilidades de tu equipo con Storytraining, la plataforma que usa el poder
              de las historias para un aprendizaje memorable.
            </p>
            <div className="flex items-center justify-center">
              <Button
                className="w-60"
                onClick={() => {
                  navigateTo("/services");
                }}
              >
                Explorar más
              </Button>
            </div>
          </div>
        </div>

        {!token && (
          <StyledSection className="StyledSection">
            <h2>
              {homeData?.suscribe?.section?.title?.value
                ? homeData.suscribe.section.title.value
                : "SUSCRÍBETE Y OBTÉN UNA PRUEBA GRATIS"}
            </h2>
            <GoSubscribe />
          </StyledSection>
        )}
        <StyledSection withoutPadding className="StyledSection">
          <article className="flex flex-col items-center gap-y-3 ">
            <h2>
              <span>{homeData?.catalog?.title?.value ? homeData.catalog.title.value : "NUESTRAS PRODUCCIONES"}</span>
            </h2>
            <p className="hidden lg:block">
              {homeData?.catalog?.description?.value
                ? homeData.catalog.description.value
                : 'Todas nuestras producciones son originales y han sido realizadas por el equipo de "storytraining" utilizando su gran experiencia práctica y pedagógica en cada una de nuestras Áreas Temáticas. Contodas ellas aprenderás mientras te diviertes. ¡No te lo pierdas!'}
            </p>
          </article>
          {productions.map((production, index) => {
            const { title, productions: productionList, description } = production;
            return (
              <CategoryCarousel
                className="mt-8 lg:mt-16"
                key={`P-${index}`}
                categoryName={title}
                categoryDescription={description}
                productions={productionList}
                hideCategory={true}
                currentCardPaddingMobile="72px"
              />
            );
          })}

          <h3 className="mt-8 lg:mt-16">
            CONOCE <span>TODAS</span> NUESTRAS <span>ÁREAS TEMÁTICAS</span>
          </h3>
          <Button onClick={() => (window.location.href = "/catalog")} className="mt-6">
            Ver todo el catálogo
          </Button>
        </StyledSection>
        {podcastProductions?.productions.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <Podcast podcastProductions={podcastProductions} />
          </StyledSection>
        )}
        {gameProductions?.games.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <GameSection gameProductions={gameProductions} onClickCard={onGetGameById} />
          </StyledSection>
        )}

        <StyledLearningPathsSection>
          <h2 className="font-primaryBold text-xl my-5 text-white text-center lg:text-4xl">
            CAMINOS DE APRENDIZAJE DISPONIBLES
          </h2>
          <div className="mx-auto w-full lg:px-44 px-0 max-w-dashboard">
            <Carousel
              slidesToShow={listLearningPathsToRent.length == 1 ? 1 : 2}
              slidesToScroll={1}
              infinite={false}
              dots={false}
              responsive={[
                {
                  breakpoint: 1120,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "65px",
                  },
                },
              ]}
            >
              {listLearningPathsToRent.map((learningPath: any) => {
                return (
                  <LearningPathCard
                    key={learningPath.uuid}
                    className={"lg:px-4"}
                    learningPathData={{
                      uuid: learningPath.uuid,
                      title: learningPath.title,
                      imgPreview: learningPath.image,
                      // description: "",
                    }}
                  />
                );
              })}
            </Carousel>
          </div>
        </StyledLearningPathsSection>
        {/* Learning paths section ends */}
        <div className="max-w-theme mx-auto ">
          <div className="w-full lg:px-28 px-0 ">
            <CourseSection />
          </div>
        </div>

        {(userSubscription?.stripeStatus !== "active" || !token) && (
          <StyledSection>
            <Subscriptions
              token={token}
              subscriptionsList={subscriptionsList}
              handleCallToActionCard={handleCallToActionCard}
              homeData={homeData}
            />
          </StyledSection>
        )}
      </StyledHomeContainer>
      {/* {!!token && <StyledSection className="StyledSection" />} */}
    </DefaultLayout>
  );
};
