import React, { useEffect, useState } from "react";
import { Trash2, MoveRight } from "lucide-react";
import { Button, TextField } from "../../../../components";
import toast from "react-hot-toast";

interface MatchingWordFormProps {
  submitFormHandler: (data: any) => void;
  handlePrevious: () => void;
  quizQuestion: any[];
  currentQuestionIndex: number;
  handleDelete: (
    questionId: string,
    answerIndex: number,
    questionType: string
  ) => void;
}

export const MatchingWordForm = ({
  submitFormHandler,
  handlePrevious,
  quizQuestion,
  currentQuestionIndex,
  handleDelete,
}: MatchingWordFormProps) => {
  const [contents, setContents] = useState<string[]>([""]);
  const [matchPairContent, setMatchPairContent] = useState<string[]>([""]);

  const addWord = () => {
    setContents([...contents, ""]);
    setMatchPairContent([...matchPairContent, ""]);
  };

  const removeWord = (index: number) => {
    setContents(contents.filter((_, i) => i !== index));
    setMatchPairContent(matchPairContent.filter((_, i) => i !== index));
  };

  const updateLeftWord = (index: number, value: string) => {
    const newWords = [...contents];
    newWords[index] = value;
    setContents(newWords);
  };

  const updateRightWord = (index: number, value: string) => {
    const newWords = [...matchPairContent];
    newWords[index] = value;
    setMatchPairContent(newWords);
  };

  const getCommonDuplicates = (arr1: string[], arr2: string[]): string[] => {
    const set1 = new Set(arr1);
    const duplicates: string[] = [];

    for (let item of arr2) {
      if (set1.has(item) && !duplicates.includes(item)) {
        duplicates.push(item);
      }
    }

    return duplicates;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      contents.some((word) => !word) ||
      matchPairContent.some((word) => !word)
    ) {
      return;
    }

    if (getCommonDuplicates(contents, matchPairContent).length > 0) {
      toast.error(
        `No se permiten palabras duplicadas ${getCommonDuplicates(
          contents,
          matchPairContent
        ).join()}`
      );
    } else {
      submitFormHandler({
        type: "word_connection",
        contents,
        matchPairContent,
      });
      clearForm();
    }
  };

  const clearForm = () => {
    setContents([""]);
    setMatchPairContent([""]);
  };

  useEffect(() => {
    if (quizQuestion && quizQuestion[currentQuestionIndex].answers) {
      let leftWord: string[] = [];
      let rightWord: string[] = [];
      quizQuestion[currentQuestionIndex].answers.map((item: any) => {
        leftWord.push(item.content);
        rightWord.push(item.match_pair_content);
      });
      setContents(leftWord);
      setMatchPairContent(rightWord);
    }
  }, [currentQuestionIndex]);

  return (
    <div className="w-full max-w-4xl mx-auto px-6 bg-black rounded-xl min-h-[10rem]">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          {contents.map((_, index) => (
            <div key={index} className="flex gap-4 items-center">
              <TextField
                className="flex-1  rounded-lg p-2"
                value={contents[index]}
                onChange={(e) => updateLeftWord(index, e.target.value)}
                placeholder="Ingrese la palabra izquierda"
              />
              <MoveRight className="text-primary-50" />
              <TextField
                className="flex-1  rounded-lg p-2"
                value={matchPairContent[index]}
                onChange={(e) => updateRightWord(index, e.target.value)}
                placeholder="Introduzca la palabra correcta"
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    removeWord(index);
                    quizQuestion[currentQuestionIndex]?.question_id &&
                      handleDelete(
                        quizQuestion[currentQuestionIndex]?.question_id,
                        index,
                        "word_connection"
                      );
                  }}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Trash2 size={20} />
                </button>
              )}
            </div>
          ))}

          {!quizQuestion[currentQuestionIndex]?.question_id && (
            <Button
              onClick={addWord}
              color="secondary"
              className="text-primary-50 my-0"
            >
              Agregar par
            </Button>
          )}
        </div>
        <div className="flex items-center justify-center gap-8">
          <Button
            onClick={() => handlePrevious()}
            className="w-full"
            type="button"
            color="third"
            disabled={currentQuestionIndex < 1 ? true : false}
          >
            Previa
          </Button>
          <Button type="submit" className="w-full">
            {currentQuestionIndex + 1 === quizQuestion.length
              ? "Entregar"
              : "Próxima"}
          </Button>
        </div>
      </form>
    </div>
  );
};
