import {
  CheckCircle,
  XCircle,
  AlertTriangle,
  SortAsc,
  SortDesc,
} from "lucide-react";
import { StudentCourseQuizTableItem } from "../StudentCourseQuizTableItem";

interface CourseQuizInterface {
  id: number;
  title: string;
  description: string;
  course_uuid: string;
  quiz_type: string;
  difficulty_level: string;
  passing_score: number;
  duration: number;
  is_published: true;
  taken_at: string;
  grade: number;
  grade_id: null;
  questions_count: number;
  correct_answers: number;
  feedback: string | null;
}

interface CoursebookTableProps {
  coursesQuiz: CourseQuizInterface[];
  sortConfig: {
    key: string;
    direction: "asc" | "desc";
  };
  onSort: (config: any) => void;
}

export default function StudentCoursesQuizTable({
  coursesQuiz,
  sortConfig,
  onSort,
}: CoursebookTableProps) {
  const handleSort = (key: string) => {
    onSort({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <SortAsc className="h-4 w-4" />
    ) : (
      <SortDesc className="h-4 w-4" />
    );
  };

  return (
    <div className="bg-secondary-100 rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-x-auto">
        {coursesQuiz.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-700">
            <thead>
              <tr className="bg-primary-50">
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer"
                  onClick={() => handleSort("name")}
                >
                  <div className="flex items-center space-x-1">
                    <span>Prueba</span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Tipo de cuestionario
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Puntuación de aprobación{" "}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Calificación{" "}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Pregunta total{" "}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Respuestas correctas
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Comentario
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                  Tomada en
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {coursesQuiz.map((quiz) => (
                <StudentCourseQuizTableItem {...quiz} key={quiz.id} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex items-center justify-center h-60 font-primaryBold text-white text-xl">
            <p>No tienes curso</p>
          </div>
        )}
      </div>
    </div>
  );
}
