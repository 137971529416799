import { SquareCheckBig } from "lucide-react";
import { FC, useContext, useState } from "react";
import { CheckBox } from "../../../../components";
import { LanguageContext } from "../../../../context/LanguageContext";

interface MultipleSelectionProps {
  id: string;
  questionType: "multiple-choice" | "single-choice" | "matching-choice";
  question: string;
  options: any[];
  onAnswer: (questionId: string, questionType: string, answer: any) => void;
}

export const MultipleSelection: FC<MultipleSelectionProps> = ({
  id,
  questionType,
  question,
  onAnswer,
  options,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<any[]>([]);

  const handleAnswer = (option: any) => {
    setSelectedAnswer((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.answer_id === option.answer_id
      );

      const updatedSelection = isSelected
        ? prevSelected.filter(
            (selected) => selected.answer_id !== option.answer_id
          ) // Remove if already selected
        : [...prevSelected, option]; // Add if not selected

      onAnswer(id, questionType, updatedSelection); // Pass the updated selection
      return updatedSelection;
    });
  };

  const languageContext = useContext(LanguageContext);
  const language = languageContext?.language || "es"; // Fallback to Spanish

  return (
    <div className="mx-auto bg-black p-8 rounded-lg shadow-md w-full">
      <div className="space-y-8">
        <div>
          <h3 className="text-2xl font-medium mb-6">{question}</h3>
          <div className="space-y-3">
            {options.map((option) => {
              const isSelected = selectedAnswer.some(
                (selected) => selected.answer_id === option.id
              );
              return (
                <button
                  key={`${option.id}-${language}-${option.content}`}
                  onClick={() =>
                    handleAnswer({
                      answer_id: option.id, // Changing id to answer_id
                      content: option.content,
                      question_id: id,
                      is_pair_id: option.is_pair_id,
                    })
                  }
                  className={`flex items-center rounded-lg cursor-pointer transition-colors w-full px-4 ${
                    isSelected
                      ? "bg-primary-50"
                      : "bg-secondary-300 hover:bg-secondary-100"
                  }`}
                >
                  <span className="w-10">
                    {isSelected ? (
                      <SquareCheckBig
                        className="mr-4 w-6 h-6"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <CheckBox readOnly checked={isSelected} />
                    )}
                  </span>

                  <span className="ml-3 text-white text-left py-3">
                    {option.content}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
