import {
  GameById,
  ProductionById,
  ProductionsByCategory,
  ProductionSeoById,
  ProductionsService,
  ProductionsWithFilter,
} from "../../domain/services/productions.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { ProductionModel, ProductionsModel } from "../models/production.model";
import { ChapterEntity } from "../../domain/entities";
import { ProductionSeoModel } from "../models";
import { useSelector } from "react-redux";
import { getSessionToken } from "../dto/selectors";
import { AxiosResponse } from "axios";

const baseUrl = process.env.REACT_APP_API_BASE;

export interface ProductionsResponse {
  data: {
    data: ProductionsModel;
  };
}
export interface ProductionResponse {
  data: {
    data: ProductionModel;
  };
}

export interface ProductionSeoResponse {
  data: {
    data: ProductionSeoModel;
  };
}

export interface ChapterResponse {
  data: {
    data: ChapterEntity;
  };
}
export function productionsDataService(): ProductionsService {
  const token = useSelector(getSessionToken);

  const { get } = httpService();
  return {
    async getAllProductions({ success, error }: handleResponse) {
      try {
        const response: ProductionsResponse = await get(Endpoints.productions);
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getExtraProductions({ success, error }: handleResponse) {
      fetch(`${baseUrl}${Endpoints.extraProductions}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (success) {
            success(data.data.items);
          }
        })
        .catch((e) => {
          if (error) {
            error(e);
            console.log(error);
          }
        });
    },
    async getProductionsByCategory({ id, success, error }: ProductionsByCategory) {
      try {
        const response: ProductionsResponse = await get(`${Endpoints.terms}/${id}/production`);
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getProductionById({ id, success, error }: ProductionById) {
      fetch(`${baseUrl}${Endpoints.productions}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (success) {
            success(data.data);
          }
        })
        .catch((e) => {
          if (error) {
            error(e);
            console.log(error);
          }
        });
    },
    async getProductionSeoById({ id, success, error }: ProductionSeoById) {
      try {
        const response: ProductionSeoResponse = await get(`${Endpoints.productions}/${id}/seo`);
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getGameById({ id, success, error }: GameById) {
      try {
        const response: ProductionResponse = await get(`${Endpoints.games}/${id}`);
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getProductionsWithFilter({ filter = {}, success, error }: ProductionsWithFilter) {
      try {
        const response: ProductionsResponse = await get(Endpoints.productions, filter);
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },

    async getProductionChapter({ id, success, error }: ProductionById) {
      fetch(`${baseUrl}${Endpoints.chapters}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (success) {
            success(data.data);
          }
        })
        .catch((e) => {
          if (error) {
            error(e);
          }
        });
    },
  };
}
