import { FC, useMemo } from "react";
import { Breadcrumb, SubscriptionDetailCard } from "../../components";
import { DefaultLayout } from "../../layouts";
import { StatusModal } from "./components/StatusModal";
import { StyledContainerCards } from "./subscriptionDetail.styles";
import { useSubscriptionDetailViewModel } from "../../viewModels";
useSubscriptionDetailViewModel;
export const SubscriptionDetail: FC = () => {
  const {
    showTransactionModal,
    transactionFinalStatus,
    breadcrumbItemsElements,
    subscriptionSelected,
    subscriptionsList,
    handleCallToActionCard,
    handleSuccessSubscription,
  } = useSubscriptionDetailViewModel();

  const filteredSubscriptionList = useMemo(() => {
    return [
      ...(subscriptionsList?.filter(
        (item) => item?.price?.id !== subscriptionSelected?.price?.id
      ) || []),
    ];
  }, [subscriptionsList, subscriptionSelected]);

  return (
    <DefaultLayout>
      <Breadcrumb
        className="px-8 lg:px-14 "
        breadcrumbItems={breadcrumbItemsElements}
      />
      {!!subscriptionsList.length && (
        <StyledContainerCards>
          <div className="w-full mx-auto">
            <SubscriptionDetailCard
              subscriptionData={subscriptionSelected}
              callToActionCard={handleCallToActionCard}
              flag={false}
            />
          </div>
          <div className="hidden lg:flex flex-col gap-y-10 ">
            {!!subscriptionsList.length &&
              filteredSubscriptionList.map((currentSubscription, index) => (
                <SubscriptionDetailCard
                  key={`S-${index}`}
                  subscriptionData={currentSubscription}
                  flag={true}
                />
              ))}
          </div>
        </StyledContainerCards>
      )}
      {!!subscriptionSelected?.price?.id && (
        <StatusModal
          showModal={showTransactionModal}
          status={transactionFinalStatus}
          handleSuccessSubscription={handleSuccessSubscription}
          handleRedirectToCheckout={handleCallToActionCard}
          priceId={subscriptionSelected.price.id}
        />
      )}
    </DefaultLayout>
  );
};
