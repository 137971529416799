import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useStopwatch } from "react-timer-hook";
import { yupResolver } from "@hookform/resolvers/yup";

import { CountryEntity, OptionsSelectEntity } from "../../../domain/entities";

import { countryDataService } from "../../../data/services/country.data.service";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { useCaseCountries } from "../../../domain/useCases/countries";
import { signUp, useCaseLegal, useCaseSignIn } from "../../../domain/useCases";

import { userSignUpSchema } from "../../validators";
import { useCustomEffect } from "../../hooks/useHookEffect";
import { useNavigation, useObjectToArray } from "../../hooks";
import toast from "react-hot-toast";
import { LegalModel } from "../../../data/models";
import { legalDataService } from "../../../data/services";
import { useSelector } from "react-redux";
import { getSessionToken } from "../../../data/dto/selectors";
import { useModal } from "../../context/ModalContext";

interface CountriesData {
  countries: Array<CountryEntity>;
  countriesOptions: Array<OptionsSelectEntity>;
}

export function useRegisterViewModel() {
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Crear Cuenta",
    },
  ];

  const { seconds, minutes, isRunning, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const { showLoading, hideLoading } = useModal();
  const { navigateTo, getStateParam, getQueryParam } = useNavigation();
  const token = useSelector(getSessionToken);
  const [countriesData, setCountriesData] = useState<CountriesData>();
  const [showModalRegisterSuccess, setShowModalRegisterSuccess] = useState(false);
  const [legalData, setLegalData] = useState<LegalModel>({} as LegalModel);
  const { run: getCountries } = useCaseCountries(countryDataService());
  const { run } = signUp(authenticationDataService());
  const { sendVerificationLink } = authenticationDataService();
  const { getLegal } = useCaseLegal(legalDataService());
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(userSignUpSchema) });

  // get countries
  const onGetCountriesSuccess = (countries: Array<CountryEntity>) => {
    const countriesForSelectComponent = countries.map((country) => ({
      id: country.id,
      name: country.name,
    }));
    setCountriesData({
      countries,
      countriesOptions: [{ id: "none", name: "Elige tu país de origen" }, ...countriesForSelectComponent],
    });
  };

  const handleLegalDataSuccess = (legalData: LegalModel) => {
    setLegalData(legalData);
  };

  // handle get email
  const handlerGetEmail = () => {
    const defaultEmail = getStateParam("email");
    defaultEmail && setValue("email", defaultEmail);
  };

  // handle register success
  const onSignUpSuccess = () => {
    hideLoading();
    setShowModalRegisterSuccess(true);
    reset();
  };

  const onRegisterError = (error: any) => {
    if (error?.response?.status != 422) {
      toast.error(error.response.data.message);
    } else {
      const arrayOfErrors = useObjectToArray(error.response.data.errors);
      arrayOfErrors.forEach((currentError) => {
        setError(currentError.key, {
          type: "custom",
          message: currentError.value,
        });
      });
    }
  };

  const handleModalTimer = () => {
    minutes === 1 && pause();
  };

  function formatWithHyphens(input: string): string {
    const cleanedInput = input.replace(/-/g, "");

    const formatted = cleanedInput.replace(/(.{4})(?=.)(.{4})(?=.)(.{4})/, "$1-$2-$3");

    return formatted;
  }

  // on submit signup
  const onSubmit = (data: FieldValues) => {
    if (countriesData && countriesData.countries) {
      const countryData = countriesData.countries.find((country) => country.id == data.country);

      const signUpData = {
        name: data.name,
        email: data.email,
        lastName: data.lastName,
        password: data.password,
        country: countryData as CountryEntity,
        invitationCode: data.isBusinessUser ? formatWithHyphens(data.invitationCode) : "",
        success: onSignUpSuccess,
        error: onRegisterError,
      };
      showLoading();
      run(signUpData);
    }
  };

  const onResendLink = (data: FieldValues) => {
    const emailData = {
      email: data.email,
      success: () => reset(),
      error: onRegisterError,
    };
    sendVerificationLink(emailData);
  };

  useCustomEffect(() => {
    handlerGetEmail();
    getCountries({ success: onGetCountriesSuccess });
    getLegal({ success: handleLegalDataSuccess });

    const email = getQueryParam("email");

    if (!token && email) {
      onResendLink({ email, success: () => reset(), error: onRegisterError });
      onSignUpSuccess();
    }

    if (token) navigateTo("/profile");
  }, []);

  useCustomEffect(handleModalTimer, [minutes]);

  return {
    breadcrumbItemsElements,
    navigateTo,

    countriesData,
    legalData,
    showModalRegisterSuccess,
    isRunning,
    seconds,
    onResendLink,
    handleSubmit,
    control,
    errors,
    watch,
    onSubmit,
  };
}
