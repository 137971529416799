import * as yup from "yup";

export const newEvaluationSchema = yup.object({
  title: yup.string().required("El título es obligatorio"),
  description: yup.string().optional(),
  difficultyLevel: yup.string().required("Se requiere nivel de dificultad"),
  passingScore: yup.string().required("Puntuación de aprobación"),
  duration: yup.string().required("duración requiere límite de tiempo"),
  isPublished: yup.string().required("Se requiere que se publique"),
  numberOfQuestions: yup.string().required("El número de pregunta es obligatorio")
});
