import { FC, useContext, useEffect, useState } from "react";
import Xarrow from "react-xarrows";
import { LanguageContext } from "../../../../context/LanguageContext";

interface Connection {
  start: string;
  end: string;
}

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface MatchingWordComponentProps {
  id: string;
  questionType: string;
  question: string;
  options: any[];
  onAnswer: (questionId: string, questionType: string, answer: any) => void;
  selectedAnswer: selectedOptionCheck[];
}

export const MatchingWordComponent: FC<MatchingWordComponentProps> = ({
  id,
  options,
  questionType,
  onAnswer,
}) => {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [startWord, setStartWord] = useState<string | null>(null);
  const [pairedWord, setPairedWord] = useState<string[]>([]);
  const [word, setWord] = useState<string[]>([]);

  const handleConnectionMatching = (connectted: any) => {};
  const languageContext = useContext(LanguageContext);
  const language = languageContext?.language || "es"; // Fallback to Spanish

  const handleWordClick = (word: string, side: "left" | "right") => {
    if (!startWord) {
      if (side === "left") {
        setStartWord(word);
      }
    } else {
      if (side === "right") {
        // Ensure no duplicate connections for either side
        const newConnections = connections.filter(
          (c) => c.start !== startWord && c.end !== word
        );
        // Add the new connection
        const connectCombination = [
          ...newConnections,
          { start: startWord, end: word },
        ];
        setConnections(connectCombination);
        // convert array to string
        const connectionString: string = connectCombination
          .map((item) => {
            return `${item.start}*${item.end}`;
          })
          .join();
        onAnswer(id, questionType, connectionString);

        const formattedConnections = connectCombination
          .map(({ start, end }) => {
            const startObj = options.find((o) => o.content === start);
            const endObj = options.find((o) => o.content === end);

            if (startObj && endObj) {
              return {
                answer_id: startObj.id,
                content: startObj.content,
                match_pair_id: endObj.id,
                match_pair_content: endObj.content,
              };
            }
            return null;
          })
          .filter(Boolean);
        onAnswer(id, questionType, formattedConnections);
      }
      setStartWord(null);
    }
  };

  const removeConnection = (connectionToRemove: Connection) => {
    setConnections((prev) =>
      prev.filter(
        (connection) =>
          !(
            connection.start === connectionToRemove.start &&
            connection.end === connectionToRemove.end
          )
      )
    );
  };

useEffect(() => {
  const shuffleArray = (array: string[]) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  setPairedWord(
    shuffleArray(
      options
        .map((item) => (!item.is_pair_id ? item.content : undefined))
        .filter((item) => item !== undefined) as string[]
    )
  );

  setWord(
    shuffleArray(
      options
        .map((item) => (item.is_pair_id ? item.content : undefined))
        .filter((item) => item !== undefined) as string[]
    )
  );
}, []);


  return (
    <div className="bg-black py-8 px-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-medium mb-6 ">Conexión de palabras</h1>
        <div className="flex  mb-12 gap-x-32">
          <div className="flex flex-col space-y-8 w-full ">
            {word
              .filter((item) => item !== undefined)
              .map((word) => (
                <div
                  key={`${word}-${language}`}
                  id={word}
                  className={` cursor-pointer px-4 py-3 rounded-md flex items-center justify-center  hover:bg-secondary-100  ${
                    startWord === word ? "bg-primary-50" : "bg-secondary-300"
                  }`}
                  onClick={() => handleWordClick(word, "left")}
                >
                  {word}
                </div>
              ))}
          </div>

          <div className="flex flex-col space-y-8 w-full">
            {pairedWord &&
              pairedWord
                .filter((item) => item !== undefined)
                .map((word) => (
                  <div
                    key={`${word}-${language}`}
                    id={word}
                    className="bg-secondary-300 hover:bg-secondary-100 cursor-pointer px-4 py-3 rounded-md flex items-center justify-center"
                    onClick={() => handleWordClick(word, "right")}
                  >
                    {word}
                  </div>
                ))}
          </div>
        </div>

        {connections.map((connection, index) => (
          <div
            key={`${connection.start}-${connection.end}-${index}`}
            className="cursor-pointer"
            onClick={() => removeConnection(connection)}
          >
            <Xarrow
              start={connection.start}
              end={connection.end}
              color="#E30613"
              strokeWidth={4}
              curveness={0.2}
            />
          </div>
        ))}

        <div className="mt-12 text-center text-gray-600 dark:text-gray-400 space-y-2">
          <p>
            Haga clic en una palabra a la izquierda, luego haga clic en una
            palabra coincidente a la derecha para conectarlas.
          </p>
          <p>Haga clic en cualquier línea para eliminar la conexión.</p>
        </div>
      </div>
    </div>
  );
};
