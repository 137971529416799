import tw from "twin.macro";
import styled from "styled-components";

export const StyledParagraph = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${tw`
  text-white
  text-base
  font-primarySemibold
  leading-6

  py-3 
  px-6
  `}
`;

export const StyledList = styled.ul.attrs((props) => ({
  className: props.className,
}))`
  ${tw`
    z-20 hidden lg:(inline-block absolute) top-full bg-white rounded-lg text-gray-400 w-64 right-0 font-primaryBold
    `}
  li {
    ${tw`
      px-4 py-3 cursor-pointer
      hover:(bg-primary-100 rounded-lg text-white)
    `}
  }
`;

export const StyleButton = styled.button.attrs((props) => ({
  className: props.className,
}))`
  ${tw`
    p-0
`}
`;
