import React, { FC } from "react";
import { ListChecks, CircleEqual, CheckCircle2 } from "lucide-react";
import { Button } from "../../../../components";

type QuestionType =
  | "word_connection"
  | "single_selection"
  | "multiple_selection"
  | "";

interface QuestionTypeSelectionProps {
  selectedType: string;
  questionTitle: string;
  handleSelect: (type: string) => void;
  disabled?: boolean; // New prop to disable selection
}

export const QuestionTypeSelection: FC<QuestionTypeSelectionProps> = ({
  handleSelect,
  selectedType,
  questionTitle,
  disabled = false,
}) => {
  const questionTypes = [
    {
      id: "word_connection",
      title: "Palabras coincidentes",
      description: "Unir palabras o frases relacionadas",
      icon: CircleEqual,
    },
    {
      id: "single_selection",
      title: "Selección única",
      description: "Elija una respuesta correcta entre múltiples opciones",
      icon: CheckCircle2,
    },
    {
      id: "multiple_selection",
      title: "Selección múltiple",
      description: "Seleccione todas las respuestas correctas que correspondan",
      icon: ListChecks,
    },
  ];

  return (
    <div className="py-0 rounded-md">
      <div className="max-w-2xl">
        <p className="text-base font-primaryRegular text-white mb-2">
          Elija el tipo de pregunta
        </p>
        <div className="">
          {questionTypes.map((type) => {
            const isSelected = selectedType === type.id;
            return (
              <button
                key={type.id}
                onClick={() => {
                  if (!disabled) {
                    handleSelect(type.id);
                  }
                }}
                disabled={disabled}
                className={`relative px-3 py-2 rounded-xl transition-all duration-200 mr-6 mb-6 ${
                  isSelected ? "bg-primary-50 shadow-lg" : "bg-secondary-100"
                } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                <div className="flex flex-col items-center text-center">
                  <h3 className="text-normal font-primarySemibold text-white mb-2">
                    {type.title}
                  </h3>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
