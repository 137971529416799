import { FC, useState } from "react";
import { SeasonEntity, PreviewChapterEntity, ChapterEntity } from "../../../../../domain/entities";
import { ArrowDownIcon, LineSeparatorIcon } from "../../../../assets/Icons";
import { ChapterCard } from "../../../../components";
import { StyledButton, StyledSelectField } from "./SeasonsSection.styles";
import { useSeasonsSection } from "./useSeasonsSection";

interface SeasonsSectionProps {
  seasons: Array<SeasonEntity>;
  chapters?: Array<PreviewChapterEntity>;
  onChapterClick?: (id: string, currentSeason?: number) => void;
  currentChapter: ChapterEntity;
}

export const SeasonsSection: FC<SeasonsSectionProps> = ({ seasons, chapters, onChapterClick, currentChapter }) => {
  const { seasonsOptions, currentSeasonNumber, currentSeasonChapters, onSeasonSelected } = useSeasonsSection(
    seasons,
    currentChapter
  );
  return (
    <section id="SeasonsSection" className=" px-4 max-w-preview mx-auto w-full flex flex-col ">
      {!!seasons.length && (
        <div>
          {/* <ul className="hidden lg:flex">
            {seasonsOptions.map((currentSeason, index) => {
              return (
                <li className={`flex items-center`} key={index}>
                  {currentSeason?.id !== 0 && <LineSeparatorIcon />}
                  <StyledButton
                    onClick={() => onSeasonSelected(Number(currentSeason.id))}
                    colorText={
                      currentSeason?.id === currentSeasonNumber
                        ? "#C1101A"
                        : "#FFFFFF"
                    }
                  >
                    {currentSeason.name}{" "}
                    <ArrowDownIcon
                      color={
                        currentSeason?.id === currentSeasonNumber
                          ? "#C1101A"
                          : "#FFFFFF"
                      }
                    />
                  </StyledButton>
                </li>
              );
            })}
          </ul> */}
          <StyledSelectField
            value={currentSeasonNumber}
            options={seasonsOptions}
            // disabled={seasonsOptions.length > 1}
            onBlur={(e) => e.stopPropagation()}
            onChange={(e) => onSeasonSelected(Number(e.target.value))}
          />
          <div className="flex flex-col gap-y-6">
            {currentSeasonChapters?.map((chapter: PreviewChapterEntity, i: number) => {
              return (
                <ChapterCard
                  onClick={
                    onChapterClick
                      ? () => onChapterClick(chapter.uuid, seasons.length ? currentSeasonNumber + 1 : 0)
                      : () => {}
                  }
                  key={`card${i}`}
                  chapterData={chapter}
                />
              );
            })}
          </div>
        </div>
      )}

      {!!chapters?.length && (
        <div className="flex flex-col gap-y-6">
          {chapters?.map((chapter: PreviewChapterEntity, i: number) => {
            return (
              <ChapterCard
                onClick={onChapterClick ? () => onChapterClick(chapter.uuid, 0) : () => {}}
                key={`card${i}`}
                chapterData={chapter}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};
