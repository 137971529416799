import { useSelector } from "react-redux";
import {
    CommentsService,
    ProductionComments,
    RateProduction,
} from "../../domain/services/comments.service";
import { CreateSessions, SessionService, GetLearningPathSession, UpdateSession } from "../../domain/services/createSession.service";
import {
    ProductionsSearch,
    SearchService,
} from "../../domain/services/search.service";
import { httpService } from "../http/services/http";
import { CommentModel, ProductionCommentsModel } from "../models";
import { Endpoints } from "./endpoints";
import { getSessionToken } from "../dto/selectors";
const baseUrl = process.env.REACT_APP_API_BASE

export interface LearningPathSessionResponse {
    data: {
        data: [];
    };
}

export function learninPathSessionDataService(): SessionService {
    const token = useSelector(getSessionToken);
    const { get, post, put } = httpService();
    return {
        async createSession({
            body,
            success,
            error,
        }: CreateSessions) {
            const formData = new FormData()
            formData.append("learning_path_uuid", body.learningPathId)
            formData.append("starts_at", body.startAt)
            formData.append("ends_at", body.endAt)
            formData.append("date", body.date)
            formData.append("status", body.status)
            formData.append("name", body.name);
            fetch(`${baseUrl}${Endpoints.creatsSession}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                }, body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        error(e);
                    }
                });
        },

        async updateSession({ body,
            learningPathSessionId,
            success,
            error, }: UpdateSession) {
            const formData = new URLSearchParams({
                learning_path_uuid: body.learningPathId,
                starts_at: body.startAt,
                ends_at: body.endAt,
                date: body.date,
                status: body.status,
                name: body.name,
            });
            fetch(`${baseUrl}${Endpoints.creatsSession}/${learningPathSessionId}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }, body: formData.toString()
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        console.log(e)
                        error(e);
                    }
                });

        },
        async getSessionDetails({ success, error, learningPathSessionId }: GetLearningPathSession) {
            try {
                const response = await get(`${Endpoints.creatsSession}/${learningPathSessionId}`)
                if (success) {
                    success(response.data.data)
                }
            }
            catch (err) {
                if (error) {
                    error(err)
                }
            }
        },

    };
}
