import {
  BookOpen,
  Users,
  SquareCheckBig,
  Clock4,
  ChartNoAxesColumn,
  AlertCircle,
} from "lucide-react";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { useNavigation } from "../../hooks";

const AdministrationDashboard = () => {
  const { navigateTo } = useNavigation();
  const dashBoardData: Array<{ title: string; icon: any; count: string }> = [
    {
      title: "Total Usuarios",
      icon: <Users color="#E30613" width={30} height={30} />,
      count: "4564",
    },
    {
      title: "Evaluaciones Completadas",
      icon: <ChartNoAxesColumn color="#E30613" width={30} height={30} />,
      count: "45",
    },
    {
      title: "Reportes Pendientes",

      icon: (
        <AlertCircle width={30} height={30} className="h-6 w-6 text-red-600" />
      ),
      count: "48",
    },
  ];

  const recentActivities: Array<{
    description: string;
    title: string;
    time: string;
    accessmentType: string;
  }> = [
    {
      description: "Completaste la evaluación",
      title: `Nueva evaluación creada`,
      time: "Hace 2 horas",
      accessmentType: "assessments",
    },
    {
      description: "Viste el video ",
      title: `Calificaciones actualizadas`,
      time: "Hace 2 horas",
      accessmentType: "course",
    },
  ];

  const evaluationData: Array<{
    description: string;
    title: string;
    date: string;
    status: "Activa" | "Borrador";
    completed: string;
    total: string;
  }> = [
    {
      description: "El Ascensor",
      title: `Liderazgo Efectivo`,
      date: "2024-03-20",
      status: "Activa",
      completed: "12",
      total: "20",
    },
    {
      description: "La Mejor Alternativa",
      title: `Conceptos de Negociación`,
      date: "2024-03-25",
      status: "Borrador",
      completed: "12",
      total: "20",
    },
  ];
  return (
    <DefaultLayout>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Dashboard del Administrador
          </h2>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 ">
          {/* {dashBoardData.map((item) => (
            <CountCard {...item} />
          ))} */}
        </div>
        <div className="my-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-primary-50">
                <th className="px-6 py-3  text-left text-xs font-medium text-white uppercase tracking-wider">
                  Métrica
                </th>
                <th className="px-6 py-3  text-left text-xs font-medium text-white uppercase tracking-wider">
                  Último Mes
                </th>
                <th className="px-6 py-3  text-left text-xs font-medium text-white uppercase tracking-wider">
                  Total
                </th>
                <th className="px-6 py-3  text-left text-xs font-medium text-white uppercase tracking-wider">
                  Tendencia
                </th>
              </tr>
            </thead>
            <tbody className="bg-secondary-50 divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-300">
                  Evaluaciones Creadas
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  89
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  567
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">
                  +8.3%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AdministrationDashboard;
