import { FC } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Carousel,
  CategoryCarousel,
  Navbar,
  SearchCard,
  StyledSearchBar,
  TextFieldControlled,
} from "../../components";
import { useHeader } from "../../components/Header/useHeader";
import { DefaultLayout } from "../../layouts";
import { useSearchPageViewModel } from "../../viewModels";
import { PodcastProductionsSection, GameCategorySection, GameProductionsSection, PodcastSection } from "./components";
import { SearchNotFound } from "./components/searchNotFound";
import {
  StyledCarousel,
  StyledContainer,
  StyledSearchPage,
  StyledSearchText,
  StyledSection,
  StyledFavoriteCard,
} from "./searchPage.styles";
/* import  searchIcon  from "../../assets/images/searchIcon.svg"; */

export const SearchPage: FC = () => {
  const {
    breadcrumbItemsElements,
    productions,
    podcastProductions,
    gameCategory,
    podcastsCategory,
    categories,
    navigateTo,
    searchTerm,
    onGetGameById,
  } = useSearchPageViewModel();
  /* const { handleSubmit, control, onSubmit } = useHeader(); */
  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      {/*  <div className="mt-4 lg:hidden">
            <StyledSearchBar>
                <TextFieldControlled iconSrc={searchIcon} placeholder="Buscar soft skills, áreas o series" id="searchTerm" name="searchTerm" control={control}/>
                <Button onClick={handleSubmit(onSubmit)}>Buscar</Button>
            </StyledSearchBar>
            </div> */}
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledSearchPage>
        {!!categories.length ||
        !!productions.length ||
        !!podcastProductions?.length ||
        !!gameCategory?.games.length ||
        !!podcastsCategory?.productions.length ? (
          <StyledContainer>
            <StyledSection className="StyledSection">
              <StyledSearchText>
                <h1 className="text-white">
                  Estamos mostrando resultados de búsqueda de: “<span>{searchTerm}</span>”
                </h1>
              </StyledSearchText>
            </StyledSection>
            {(!!productions.length || !!podcastProductions?.length) && (
              <StyledSection className="StyledSection">
                {!!productions.length && (
                  <Carousel
                    slidesToShow={4}
                    slidesToScroll={1}
                    infinite={false}
                    dots={false}
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: productions.length > 1 ? 2 : 1,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: true,
                          arrows: false,
                          centerMode: true,
                        },
                      },
                    ]}
                  >
                    {productions?.map((production, i: number) => {
                      return (
                        <SearchCard
                          isCatalog
                          key={`card${i}-${production.uuid}`}
                          className="px-2 lg:px-4"
                          productionData={production}
                        />
                      );
                    })}
                  </Carousel>
                )}
                {!!podcastProductions?.length && <PodcastProductionsSection podcastProductions={podcastProductions} />}
              </StyledSection>
            )}

            <StyledSection className="StyledSection">
              {(!!categories.length || !!gameCategory?.games.length || !!podcastsCategory?.productions.length) && (
                <StyledSearchText>
                  <p className="text-white text-left mb-8">Pensamos que estas soft skill serían de tu interés:</p>
                </StyledSearchText>
              )}

              {!!categories.length &&
                categories.map((category, index) => {
                  const { title, productions: productionList, description } = category;
                  return (
                    <div key={`category-${index}`}>
                      <h2 className={`flex justify-start my-4 px-4 lg:px-0`}>{title}</h2>
                      <p className="hidden lg:block text-left my-4">{description}</p>
                      <Carousel
                        slidesToShow={4}
                        slidesToScroll={1}
                        infinite={false}
                        dots={false}
                        responsive={[
                          {
                            breakpoint: 1024,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true,
                              arrows: false,
                              centerMode: true,
                            },
                          },
                        ]}
                      >
                        {productionList?.map((production, i: number) => {
                          return (
                            <SearchCard
                              isCatalog
                              key={`card${i}-${production.uuid}`}
                              className="px-2 lg:px-4"
                              productionData={production}
                            />
                          );
                        })}
                      </Carousel>
                    </div>
                  );
                })}

              {!!gameCategory?.games.length && (
                <GameCategorySection gameProductions={gameCategory} onClickCard={onGetGameById} />
              )}
              {!!podcastsCategory?.productions.length && <PodcastSection podcastProductions={podcastsCategory} />}
            </StyledSection>
            <div className="flex justify-center my-16">
              <Button onClick={() => navigateTo("/catalog")}>Ver todo el catálogo</Button>
            </div>
          </StyledContainer>
        ) : (
          <SearchNotFound navigateTo={navigateTo} searchTerm={searchTerm} />
        )}
      </StyledSearchPage>
    </DefaultLayout>
  );
};
