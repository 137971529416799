import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useCaseCourseDetail, useCaseCourses } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { CoursesDataService } from "../../../data/services/course.data.service";
import { CoursesEntity } from "../../../domain/entities";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gradeSchema } from "../../validators/gradeSchema";
import toast from "react-hot-toast";


interface CourseQuizInterface {
  id: number,
  title: string,
  description: string,
  course_uuid: string,
  quiz_type: string,
  difficulty_level: string,
  passing_score: number,
  duration: number,
  is_published: true,
  taken_at: string,
  grade: number,
  grade_id: null,
  questions_count: number,
  correct_answers: number,
  feedback: string | null,
}


export function useStudentCourseQuizViewModel() {

  const { getCurrentPathName, navigateTo, getQueryParam } = useNavigation();

  const [courseQuiz, setCourseQuiz] = useState<Array<CourseQuizInterface>
  >([]);

  const courseId = getQueryParam("course")


  const { getStudentCourseQuiz } =
    useCaseCourseDetail(CourseDetailsDataService());

  const getCoursesQuizSuccess = async (courseQuiz: CourseQuizInterface[]) => {
    setCourseQuiz(courseQuiz)
  }


  const getCoursesQuizError = async (Error: AxiosError) => {
    toast.error("")
  }

  useCustomEffect(() => {
    getStudentCourseQuiz({
      success: getCoursesQuizSuccess,
      error: getCoursesQuizError,
      courseId: courseId
    })
  }, []);

  return {
    getCurrentPathName,
    navigateTo,
    courseQuiz
  };
}
