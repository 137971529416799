import React, { createContext, useEffect, useState, ReactNode } from "react";

interface LanguageContextType {
  language: string;
}

export const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>(navigator.language || "es");

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const detectedLanguage =
        document.documentElement.lang || navigator.language;
      if (detectedLanguage !== language) {
        setLanguage(detectedLanguage);
      }
    });

    observer.observe(document.documentElement, { attributes: true });

    return () => observer.disconnect();
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language }}>
      {children}
    </LanguageContext.Provider>
  );
};
