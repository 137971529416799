import { FC } from "react";
import { Breadcrumb, PageLoader } from "../../components";
import { DefaultLayout } from "../../layouts";
import { StyledLearningPathDetailContainer } from "./learningPathDetail.styles";
import { ModalsLearningPath } from "./components/modals";
import { useLearningPathDetailViewModel } from "../../viewModels";
import { StyledBlurWall } from "../preview/preview.styles";
import { InformationSection, UsersCommentsSection, VideoPreview } from "../preview/components";
import LearningPathProduction from "../home/components/LearningPaths/LearningPathsProduction/learningPathsProduction";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Caminos de Aprendizaje",
    url: "/learning-path",
  },
  {
    name: "Camino",
  },
];

export const LearningPathDetail: FC = () => {
  const {
    token,
    roles,
    learningProgress,
    videoOptions,
    detailLearningPath,
    detailGoldMedal,
    handleStartLearningPath,
    showLogInModal,
    setShowLoginModal,
    setShowStartPathModal,
    showStartPathModal,
    showSuccessModal,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
    currentProduction,
    changeProduction,
    nextChapter,
    currentChapter,
    handlePlayerReady,
    onChapterClick,
    similarProductions,
    videoTimeLeft,
    handleSaveProduction,
    isProductionSaved,
    onCancelRating,
    onClickRatingButton,
    showModalRating,
    onSubmitRating,
    userCanComment,
    productionOptions,
    currentProductionNumber,
    handleChangeCommentsPage,
    filteredUserComments,
    userSubscription,
  } = useLearningPathDetailViewModel();

  return (
    <DefaultLayout>
      {detailLearningPath.uuid ? (
        <div>
          <div className="px-8 pb-8 lg:px-14 hidden lg:block">
            <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
          </div>
          <StyledLearningPathDetailContainer>
            <div className="block lg:flex items-start w-full gap-6 mb-36 lg:mb-10 ">
              <div className="w-full lg:w-[60%] bg-black rounded-lg">
                <VideoPreview
                  videoOptions={videoOptions}
                  imgPreview={currentChapter ? currentChapter?.imgPreview : ""}
                  format={currentProduction ? currentProduction.productionType : ""}
                  terms={currentProduction ? currentProduction.terms : []}
                  duration={currentProduction ? currentProduction.duration : 0}
                  handlePlayerReady={handlePlayerReady}
                  timeLeft={videoTimeLeft}
                  chapterName={currentChapter ? currentChapter.name : ""}
                  productionName={currentProduction ? currentProduction.title : detailLearningPath.title}
                  currentSeasonNumber={1}
                  nextChapterName={nextChapter?.name ? nextChapter.name : ""}
                  nextChapterImage={nextChapter?.imgPreview ? nextChapter.imgPreview : ""}
                  similarProductions={similarProductions}
                />
                <InformationSection
                  progress={learningProgress}
                  token={token}
                  title={
                    currentChapter?.name
                      ? currentChapter.name
                      : currentProduction
                      ? currentProduction.title
                      : detailLearningPath.title
                  }
                  description={currentChapter ? currentChapter.description : currentProduction.description}
                  roles={roles}
                  onClick={handleSaveProduction}
                  isProductionSaved={isProductionSaved}
                  isProductionRented={detailLearningPath.canWatch}
                  isSubscriptionActive={userSubscription?.stripeStatus === "active" ? true : false}
                  price={detailLearningPath.price}
                  navigateToRental={() => {
                    handleStartPath();
                  }}
                  onSubmitRating={onSubmitRating}
                  onCancelRating={onCancelRating}
                  onClickRatingButton={onClickRatingButton}
                  showModalRating={showModalRating}
                  userCanComment={userCanComment}
                  detailLearningPath={detailLearningPath}
                />
              </div>
              <div className="w-full lg:w-[40%] bg-black rounded-lg pb-6">
                <div className="StyledSubscribedContent relative">
                  {!detailLearningPath.canWatch && userSubscription?.stripeStatus !== "active" && <StyledBlurWall />}
                  {detailLearningPath.productions && (
                    <LearningPathProduction
                      currentProductionNumber={currentProductionNumber}
                      productionOptions={productionOptions}
                      onChapterClick={onChapterClick}
                      production={currentProduction}
                      title={detailLearningPath.title}
                      canWatch={detailLearningPath.canWatch}
                      uuid={detailLearningPath.uuid}
                      image={detailLearningPath.image}
                      changeProduction={changeProduction}
                    />
                  )}

                  {!!filteredUserComments?.items?.length && (
                    <UsersCommentsSection
                      comments={filteredUserComments}
                      handleChangeCommentsPage={handleChangeCommentsPage}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="px-8 lg:pl-56"></div>
          </StyledLearningPathDetailContainer>
          <ModalsLearningPath
            showLogInModal={showLogInModal}
            showStartPathModal={showStartPathModal}
            setShowStartPathModal={setShowStartPathModal}
            handleStartLearningPath={handleStartLearningPath}
            learningPathId={detailLearningPath.uuid}
            learningPathTitle={detailLearningPath.title}
            medalImage={detailGoldMedal ? detailGoldMedal.imgPreview : ""}
            setShowLoginModal={setShowLoginModal}
            showSuccessModal={showSuccessModal}
            handleRedirectToFirstProduction={handleRedirectToFirstProduction}
            handleNotSubscribedModal={handleNotSubscribedModal}
            showNotSubscribedModal={showNotSubscribedModal}
            showTransactionModal={showTransactionModal}
            handleTransactionModal={handleTransactionModal}
            transactionFinalStatus={transactionFinalStatus}
          />
        </div>
      ) : (
        <PageLoader />
      )}
    </DefaultLayout>
  );
};
