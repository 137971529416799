import { ArrowRight, BookOpenCheck, ChevronDown, ChevronUp, Play, SquareCheckBig, Trash2 } from "lucide-react";
import { FC, useContext, useState } from "react";
import { useNavigation, useWindowSize } from "../../../../hooks";
import { ChapterEntity, QuizEntity } from "../../../../../domain/entities";
import { Button } from "../../../../components";
import { useProfileDropDown } from "../../../../components/ProfileDropDown/useProfileDropDown";
import { ChapterCard } from "../ChapterCard/ChapterCard";
import { StyledBlurWall } from "../../../preview/preview.styles";
import { ClickAwayListener } from "@mui/material";
import toast from "react-hot-toast";
import { LanguageContext } from "../../../../context/LanguageContext";

interface CourseAssessmentCardProps {
  courseId: string;
  productionId?: any;
  title: string;
  description: string;
  duration: number;
  accessmentType: string;
  imageUrl: string;
  quiz?: QuizEntity | null;
  link?: string;
  assessmentExist: boolean;
  isCompleted: number;
  previousCompleted: number;
  handleStartChapter: (chapterId: string) => void;
  courseProgress?: string;
  available: number;
  handleShowAssementForm?: () => void;
  handleDeleteQuiz: (quizId: number) => void;
}

export const CourseAssessmentCard: FC<CourseAssessmentCardProps> = ({
  courseId,
  productionId,
  title,
  description,
  duration,
  accessmentType,
  imageUrl,
  quiz,
  link,
  assessmentExist,
  isCompleted,
  previousCompleted,
  handleStartChapter,
  courseProgress,
  available,
  handleShowAssementForm,
  handleDeleteQuiz,
}) => {
  const { navigateTo } = useNavigation();
  const { isDesktop } = useWindowSize();
  const { user } = useProfileDropDown();
  const [showDropDown, setShowDropDown] = useState(false);
  const handleClickAway = () => {
    setShowDropDown(false);
  };
  const languageContext = useContext(LanguageContext);
  const language = languageContext?.language || "es"; // Fallback to Spanish

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className="flex flex-col w-full hover:bg-secondary-100  bg-secondary-200 rounded-xl mb-4 relative"
        onClick={() => {
          if (!showDropDown) {
            setShowDropDown(!showDropDown);
          }
        }}
        key={language}
      >
        {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "owner" && available < 1 && (
          <StyledBlurWall />
        )}
        <div className="flex items-center justify-between w-full"></div>
        <div className="">
          <div className="flex items-center p-4 ">
            <div className="w-full flex gap-4 items-center">
              <BookOpenCheck className="h-12 w-12 text-primary-50" />
              <h2 className="text-lg font-semibold text-white mb-1 leading-tight line-clamp-2">{title}</h2>
            </div>
            <button
              className="text-white px-4 cursor-pointer w-6"
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown((prev) => !prev);
              }}
            >
              {showDropDown ? <ChevronUp /> : <ChevronDown />}
            </button>
          </div>
          {showDropDown && (
            <div>
              <p className="text-xs lg:text-sm text-justify text-white leading-relaxed px-4 pb-4">{description}</p>

              {quiz && (
                <div className="relative">
                  <div className="flex flex-col gap-1 w-full px-6 py-4 border-t border-solid border-gray-400 relative">
                    {/* {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "owner" && isCompleted > 0 && (
                      <StyledBlurWall />
                    )} */}
                    <div className="w-full flex items-center justify-between">
                      <h4 className="text-lg font-semibold text-white mb-1 leading-tight line-clamp-2">
                        Prueba: {quiz?.title}
                      </h4>
                      <span
                        className={` ${
                          quiz?.difficulty_level === "hard"
                            ? "bg-primary  text-white"
                            : quiz?.difficulty_level === "medium"
                            ? "bg-[#14a3fc] text-white"
                            : "bg-[#04d183] text-white"
                        } rounded-[25px] px-4 py-1 text-sm`}
                      >
                        {quiz?.difficulty_level}
                      </span>
                    </div>
                    <div className="text-sm text-white font-primarySemibold">{quiz?.description}</div>
                    <div className="w-full flex items-center gap-4">
                      <div className="flex items-center justify-between text-primary gap-2 text-xs font-bold">
                        Puntuación de aprobación
                        <p>{quiz?.passing_score}</p>
                      </div>
                      <div className="flex items-center justify-between text-gray-50 gap-2 text-xs font-bold">
                        Duración
                        <p>{quiz?.duration}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={` ${
                  assessmentExist && quiz && isCompleted && accessmentType === "production" ? "h-16" : ""
                }  px-6 pb-4`}
              >
                {quiz && (
                  <div>
                    {user?.roles[0] &&
                      user?.roles[0].slug &&
                      user?.roles[0].slug !== "professor" &&
                      isCompleted > 0 && (
                        <Button
                          onClick={() => {
                            navigateTo(`/course/quiz?quiz=${quiz?.id}`);
                          }}
                          color="primary"
                          className="text-primary-50 my-0 w-full"
                        >
                          Ver cuestionario
                        </Button>
                      )}
                  </div>
                )}
                {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
                  <div className="h-full">
                    {assessmentExist && (
                      <div>
                        {accessmentType === "production" ? (
                          <div className="flex items-center gap-4">
                            <Button
                              onClick={() => {
                                navigateTo(
                                  `/quiz?courseId=${courseId}&production=${productionId}&quiz=${
                                    quiz?.id ? quiz?.id : ""
                                  }&type=${accessmentType}`
                                );
                              }}
                              color="primary"
                              className="text-primary-50 my-0 w-full"
                            >
                              {quiz ? "Editar cuestionario" : "Establecer cuestionario"}
                            </Button>

                            {quiz && (
                              <button
                                className="text-primary-50"
                                onClick={() => {
                                  quiz?.id && handleDeleteQuiz(quiz?.id);
                                }}
                              >
                                <Trash2 />
                              </button>
                            )}
                          </div>
                        ) : (
                          <Button
                            onClick={handleShowAssementForm}
                            color="primary"
                            className="text-primary-50 my-0 w-full"
                          >
                            Editar evaluación
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
