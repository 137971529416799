import { Check } from "lucide-react";
import { useState, useRef, useEffect } from "react";
import { useStudentSelectorViewModel } from "../../../../viewModels";
import { Button, Paginator } from "../../../../components";
interface StudentI {
  id: number;
  name: string;
  last_name: string;
  email: string;
  country: string;
  registered: boolean;
}
export function StudentSelector() {
  const { rentedStudent, handleRegisterStudent, handlePageNewPage } =
    useStudentSelectorViewModel();
  return (
    <div className=" mx-6 lg:mx-16  bg-black p-8 rounded-lg shadow-md my-12 overflow-x-auto">
      <h2 className="text-3xl font-bold text-left mb-8 text-white">
        Registrar estudiante al curso
      </h2>
      <table className="min-w-full divide-y divide-gray-700 ">
        <thead className="sticky top-0">
          <tr className="bg-primary-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer">
              Identificación
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
              Nombre
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
              correo electrónico
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
              país
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {rentedStudent?.users.map((student) => (
            <tr
              key={`${student.id}-${student.id}`}
              className="hover:bg-gray-700"
            >
              <td className="px-6 py-4 whitespace-nowrap  text-sm text-gray-300">
                {student.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                {student.name} {student.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                {student.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                {student.country}
              </td>

              <td className="px-6 py-4 whitespace-nowrap ">
                <span
                  style={{
                    color: !student.registered ? "#e30613" : "#5cb85c",
                  }}
                  className={`${
                    student.registered ? "text-[#5cb85c]" : "text-primary"
                  }  cursor-pointer`}
                  onClick={() => {
                    !student.registered && handleRegisterStudent([student.id]);
                  }}
                >
                  {student.registered ? "Aprobada" : "Aprobar"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-end items-center">
        <Paginator
          className="mx-auto mt-8"
          page={rentedStudent?.current_page}
          totalPages={rentedStudent?.total_pages}
          onPageChange={(event, page) => {
            handlePageNewPage(page);
          }}
        />
      </div>
    </div>
  );
}
