import { Button, Carousel } from "../../components";
import { Status } from "../../components/Status";
import { DefaultLayout } from "../../layouts";
import LearningPathSessionCard from "./components/learningPathSessionCard";
import { administratorSessionPageViewModel } from "../../viewModels/administratorSessionPageViewModel";
import { useNavigation } from "../../hooks";

export const AdministratorSessionPage = () => {
  const { navigateTo } = useNavigation();
  const { options, getListOfGrpoupLearningPathsSession } =
    administratorSessionPageViewModel();
  return (
    <DefaultLayout isAbsolute>
      <div className="relative flex size-full min-h-screen flex-col justify-between  overflow-x-hidden  pt-24 lg:pt-36">
        <div className="p-4 w-full lg:mx-auto max-w-[90rem]">
          <h2 className="text-primary-50 text-2xl lg:text-3xl font-bold flex-1 text-center  py-8 flex justify-center  mx-8 lg:mx-0">
            Sesión de rutas de aprendizaje
          </h2>

          <div className="flex items-center justify-center h-60 font-primaryBold text-white text-xl">
            <p>
              No hay sesiones de ruta de aprendizaje disponibles en este momento
            </p>
          </div>
          <div className="w-full flex flex-col gap-8 md:mx-auto px-8 ">
            {getListOfGrpoupLearningPathsSession.map(
              (learningPath, index: number) => (
                <div key={index}>
                  <h2 className="text-white text-2xl lg:text-3xl font-bold flex-1 text-center pr-12 py-4 flex justify-center lg:justify-start">
                    {learningPath.name}
                  </h2>
                  <Carousel
                    slidesToShow={3}
                    slidesToScroll={1}
                    infinite={false}
                    dots={false}
                    responsive={[
                      {
                        breakpoint: 1280,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          infinite: false,
                          dots: false,
                          arrows: true,
                          centerMode: false,
                          centerPadding: "64px",
                        },
                      },
                      {
                        breakpoint: 824,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                          infinite: false,
                          dots: false,
                          arrows: true,
                          centerMode: false,
                          centerPadding: "64px",
                        },
                      },
                      {
                        breakpoint: 650,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: false,
                          centerMode: true,
                          dots: false,
                          arrows: true,
                        },
                      },
                    ]}
                  >
                    {learningPath.sessions.map((session) => (
                      <LearningPathSessionCard
                        options={options}
                        session={session}
                        key={session.id}
                      />
                    ))}
                  </Carousel>
                </div>
              )
            )}

            <div className="flex items-center justify-center mt-8">
              <Button
                className="w-full md:w-64"
                onClick={() => {
                  navigateTo(`/admin-learning-path-session`);
                }}
              >
                Agendar nueva sesión
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
