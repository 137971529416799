import tw from "twin.macro";
import styled from "styled-components";
import "video.js/dist/video-js.css";
import pauseButtonIcon from "../../assets/images/pauseButtonIcon.svg";
import pauseButtonHoverIcon from "../../assets/images/pauseButtonHoverIcon.svg";
import playButtonIcon from "../../assets/images/playButtonIcon.svg";
import playButtonWithoutHoverIcon from "../../assets/images/playButtonWithoutHoverIcon.svg";
import reloadVideoIcon from "../../assets/images/reloadVideoIcon.svg";
import reloadVideoHoverIcon from "../../assets/images/reloadVideoHoverIcon.svg";
import closeIcon from "../../assets/images/closeIcon.svg";
import closeHoverIcon from "../../assets/images/closeHoverIcon.svg";
import chaptersIcon from "../../assets/images/chaptersIcon.svg";
import chaptersHoverIcon from "../../assets/images/chaptersHoverIcon.svg";
import nextChapterIcon from "../../assets/images/nextChapterIcon.svg";
import nextChapterHoverIcon from "../../assets/images/nextChapterHoverIcon.svg";
import getOutFullScreenIcon from "../../assets/images/getOutFullScreenIcon.svg";
import getOutFullScreenHoverIcon from "../../assets/images/getOutFullScreenHoverIcon.svg";
import delayIcon from "../../assets/images/chevronLeftIcon.svg";
import delayHoverIcon from "../../assets/images/chevronLeftHoverIcon.svg";
import fordWardIcon from "../../assets/images/chevronRightIcon.svg";
import fordWardHoverIcon from "../../assets/images/chevronRightHoverIcon.svg";

export const StyledVideo = styled.video.attrs((props) => ({
  className: props.className,
}))`
  ${tw`w-full`}

  .vjs-poster {
    background-size: cover;
    background-position: center;
  }

  .vjs-modal-dialog-content {
    display: none;
  }

  .vjs-big-play-button {
    background-color: transparent !important;
    width: 80px;
    height: 80px;
    background-image: url(${playButtonIcon});
    background-repeat: no-repeat;
    background-position: center;
    border: none !important; // @stylint ignore

    span:before {
      content: "" !important;
      display: none !important;
    }
    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }
    &:focus-visible {
      outline: none !important;
    }
  }

  .vjs-control-bar {
    ${tw`h-full bg-secondary-300 bg-opacity-50`}
    .vjs-current-time {
      ${tw`absolute block text-white bottom-0 right-0 p-0`}
      height: min-content;
      font-family: Carnero-Bold, sans-serif;
      font-size: 18px;
      line-height: 20px;
      position: absolute;
      transform: translate(-8px, -24px);
      @media (max-width: 1024px) {
        font-size: 2vw;
        ${tw`absolute top-0 left-0`}
        width: min-content;
        transform: translate(8px, 8px);
      }
    }
    .vjs-play-control {
      ${tw`absolute z-10 w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:focus-visible {
        outline: none !important;
      }
    }
    .vjs-playing {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        content: "" !important;
        background-image: url(${pauseButtonIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .vjs-playing:hover {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        content: "" !important;
        background-image: url(${pauseButtonHoverIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 1024px) {
          background-image: url(${pauseButtonIcon}) !important;
        }
      }
    }
    .vjs-paused {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        content: "" !important;
        background-image: url(${playButtonWithoutHoverIcon});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .vjs-paused:hover {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        content: "" !important;
        background-image: url(${playButtonIcon});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .vjs-ended {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        content: "" !important;
        background-image: url(${reloadVideoIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .vjs-ended:hover {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        content: "" !important;
        background-image: url(${reloadVideoHoverIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 1024px) {
          background-image: url(${reloadVideoIcon}) !important;
        }
      }
    }

    .vjs-close-full-screen-button {
      ${tw`absolute w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
      top: 56px !important;
      right: 56px !important;
      @media (max-width: 1024px) {
        top: 20px !important;
        right: 20px !important;
      }
      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${closeIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        span:before {
          background-image: url(${closeHoverIcon}) !important;
          @media (max-width: 1024px) {
            background-image: url(${closeIcon}) !important;
          }
        }
      }
    }

    .vjs-delay-button {
      ${tw`absolute w-[4vw] h-[4vw] max-w-[56px] max-h-[56px] top-1/2 left-[10%]`}
      transform: translate(0,-50%);

      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${delayIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      span:after {
        ${tw``}
        font-family: Carnero-Bold, sans-serif;
        color: white;
        font-size: 1.4vw;
        line-height: 2vw;
        position: absolute;
        left: 200%;
        top: 50%;
        transform: translate(0, -50%);
        content: "10" !important;
      }
      &:hover {
        span:before {
          background-image: url(${delayHoverIcon}) !important;
          @media (max-width: 1024px) {
            background-image: url(${delayIcon}) !important;
          }
        }
        span:after {
          color: #e30613;
          @media (max-width: 1024px) {
            color: white;
          }
        }
      }
    }
    .vjs-forward-button {
      ${tw`absolute w-[4vw] h-[4vw] max-w-[56px] max-h-[56px] top-1/2 right-[10%]`}
      transform: translate(-50%,-50%);

      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${fordWardIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      span:after {
        ${tw``}
        font-family: Carnero-Bold, sans-serif;
        color: white;
        font-size: 1.4vw;
        line-height: 2vw;
        position: absolute;
        right: 200%;
        top: 50%;
        transform: translate(0, -50%);
        content: "10" !important;
      }
      &:hover {
        span:before {
          background-image: url(${fordWardHoverIcon}) !important;
          @media (max-width: 1024px) {
            background-image: url(${fordWardIcon}) !important;
          }
        }
        span:after {
          color: #e30613;
          @media (max-width: 1024px) {
            color: white;
          }
        }
      }
    }

    .vjs-progress-control {
      ${tw`absolute z-10 w-full h-4 lg:(h-6 max-h-6) bottom-0`}
      .vjs-progress-holder {
        ${tw`h-full m-0 bg-white`}
        .vjs-load-progress {
          ${tw`hidden`}
        }
        .vjs-play-progress {
          ${tw`bg-primary-50`}
          :before {
            ${tw`hidden`}
          }
        }
      }
    }
  }

  .vjs-new-control-bar {
    ${tw`absolute w-min bg-secondary-300 bg-opacity-50 rounded-lg px-2 py-3 lg:(px-4 py-5 rounded-xl) max-h-24 grid gap-x-6 items-center `}
    height: min-content;
    grid-template-columns: 4vw 3vw 4vw;
    grid-template-rows: 1fr;
    bottom: 48px;
    left: 100%;
    transform: translate(calc(-100% - 56px), 0px);
    .vjs-picture-in-picture-control {
      ${tw`hidden`}
    }

    .vjs-chapters-button {
      ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
      grid-column: 1 / 2;
      grid-row: 1;
      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${chaptersIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .vjs-chapters-button:hover {
      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${chaptersHoverIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 1024px) {
          background-image: url(${chaptersIcon}) !important;
        }
      }
    }

    .vjs-next-chapter-button {
      ${tw`w-[3vw] h-[4vw] max-w-[32px] max-h-[56px]`}
      grid-column: 2 / 3;
      grid-row: 1;
      span:before {
        ${tw`w-[3vw] h-[4vw] max-w-[32px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${nextChapterIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .vjs-next-chapter-button:hover {
      span:before {
        ${tw`w-[3vw] h-[4vw] max-w-[32px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${nextChapterHoverIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 1024px) {
          background-image: url(${nextChapterIcon}) !important;
        }
      }
    }

    .vjs-fullscreen-control {
      ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
      grid-column: 3 / 4;
      grid-row: 1;
      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${getOutFullScreenIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .vjs-fullscreen-control:hover {
      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
        content: "" !important;
        background-image: url(${getOutFullScreenHoverIcon}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 1024px) {
          background-image: url(${getOutFullScreenIcon}) !important;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .vjs-control-bar {
      .vjs-play-control {
        ${tw`w-[12vw] h-[12vw] max-w-[80px] max-h-[80px]`}
      }
      .vjs-playing,
      .vjs-paused,
      .vjs-ended {
        span:before {
          ${tw`w-[12vw] h-[12vw] max-w-[80px] max-h-[80px]`}
        }
        &:hover {
          span:before {
            ${tw`w-[12vw] h-[12vw] max-w-[80px] max-h-[80px]`}
          }
        }
      }
      .vjs-delay-button,
      .vjs-forward-button,
      .vjs-new-chapter-name,
      .vjs-title-video,
      .vjs-similar-productions-container,
      .vjs-first-production,
      .vjs-second-production,
      .vjs-third-production,
      .vjs-next-chapter-card {
        ${tw`hidden`}
      }
    }
    .vjs-new-control-bar {
      ${tw`flex bg-transparent`}
      transform: translate(calc(-100% - 24px), 0px);
      .vjs-fullscreen-control {
        ${tw`w-[12vw] h-[12vw] max-w-[40px] max-h-[40px]`}
        span:before {
          ${tw`w-[12vw] h-[12vw] max-w-[40px] max-h-[40px]`}
        }
        &:hover {
          span:before {
            ${tw`w-[12vw] h-[12vw] max-w-[40px] max-h-[40px]`}
          }
        }
      }
      .vjs-chapters-button,
      .vjs-next-chapter-button {
        ${tw`hidden`}
      }
    }
  }
  .vjs-important-fullscreen {
    .vjs-current-time {
      ${tw`absolute block text-white top-full left-full`}
      transform: translate(calc(-100% - 16px), calc(-100% - 24px));
      @media (max-width: 1024px) {
        transform: translate(calc(-100% - 8px), calc(-100% - 16px));
      }
    }
    .vjs-play-control {
      ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
    }
    .vjs-playing,
    .vjs-paused,
    .vjs-ended {
      span:before {
        ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
      }
      &:hover {
        span:before {
          ${tw`w-[6vw] h-[6vw] max-w-[80px] max-h-[80px]`}
        }
      }
    }

    .vjs-delay-button,
    .vjs-forward-button,
    .vjs-new-chapter-name,
    .vjs-title-video,
    .vjs-chapters-button,
    .vjs-next-chapter-button,
    .vjs-first-production,
    .vjs-second-production,
    .vjs-third-production,
    .vjs-next-chapter-card {
      ${tw`block`}
    }
    .vjs-similar-productions-container {
      ${tw`flex`}
    }
    .vjs-fullscreen-control {
      ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
      span:before {
        ${tw`w-[4vw] h-[4vw] max-w-[56px] max-h-[56px]`}
      }
    }
    &.vjs-new-control-bar {
      display: grid !important;
      ${tw`bg-secondary-300 bg-opacity-50`}
      transform: translate(calc(-100% - 56px), 0px);
      @media (max-width: 1024px) {
        transform: translate(calc(-100% - 48px), 0px);
      }
    }
  }
  .vjs-hidden-on-unsubscribe {
    &.vjs-control-bar {
      .vjs-next-chapter-card {
        ${tw`hidden`}
      }
    }
    &.vjs-new-control-bar {
      ${tw`bg-transparent`}
      .vjs-chapters-button,
      .vjs-next-chapter-button {
        ${tw`hidden`}
      }
    }
  }

  .vjs-hidden-on-podcast {
    &.vjs-new-control-bar {
      ${tw`hidden`}
    }
  }
`;
