import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSessionToken, getSubscriptionStatus, getUser } from "../../../data/dto/selectors";
import { QuizEntity } from "../../../domain/entities";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { assessmentSchema } from "../../validators";
import { QuizDetailDataService } from "../../../data/services";
import { useCaseCourseDetail, useCaseCourseQuiz } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";

interface StudentI {
  id: number,
  name: string,
  last_name: string,
  email: string,
  country: string,
  registered: boolean
}
interface StudentResponseSchema {
  users: StudentI[];
  current_page: number,
  total_pages: number,
  per_page: number,
  total_items: number,
  has_more_pages: boolean,
}

export function useStudentSelectorViewModel() {
  const { getRentedUsers, postRegisterStudentToCourse } =
    useCaseCourseDetail(CourseDetailsDataService());
  const { getQueryParam, navigateTo } = useNavigation();
  const [currentPage, setCurrentPage] = useState(1)
  const courseId = getQueryParam("course");

  const [rentedStudent, setRentedSTudent] = useState<StudentResponseSchema>()

  const postRentStudentSuccess = async (data: any) => {
    handlePageNewPage(currentPage)
    if (data.code === 200) {
      toast.success("Estudiante registrado al curso exitosamente")
    }
  }

  const postRentStudentError = async (error: AxiosError) => {
    console.log(error)
  }

  const handleRegisterStudent = async (studentIds: number[]) => {
    postRegisterStudentToCourse({ success: postRentStudentSuccess, error: postRentStudentError, courseId, studentIds })
  }

  const getRentedStudentSuccess = async (response: StudentResponseSchema) => {
    setRentedSTudent(response)
  }

  const getRentedStudentError = async (error: AxiosError) => {
    console.log(error)
  }

  const handlePageNewPage = (page: number) => {
    setCurrentPage(page)
    getRentedUsers({ success: getRentedStudentSuccess, error: getRentedStudentError, courseId: courseId, currentPage: page })

  }

  useCustomEffect(() => {
    handlePageNewPage(currentPage)
  }, [])


  return {
    courseId,
    rentedStudent,
    handleRegisterStudent,
    handlePageNewPage
  };
}
