import { FC } from "react";
import { Rating } from "../../../../components";
import { useNavigation } from "../../../../hooks";

interface CourseCardProps {
  uuid: string;
  image: string;
  title: string;
  description: string;
  rating?: number;
}

export const CourseCard: FC<CourseCardProps> = ({
  uuid,
  image,
  title,
  description,
  rating,
}) => {
  const { navigateTo } = useNavigation();

  return (
    <div
      className="overflow-hidden cursor-pointer px-2 w-full lg:px-4"
      onClick={() => navigateTo(`/course-detail?uuid=${uuid}`)}
    >
      <div className="w-full max-w-[440px] mx-auto">
        <img
          src={image}
          className="object-cover w-full rounded-3xl"
          style={{ height: "260px" }}
        />
        <div className="grid flex-col gap-2 p-6 lg:py-6 lg:px-0 place-items-center lg:place-items-start place-content-center lg:place-content-start">
          {/* <div className="flex justify-center">
            <Rating id={"rating"} name={"rating"} value={4} readOnly={true} />
          </div> */}
          <h2
            className={`text-white font-bold text-center lg:text-left   ${
              title?.length > 24
                ? title.length > 40
                  ? "lg:text-sm"
                  : "lg:text-xl"
                : "lg:text-2xl"
            } `}
          >
            {title}
          </h2>
          <p className=" text-white text-xs lg:text-left text-center lg:text-base font-primaryBook">
            {`${
              // description.substring(0, 80):
              description ? description.substring(0, 120) : ""
            }...`}
            <span className="font-primarySemiBold text-primary"> Ver más</span>
          </p>
          <div></div>
        </div>
      </div>
    </div>
  );
};
