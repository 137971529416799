import tw from "twin.macro";
import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import { Pagination, PaginationItem, PaginationProps } from "@mui/material";

export const StyledPaginatorContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`relative`}
`;

export const StyledPagination = styledMui(Pagination)<PaginationProps>(
  ({ theme }) => ({
    color: "#fff",
    "& .MuiPagination-ul": {
      "& .MuiPaginationItem-root": {
        color: "#fff",
        transition: "0.3s ease-in-out",
        "&.Mui-selected": {
          backgroundColor: "#C1101A",
        },
      },
    },
  })
);

export const StyledPaginationItem = styledMui(PaginationItem)(
  ({ disabled }) => ({
    ...(disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
      backgroundColor: "#444 !important",
      color: "#aaa !important",
    }),
  })
);
