import {
  BookOpen,
  Users,
  SquareCheckBig,
  Clock4,
  CircleCheckBig,
  CircleX,
  TriangleAlert,
  Download,
  Filter,
} from "lucide-react";
import {
  Breadcrumb,
  Button,
  Paginator,
  SelectControlled,
} from "../../components";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { useNavigation } from "../../hooks";
import GradebookTable from "./components/StudentQuizTable/GradeTable";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTeacherViewModel } from "../../viewModels";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Libro de calificaciones",
  },
];
export const TeacherGearBox = () => {

  const {
    courses,
    grades,
    handleGetCourseGrade,
    currentCourse,
    currentPage,
    handlePageNewPage,
  } = useTeacherViewModel();

  const { control } = useForm();
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc" as "asc" | "desc",
  });
  const handleUpdateGrade = (
    studentId: string,
    assessmentId: string,
    score: number
  ) => {};
  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Libro de Calificaciones
          </h2>
          <div className="flex items-center gap-4"></div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          <CountCard
            title="Estudiantes Activos"
            icon={<Users color="#E30613" width={30} height={30} />}
            count={grades ? grades?.users.length : 0}
          />
          <CountCard
            title="Aprobado"
            icon={<CircleCheckBig color="#047857" width={30} height={30} />}
            count={
              grades
                ? grades.users.filter((item) => item.finalGrade > 75).length
                : 0
            }
          />
          <CountCard
            title="Fallida"
            icon={<CircleX color="#E30613" width={30} height={30} />}
            count={
              grades
                ? grades.users.filter(
                    (item) => item.assessment_score < 75 && item.completed
                  ).length
                : 0
            }
          />
        </div>

        <div className="flex items-center justify-end w-full mt-6">
          <div className="flex items-center justify-end w-[20rem] gap-6 ">
            <div className="w-full flex items-center gap-2">
              <Filter className="h-8 w-8 text-white" />
              <SelectControlled
                value={currentCourse}
                id={"status"}
                control={control}
                name="status"
                placeholder="Elige tu Status"
                error={false}
                helperText={""}
                onChange={(data) => {
                  handleGetCourseGrade(`${data.target.value}`, currentPage);
                }}
                options={courses.map((course) => {
                  return { name: course.title, id: course.uuid };
                })}
              />
            </div>
          </div>
        </div>
        {/* Grade table */}
        <div className="my-6">
          <GradebookTable
            currentCourse={currentCourse}
            students={grades ? grades.users : []}
            onUpdateGrade={handleUpdateGrade}
            sortConfig={sortConfig}
            onSort={setSortConfig}
          />

          <div className="flex items-center justify-end">
          
            <Paginator
              className="mx-auto mt-8"
              page={grades ? grades.current_page : 1}
              totalPages={grades ? grades.total_pages : 0}
              onPageChange={(event, page) => {
                handlePageNewPage(page);
              }}
            />
          </div>

        </div>
      </div>
    </DefaultLayout>
  );
};
