import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues, useFormState } from "react-hook-form";
import { newEvaluationSchema } from "../../validators/NewEvaluationSchema";
import { CourseSession, ICoursesData, QuizEntity, selectOptionEntity } from "../../../domain/entities";
import { useLayoutEffect, useState } from "react";
import { useCaseCourseDetail, useCaseCourseQuiz } from "../../../domain/useCases";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { CourseDetailModel } from "../../../data/models/courseDetail.model";
import toast from "react-hot-toast";
import { useNavigation } from "../../hooks";
import { AxiosError } from "axios";
import { QuizDetailDataService } from "../../../data/services";


export const useNewEvaluationViewModel = () => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(newEvaluationSchema) });
  const { dirtyFields } = useFormState({ control });
  const { getQueryParam, navigateTo } = useNavigation();
  const { getQuizDetails } =
    useCaseCourseQuiz(QuizDetailDataService());
  const { getCourseDetails, postPostRentCourse, submitQuiz } =
    useCaseCourseDetail(CourseDetailsDataService());
  const courseuuid = getQueryParam("courseId");
  const productionId = getQueryParam("production");
  const quizId = getQueryParam("quiz");
  const quizType = getQueryParam("type");
  const [courseProduction, setCourseProduction] = useState<Array<selectOptionEntity>>([])
  const [startQuestion, setStartQuestion] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questions, setQuestions] = useState<any>([])
  const [detailCourse, setDetailCourse] =
    useState<ICoursesData>({} as ICoursesData);
  const evaluationType: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Cuestionario de producción",
      label: "production_quiz",
    },

    {
      id: "2",
      name: "Cuestionario de evaluación",
      label: "assessment_quiz",
    },
  ];
  const isPublished: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Verdadero",
      label: "true",
    },

    {
      id: "1",
      name: "falsa",
      label: "false",
    },
  ];
  const defficultyLevel: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Fácil",
      label: "easy",
    },

    {
      id: "2",
      name: "Medio",
      label: "medium",
    },
    {
      id: "3",
      name: "Dura",
      label: "hard",
    },
  ];
  const courses: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Course 1",
      label: "Course 1",
    },
    {
      id: "2",
      name: "Course 2",
      label: "Course 2",
    },
    {
      id: "3",
      name: "Course 3",
      label: "Course 3",
    },
  ];

  const successSubmitQuiz = async (quiz: any) => {
    if (quiz.id) {
      navigateTo(`/quiz?courseId=${courseuuid}&production=${productionId}&quiz=${quiz.id}&type=${quizType}`)
    }
    toast.success("Cuestionario creado con éxito")
    setStartQuestion(true)
    window.scrollTo(0, 0);

  }
  const errorSubmitQuiz = async (error: AxiosError) => {
    toast.error(error.message)
  }

  const onSubmit = (data: FieldValues) => {
    const LevelType = defficultyLevel.filter((item) => item.id === data.
      difficultyLevel
    )[0].label
    const assementsId = detailCourse.assessment.id;

    submitQuiz(({
      success: successSubmitQuiz, error: errorSubmitQuiz, body: {
        quiz_type: quizType === "assessment" ? "assessment_quiz" : "production_quiz",
        course_production_id: parseInt(productionId),
        is_published: data.isPublished,
        duration: data.duration,
        passing_score: data.
          passingScore,
        difficulty_level: LevelType ? LevelType : "",
        description: data.
          description,
        title: data.title,
        assessment_id: assementsId,
        course_uuid: detailCourse.uuid,
      }, quizId: parseInt(quizId)
    }))

  };
  const onGetQuizSuccess = (quizDetails: QuizEntity) => {
    setQuestions(quizDetails.questions)
    setValue("title", quizDetails.title)
    setValue("difficultyLevel", quizDetails.difficulty_level)
    setValue("duration", quizDetails.duration)
    setValue("description", quizDetails.description ? quizDetails.description : "")
    setValue("isPublished", isPublished.filter((item) => item.label === `${quizDetails.is_published}`)[0]?.id)
    setValue("difficultyLevel", defficultyLevel.filter((item) => item.label === quizDetails.difficulty_level)[0]?.id)
    setValue("numberOfQuestions", quizDetails.questions ? quizDetails.questions.length : 3)
  }

  const errorQuizGetDetails = (error: AxiosError) => {
    toast.error(error.message)
  }
  useLayoutEffect(() => {
    if (quizId && quizId !== "undefined" && quizId !== "null") {
      getQuizDetails({ success: onGetQuizSuccess, error: errorQuizGetDetails, quizId: quizId })
    }
  }, [])

  const successGetCouresDestails = (courseDetails: CourseDetailModel) => {
    const courseProductions = courseDetails.productions.items.map((production, index) => { return { id: index + 1, available: 0, title: production.title, completed: production.completed ? production.completed : 0, duration: production.duration, description: production.description, chapter: production.chapters, quiz: production.quiz, accessmentType: "production", link: "", imageUrl: production.imgPreview } })
    const courseAssessment = { id: courseDetails.assessment.id, available: 0, title: courseDetails.assessment.title, duration: 0, description: "", chapter: [], completed: 0, quiz: courseDetails.assessment.quiz, accessmentType: "assessment", link: "", imageUrl: "" }
    const data = {
      uuid: courseDetails.uuid,
      title: courseDetails.title,
      progress: courseDetails.progress,
      duration: 0,
      image: courseDetails.image,
      courseSection: [...courseProductions, courseAssessment],
      assessment: courseDetails.assessment,
      canWatch: courseDetails.canWatch,
      price: courseDetails.price,
      assessmentPresent: courseDetails.assessment ? true : false
    }
    setCourseProduction(courseDetails.productions.items.map((production, index: number) => { return { id: `${index + 1}`, name: production.title, label: production.uuid } }))
    setDetailCourse(data)
  }
  const errorGetCouresDestails = (error: any) => {
  }
  useLayoutEffect(() => {
    setValue("passingScore", 70)
    setValue("isPublished", 1)
    setValue("numberOfQuestions", 3)

    getCourseDetails({
      success: successGetCouresDestails,
      error: errorGetCouresDestails, courseUuid: courseuuid,
      perPage: 1,
      currentPage: 1
    })
  }, [])

  return {
    handleSubmit,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,
    navigateTo,
    dirtyFields,
    evaluationType,
    defficultyLevel,
    courseProduction,
    courses,
    questions,
    isPublished,
    currentQuestionIndex,
    detailCourse,
    startQuestion
  };
};
