import { FC } from "react";
import { Button, Modal, TextFieldControlled } from "../../../../components";
import { useTeacherViewModel } from "../../../../viewModels";
import { useNavigation } from "../../../../hooks";

interface CourseTableItemProps {
  uuid: string;
  title: string;
  description: string;
  production_num: string;
  completed: string;
  production_score: number;
  assessment_score: number;
  final_grade: number;
  feedback: number;
}

export const StudentCourseTableItem: FC<CourseTableItemProps> = ({
  uuid,
  title,
  description,
  production_num,
  completed,
  production_score,
  assessment_score,
  final_grade,
  feedback,
}) => {
  const { navigateTo } = useNavigation();
  return (
    <tr key={`${uuid}-${uuid}`} className="hover:bg-gray-700">
      <td className="px-6 py-4 whitespace-nowrap">
        <div>
          <div className="text-sm font-medium text-white">{title}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {production_score}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {assessment_score}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {final_grade ? final_grade : "Nil"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {`${completed}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap"></td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        <span
          className="text-primary cursor-pointer text-sm font-base"
          onClick={() => {
            navigateTo(`/student/course-quiz?course=${uuid}`);
          }}
        >
          Cuestionario del curso
        </span>
      </td>
    </tr>
  );
};
