import { useState, useEffect, useContext } from "react";
import { QuizProgress, QuizResults, MatchingWordComponent, MultipleSelection, QuizQuestion } from "./components";

import { DefaultLayout } from "../../layouts";
import { Button } from "../../components";
import { useQuizDetailsViewModel } from "../../viewModels";
import { LanguageContext } from "../../context/LanguageContext";

export function QuizPage() {
  const {
    quizDetails,
    handleSubmitAnswer,
    showResults,
    currentQuestion,
    onAnswer,
    disableNext,
    results,
    timeEllapsed,
  } = useQuizDetailsViewModel();
  const [timeLeft, setTimeLeft] = useState<number>(0);
  useEffect(() => {
    setTimeLeft(quizDetails.duration * 60);
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          timeEllapsed();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [quizDetails.duration]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const languageContext = useContext(LanguageContext);
  const language = languageContext?.language || "es"; // Fallback to Spanish

  return (
    <DefaultLayout>
      {quizDetails.id && (
        <div className="min-h-screen text-white">
          {showResults ? (
            <div className="min-h-screen bg-black text-white py-8">
              <div className="max-w-4xl mx-auto px-4">
                <QuizResults
                  passingScore={results.passingScore}
                  score={results.grade}
                  totalQuestions={results.totalQuestions}
                  correctAnswers={results.correctAnswers}
                  incorrectAnswers={results.incorectAnswer}
                  failedMessage={results.failedMessage}
                />
              </div>
            </div>
          ) : (
            <div className="max-w-4xl mx-auto px-4 py-8">
              <div className="flex justify-between flex-col items-start mb-8 gap-4">
                <h1 className="text-3xl font-bold" key={`${language}-${quizDetails.title}`}>
                  {quizDetails.title}
                </h1>
                <p className="text-base font-primaryRegular">{quizDetails.description}</p>
              </div>
              <div>
                <div className="flex items-center space-x-2 ">
                  {/* <Clock className="h-5 w-5" /> */}
                  <div className="text-white flex items-center">
                    <p className="pr-1">Duración:</p>
                    <span className="text-primary-50" key={`${language}-${minutes}-${seconds}`}>
                      {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                    </span>
                  </div>
                </div>
              </div>

              {quizDetails.questions.length > 0 && (
                <div className="mt-8 flex flex-col gap-8 w-full">
                  {quizDetails.questions[currentQuestion]?.type === "single_selection" ? (
                    <QuizQuestion
                      id={quizDetails.questions[currentQuestion]?.id}
                      questionType={quizDetails.questions[currentQuestion]?.type}
                      options={quizDetails.questions[currentQuestion]?.answers}
                      question={quizDetails.questions[currentQuestion]?.text}
                      onAnswer={onAnswer}
                    />
                  ) : quizDetails.questions[currentQuestion]?.type === "multiple_selection" ? (
                    <MultipleSelection
                      id={quizDetails.questions[currentQuestion]?.id}
                      questionType={quizDetails.questions[currentQuestion]?.type}
                      options={quizDetails.questions[currentQuestion].answers}
                      question={quizDetails.questions[currentQuestion].text}
                      onAnswer={onAnswer}
                    />
                  ) : (
                    <MatchingWordComponent
                      id={quizDetails.questions[currentQuestion]?.id}
                      questionType={quizDetails.questions[currentQuestion]?.type}
                      options={quizDetails.questions[currentQuestion]?.answers}
                      onAnswer={onAnswer}
                      question={quizDetails.questions[currentQuestion]?.text}
                      selectedAnswer={[]}
                    />
                  )}
                </div>
              )}
              {quizDetails.questions.length > 0 && (
                <div>
                  <div>
                    <div className="flex gap-6 justify-end items-center m-6">
                      <Button
                        className="w-full lg:w-32"
                        onClick={() => {
                          handleSubmitAnswer();
                          window.scrollTo(0, 0);
                        }}
                        color="primary"
                        disabled={disableNext}
                      >
                        {currentQuestion === quizDetails.questions.length - 1 ? "Finalizar" : "Próxima"}
                      </Button>
                    </div>
                  </div>
                  <div className="mt-8 lg:flex w-full gap-6">
                    <div className="w-full ">
                      <QuizProgress
                        total={quizDetails.questions ? quizDetails.questions!.length : 0}
                        current={currentQuestion + 1}
                      />
                    </div>
                  </div>
                </div>
              )}

              {quizDetails.questions.length < 1 && (
                <div className="h-60 w-full flex items-center justify-center text-center text-2xl">
                  El cuestionario no tiene preguntas, contacte al profesor{" "}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </DefaultLayout>
  );
}
