import { useState } from "react";
import { LearningPathSessionDataService } from "../../../data/services/learningPathsSession.data.service";
import { useCaseLearningPathSessions } from "../../../domain/useCases/learningPathSessions";
import { useCustomEffect, useNavigation } from "../../hooks";
import { error } from "console";
import { AxiosError } from "axios";
import { LearniningPathSessionEntity } from "../../../data/models/learningPathSessionEntity.model";
import toast from "react-hot-toast";

export const sessionPageViewModel = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showAttendace, setShowAttendance] = useState<boolean>(false);

    const { getQueryParam, navigateTo } = useNavigation();
    const learningPathId = getQueryParam("uuid");
    const {
        getListLearningPathsSessions,
        postResgisterLearningPathsSessions,
        postUnRegisterLearningPathsSessions,
    } = useCaseLearningPathSessions(LearningPathSessionDataService());
    const [learningPathSessions, setLearningPathSessions] = useState<
        LearniningPathSessionEntity[]
    >([]);
    const [selectedSession, setSelectedSession] =
        useState<LearniningPathSessionEntity>({} as LearniningPathSessionEntity);
    const [registeredSession, setRegisterSession] =
        useState<LearniningPathSessionEntity>({} as LearniningPathSessionEntity);

    const successGetLearningPathsSesions = (
        data: LearniningPathSessionEntity[]
    ) => {
        setLearningPathSessions(data);
        // if (data.filter((item) => item.isRegistered === true)[0]) {
        // setRegisterSession(data);
        // }
    };
    const errorGetLearningPathsSesions = (e: AxiosError) => {
        //   toast.error("Detalles del capítulo no encontrados")
    };

    // register for session
    const successRegisterLearningPathsSesions = (data: Response) => {
        location.reload()
        toast.success("Registrado exitosamente para la sesión")

    };

    const errorRegisterLearningPathsSesions = (error: any) => {
        toast.error(error.response?.data?.message ? error.response?.data?.message : "")

    };

    const handleRegisterForLearningPathSession = () => {
        postResgisterLearningPathsSessions({
            success: successRegisterLearningPathsSesions,
            error: errorRegisterLearningPathsSesions,
            learningPathUuid: selectedSession.learning_path_uuid
                ? selectedSession.learning_path_uuid
                : "",
        });
    };

    // Unregister session
    const successUnRegisterLearningPathsSesions = (data: Response) => {
        location.reload()
        closeModalHandler()
        toast.success("Se ha cancelado el registro de la sesión correctamente")
    };

    const errorUnRegisterLearningPathsSesions = (e: AxiosError) => { };

    const handleUnRegisterForLearningPathSession = () => {
        postUnRegisterLearningPathsSessions({
            success: successUnRegisterLearningPathsSesions,
            error: errorUnRegisterLearningPathsSesions,
            learningPathUuid: selectedSession.learning_path_uuid
                ? selectedSession.learning_path_uuid
                : "",
        });
    };

    // session rgistration toggle
    const handleOpenModal = (id: number) => {
        setShowModal(true);
        setSelectedSession(
            learningPathSessions.filter((singleSession) => singleSession.id === id)[0]
        );
    };

    const handleDownloadCertificate = () => { }

    const closeModalHandler = () => {
        setShowModal(false);
        setShowAttendance(false)
    };

    useCustomEffect(() => {
        getListLearningPathsSessions({
            success: successGetLearningPathsSesions,
            error: errorGetLearningPathsSesions,
            learningPathUuid: learningPathId,
        });
    }, [showModal]);

    return {
        selectedSession,
        registeredSession,
        learningPathSessions,
        handleRegisterForLearningPathSession,
        handleUnRegisterForLearningPathSession,
        showModal,
        handleOpenModal,
        closeModalHandler,
        learningPathId,

        showAttendace,
        handleDownloadCertificate,
    };
};
