import { useEffect, useState } from "react"
import { useCustomEffect } from "./useHookEffect"

export const useWindowSize = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsDesktop(width > 1023.98);
    };

    // Initial check
    handleResize();

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isDesktop };
}