import {
  BookOpen,
  Users,
  SquareCheckBig,
  Clock4,
  CircleCheckBig,
  CircleX,
  TriangleAlert,
  Download,
  Filter,
  BookCheck,
} from "lucide-react";
import { Breadcrumb } from "../../components";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTeacherStudentQuizViewModel } from "../../viewModels";
import TeacherStudentGradeTable from "./components/StudentQuizTable/TeacherStudentGradeQuizTable";
const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Libro de calificaciones",
    url: "/teacher/gradebook",
  },
  {
    name: "Prueba",
  },
];
export const TeacherStudentQuizPage = () => {
  const { teacherStudentQuizGrade } = useTeacherStudentQuizViewModel();
  const { control } = useForm();
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc" as "asc" | "desc",
  });

  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Cuestionario realizado
          </h2>
          <div className="flex items-center gap-4"></div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          <CountCard
            title="Cuestionario realizado"
            icon={<BookCheck color="#E30613" width={30} height={30} />}
            count={teacherStudentQuizGrade.length}
          />
          <CountCard
            title="Aprobado"
            icon={<CircleCheckBig color="#047857" width={30} height={30} />}
            count={
              teacherStudentQuizGrade.filter((item) => item.grade > 75).length
            }
          />
          <CountCard
            title="Fallida"
            icon={<CircleX color="#E30613" width={30} height={30} />}
            count={
              teacherStudentQuizGrade.filter((item) => item.grade < 75).length
            }
          />
        </div>

        <div className="flex items-center justify-end w-full mt-6">
          <div className="flex items-center justify-end w-[20rem] gap-6 "></div>
        </div>
        {/* Grade table */}
        <div className="my-6">
          <TeacherStudentGradeTable
            studeentQuizGrades={teacherStudentQuizGrade}
            sortConfig={sortConfig}
            onSort={setSortConfig}
          />
        </div>
      </div>
    </DefaultLayout>
  );
};
