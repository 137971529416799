import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { useCatalogViewModel, usePreviewViewModel } from "../../viewModels";
import { Button, Carousel, CategoryCarousel, LearningPathCard, Modal, Navbar } from "../../components";
import { StyledHomeContainer, StyledModal, StyledSection } from "./catalog.styles";
import { Breadcrumb } from "../../components";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import {
  InformationSection,
  RelatedAreas,
  SeasonsSection,
  UsersCommentsSection,
  VideoPreview,
} from "../preview/components";
import { StyledBlurWall, StyledButton } from "../preview/preview.styles";
import { StyledProfileSubscriptionSection, StyledSubscriptionImage } from "../profile/profile.styles";
import { GoSubscribe } from "../home/components";
import { GameSection, Podcast } from "./components";
import { CourseSection } from "../studentAcessmentDashboard/components";
import { StyledLearningPathsSection } from "../learningPathsLanding/learningPathsLanding.styles";

export const Catalog: FC = () => {
  const {
    breadcrumbItemsElements,
    productions,
    podcastProductions,
    gameProductions,
    extraProductions,
    listLearningPathsToRent,
    onGetGameById,
  } = useCatalogViewModel();
  const {
    showModalPreview,
    handleModalClose,
    isProductionRented,
    token,
    userSubscription,
    videoOptions,
    similarProductions,
    currentSeasonNumber,
    currentProduction,
    currentChapter,
    nextChapter,
    onGetChapter,
    navigateTo,
    handleSaveProduction,
    isProductionSaved,
    handlePlayerReady,
    videoTimeLeft,
    handleCallToActionRental,
    isPodcast,
    showModalRating,
    onSubmitRating,
    onCancelRating,
    onClickRatingButton,
    userCanComment,
    comments,
    handleChangeCommentsPage,
  } = usePreviewViewModel();

  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledHomeContainer>
        {!token && (
          <StyledProfileSubscriptionSection>
            <div></div>
            <div className="relative  w-full max-w-theme flex justify-center mx-auto">
              <StyledSubscriptionImage src={subscriptionsUfo} />
              <Button
                className="mx-auto top-14 lg:top-0 z-10 absolute lg:relative"
                onClick={() => navigateTo("/signup")}
              >
                ¡Regístrate ahora!
              </Button>
            </div>
          </StyledProfileSubscriptionSection>
        )}

        <h1 className="block py-4  text-center text-xl leading-9 font-primaryBold text-white sm:(text-[2rem] leading-[2.375rem]) lg:hidden">
          CATÁLOGO
        </h1>

        <StyledSection>
          <article className="flex flex-col items-center gap-y-3">
            <h2>
              <span>NUESTRAS PRODUCCIONES</span>
            </h2>
            <p className="hidden lg:block">
              Todas nuestras producciones son originales y han sido realizadas por el equipo de "storytraining"
              utilizando su gran experiencia práctica y pedagógica en cada una de nuestras Áreas Temáticas. Con todas
              ellas aprenderás mientras te diviertes. ¡No te lo pierdas!
            </p>
          </article>
        </StyledSection>
        {token && <StyledSection className="StyledSection" withoutYPadding />}
        {productions.map((production, index) => {
          const { title, productions: productionList, description } = production;
          return productionList.length ? (
            <StyledSection className="StyledSection" key={`S-${index}`} withoutPadding>
              <CategoryCarousel
                isCatalog
                key={`P-${index}`}
                categoryName={title}
                categoryDescription={description}
                productions={productionList}
                hideCategory={true}
                slidesToShow={4}
                slidesToShowMobile={1}
                currentCardPaddingMobile="72px"
                fullWidth
              />
            </StyledSection>
          ) : null;
        })}
        {podcastProductions?.productions.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <Podcast podcastProductions={podcastProductions} />
          </StyledSection>
        )}
        {gameProductions?.games.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <GameSection gameProductions={gameProductions} onClickCard={onGetGameById} />
          </StyledSection>
        )}
        {extraProductions.length > 0 ? (
          <StyledSection className="StyledSection" withoutPadding>
            <CategoryCarousel
              isCatalog
              categoryName="Producciones Adicionales"
              categoryDescription="Explora producciones adicionales y descubre más de nuestro trabajo aquí."
              productions={extraProductions}
              hideCategory={true}
              slidesToShow={4}
              slidesToShowMobile={1}
              currentCardPaddingMobile="72px"
              fullWidth
            />
          </StyledSection>
        ) : null}
        <StyledLearningPathsSection className="StyledSection">
          <h2 className="font-primaryBold text-xl my-5 text-white text-center lg:text-4xl">
            CAMINOS DE APRENDIZAJE DISPONIBLES
          </h2>
          <div className="mx-auto w-full lg:px-44 px-0 max-w-dashboard">
            <Carousel
              slidesToShow={listLearningPathsToRent.length == 1 ? 1 : 2}
              slidesToScroll={1}
              infinite={false}
              dots={false}
              responsive={[
                {
                  breakpoint: 1120,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "65px",
                  },
                },
              ]}
            >
              {listLearningPathsToRent.map((learningPath: any) => {
                return (
                  <LearningPathCard
                    key={learningPath.uuid}
                    className={"lg:px-4"}
                    learningPathData={{
                      uuid: learningPath.uuid,
                      title: learningPath.title,
                      imgPreview: learningPath.image,
                      // description: "",
                    }}
                  />
                );
              })}
            </Carousel>
          </div>
        </StyledLearningPathsSection>
        <div className="max-w-theme mx-auto mb-6">
          <div className="w-full lg:px-28 px-0 ">
            <CourseSection />
          </div>
        </div>
        {!token ? (
          <div>
            <h2 className="text-center text-2xl leading-9 font-primaryBold text-white">
              REGÍSTRATE Y FORMA PARTE DE NUESTRA HISTORIA
            </h2>
            <p className="font-primarySemibold text-sm text-center text-white px-4 pt-4 lg:hidden">
              El mejor contenido para el desarrollo de tus soft skills a través de Series Tv, Películas,
              Documentales...¡Cuándo quieras y dónde quieras!
            </p>
            <GoSubscribe />
          </div>
        ) : (
          <StyledProfileSubscriptionSection>
            <div></div>
            <div className="relative  w-full max-w-theme flex justify-center mx-auto">
              <StyledSubscriptionImage
                src={subscriptionsUfo}
                alt={"Imagen tipo de suscripción"}
                title={"Imagen tipo de suscripción"}
              />
              <Button
                className="mx-auto top-14 lg:top-0 z-10 absolute lg:relative"
                onClick={() => navigateTo("/subscriptions")}
              >
                Administrar mi suscripción
              </Button>
            </div>
          </StyledProfileSubscriptionSection>
        )}
      </StyledHomeContainer>

      {showModalPreview && (
        <StyledModal
          showModal={showModalPreview}
          backgroundColor="black"
          title={currentProduction.title}
          onClose={handleModalClose}
        >
          <div className="w-preview py-6">
            <VideoPreview
              videoOptions={videoOptions}
              imgPreview={currentProduction.imgPreview}
              format={currentProduction.format}
              terms={currentProduction.terms}
              duration={currentProduction.duration}
              handlePlayerReady={handlePlayerReady}
              timeLeft={videoTimeLeft}
              chapterName={currentChapter?.name ? currentChapter?.name : currentProduction.title}
              productionName={currentProduction.title}
              currentSeasonNumber={currentSeasonNumber}
              nextChapterName={nextChapter?.name ? nextChapter?.name : ""}
              nextChapterImage={nextChapter?.imgPreview ? nextChapter?.imgPreview : ""}
              similarProductions={similarProductions}
              isPodcast={isPodcast}
            />
            <InformationSection
              token={token}
              title={currentProduction.title}
              description={currentProduction.description}
              onClick={handleSaveProduction}
              isProductionSaved={isProductionSaved}
              isProductionRented={isProductionRented}
              isSubscriptionActive={userSubscription?.stripeStatus === "active"}
              price={currentProduction.price}
              navigateToRental={handleCallToActionRental}
              onSubmitRating={onSubmitRating}
              onCancelRating={onCancelRating}
              onClickRatingButton={onClickRatingButton}
              showModalRating={showModalRating}
              userCanComment={userCanComment}
            />
            <div className="StyledSubscribedContent relative pb-36 bg-secondary-300">
              {userSubscription?.stripeStatus !== "active" && !isProductionRented && <StyledBlurWall />}
              {!!(currentProduction?.seasons?.length || currentProduction?.chapters?.length) && (
                <SeasonsSection
                  onChapterClick={onGetChapter}
                  seasons={currentProduction.seasons}
                  chapters={currentProduction.chapters}
                  currentChapter={currentChapter}
                />
              )}
              <RelatedAreas terms={currentProduction.terms} />
              {/* <RelatedContent
        categoryName="MÁS TÍTULOS SOBRE ESTA SOFT SKILL:"
        productions={dataSliders}
      /> */}

              {!!comments?.items?.length && (
                <UsersCommentsSection comments={comments} handleChangeCommentsPage={handleChangeCommentsPage} />
              )}
              {!token && <StyledButton onClick={() => navigateTo("/signup")}>¡Regístrate ahora!</StyledButton>}
            </div>
          </div>
        </StyledModal>
      )}
    </DefaultLayout>
  );
};
