import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import axios, { AxiosRequestConfig } from "axios";
import { Routing } from "./ui/routes";
import { LanguageProvider } from "./ui/context/LanguageContext";
import { ModalProvider } from "./ui/context/ModalContext";
import Store from "./data/dto/store";
import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

const handleRequestSuccess = (request: AxiosRequestConfig): AxiosRequestConfig => {
  const { headers } = request;
  const globalState = Store?.store?.getState();
  if (headers) {
    headers["Content-Type"] = "application/vnd.api+json";
    headers.accept = "application/vnd.api+json";

    if (globalState?.session?.token) {
      headers["Authorization"] = `Bearer ${globalState?.session?.token}`;
    }
  }

  return request;
};
const handleRequestError = (error: any) => {
  console.log(`REQUEST ERROR! => ${error}`);
  return error;
};

axios.interceptors.request.use(handleRequestSuccess, handleRequestError);

function App() {
  return (
    <Provider store={Store.store}>
      <ModalProvider>
        <LanguageProvider>
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
          <Toaster />
        </LanguageProvider>
      </ModalProvider>
    </Provider>
  );
}

export default App;
