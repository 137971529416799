import {
  CheckCircle,
  XCircle,
  AlertTriangle,
  SortAsc,
  SortDesc,
} from "lucide-react";
import { TeacherStudentGradeTableItem } from "../StudentQuizTableItem";

interface gradesInterface {
  id: number;
  title: string;
  description: string;
  course_uuid: string;
  quiz_type: string;
  difficulty_level: string;
  passing_score: number;
  duration: number;
  is_published: true;
  created_at: string;
  updated_at: string;
  taken_at: string;
  grade: number;
  questions_count: number;
  correct_answers: number;
  feedback: null;
  grade_id: number;
}

interface TeacherStudentGradeTableProps {
  studeentQuizGrades: gradesInterface[];
  sortConfig: {
    key: string;
    direction: "asc" | "desc";
  };
  onSort: (config: any) => void;
}

export default function TeacherStudentGradeTable({
  studeentQuizGrades,
  sortConfig,
  onSort,
}: TeacherStudentGradeTableProps) {
  const handleSort = (key: string) => {
    onSort({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <SortAsc className="h-4 w-4" />
    ) : (
      <SortDesc className="h-4 w-4" />
    );
  };

  return (
    <div className="bg-secondary-100 rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-x-auto">
        {studeentQuizGrades.length > 0 ? (
          <div className=" h-96 overflow-y-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="top-0 sticky">
                <tr className="bg-primary-50">
                  <th
                    className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center space-x-1">
                      <span>Nombre del curso</span>
                      {renderSortIcon("name")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Tipo de cuestionario{" "}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Nivel de dificultad{" "}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Calificación{" "}
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Comentario
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {studeentQuizGrades.map((student) => (
                  <TeacherStudentGradeTableItem {...student} key={student.id} />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex items-center justify-center h-60 font-primaryBold text-white text-xl">
            <p>Los estudiantes no han realizado ninguna prueba</p>
          </div>
        )}
      </div>
    </div>
  );
}
