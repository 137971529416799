import tw from "twin.macro";
import styled from "styled-components";

export interface MenuListProps {
  isOpen: boolean;
}

export const StyledDefaultHeader = styled.header.attrs((props) => ({
  className: props.className,
}))`
  ${tw`w-full flex p-5 justify-between lg:(p-10) z-10`}

  .StoryTrainingLogo {
    ${tw`w-[100px] h-[70px] sm:(w-[193px] h-[109px])`}
  }
  .SearchBarOption {
    ${tw` hidden lg:(inline-flex)`}
  }
  .SearchBarOptionMobile {
    ${tw`inline-block lg:(hidden)`}
  }

  .mobile-searchbar {
    display: none;
    background-color: rgba(29, 29, 29, var(--tw-bg-opacity));
    padding: 5px;
    border-radius: 10px;
    border: 1px solid gray;

    @media (max-width: 1023px) {
      display: block;
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const StyledSearchBar = styled.form.attrs({
  className: "StyledSearchBar",
})`
  ${tw`flex  min-w-[288px] xl:(w-96)`}

  input {
    ${tw`rounded-r-none pr-12`}
  }

  button {
    ${tw`rounded-l-none`}
  }
`;

export const HamburgerBar = styled.button.attrs((props) => ({
  className: props.className,
}))`
  display: none;
  position: fixed;
  top: 50px;
  right: 30px;
  @media (max-width: 1023px) {
    display: flex;
  }

  @media (max-width: 500px) {
    top: 43px;
  }
`;

export const MenuList = styled.nav.attrs((props) => ({
  className: props.className,
}))<MenuListProps>`
  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 320px;
    background-color: rgba(29, 29, 29, var(--tw-bg-opacity));
    transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(320px)")};
    transition: all 0.3s ease-out;

    .menu {
      flex-direction: column;
      align-items: flex-start;

      li {
        ${tw`cursor-pointer hover:(bg-primary-100 rounded-lg text-white) w-full`}
      }

      li {
        button {
          background-color: transparent;
        }
      }

      .mobile-menu {
        width: 100%;

        li {
          ${tw`cursor-pointer p-4 text-white hover:(bg-primary-100 rounded-lg text-white) w-full`}
        }
      }
    }
  }
`;
