import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useCaseCourseDetail, useCaseCourses } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { CoursesDataService } from "../../../data/services/course.data.service";
import { CoursesEntity } from "../../../domain/entities";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gradeSchema } from "../../validators/gradeSchema";
import toast from "react-hot-toast";


interface gradesInterface {
  id: number,
  name: string;
  email: string,
  completed: false,
  productionScore: number,
  assessmentScore: number,
  finalGrade: any,
  feedback: string,
  courseUserId: number
}

interface teacherStudentQuizGradeInterface {
  id: number,
  title: string,
  description: string,
  course_uuid: string,
  quiz_type: string,
  difficulty_level: string,
  passing_score: number,
  duration: number,
  is_published: true,
  created_at: string,
  updated_at: string,
  taken_at: string,
  grade: number,
  grade_id: number;
  questions_count: number,
  correct_answers: number,
  feedback: null
}

import axios from "axios";

export function useTeacherStudentQuizViewModel() {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(gradeSchema) });

  const { getCurrentPathName, navigateTo, getQueryParam } = useNavigation();
  const [currentCourse, setCurrentCourse] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [teacherStudentQuizGrade, setTeacherStudentQuizGrade] = useState<Array<teacherStudentQuizGradeInterface>>([])

  const { getStudentQuizGrades } =
    useCaseCourseDetail(CourseDetailsDataService());
  const student = getQueryParam("student");
  const course = getQueryParam("course");

  const getStudentQuizGradeSuccess = async (quizGrades: Array<teacherStudentQuizGradeInterface>) => {
    if (quizGrades.length > 0) {
      setTeacherStudentQuizGrade(quizGrades)
    }
  }

  const getStudentQuizGradeError = async (Error: AxiosError) => {
  }
  const getStudentFeedBack = () => {
    getStudentQuizGrades({
      success: getStudentQuizGradeSuccess,
      error: getStudentQuizGradeError,
      courseId: course, studentId: student,
    })
  }

  useCustomEffect(() => {
    getStudentFeedBack()
  }, []);

  const handleCloseModal = () => { setShowModal(false) }

  return {
    getCurrentPathName,
    navigateTo,
    currentCourse,
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    errors,
    showModal,
    handleCloseModal,
    teacherStudentQuizGrade
  };
}
