import {
    Assessment, CoursesDetail, CoursesDetailService, StartCourse, Quiz, Question,
    Answer, ChapterDetails, DeleteQuestion, CourseGrade, StudentQuizGrade, PutSendFeeBack, GetStudentCourse,
    GetStudentCourseQuiz,
    GetResntedUsers,
    ResgisterStudentToCourse,
    DeleteQUiz,
    ResetQUiz,
    QuestionAnswer
} from "../../services/coursesDetail.service";

export function useCaseCourseDetail(service: CoursesDetailService) {
    return {
        async getCourseDetails({ success, error, courseUuid, perPage, currentPage }: CoursesDetail) {
            return service.getCourseDetails({ success, error, courseUuid, perPage, currentPage });
        },

        async postPostRentCourse({ success, error, courseUuid }: StartCourse) {
            return service.PostRentCourse({ success, error, courseUuid });
        },
        async submitAssessment({ success, error, courseUuid, assementId, body }: Assessment) {
            return service.submitAssessment({ success, error, courseUuid, assementId, body });
        },
        async submitQuiz({ success, error, quizId, body }: Quiz) {
            return service.submitQuiz({ success, error, body, quizId });
        },
        async submitQuestion({ success, error, questioBody, questionId }: Question) {
            return service.submitQuestion({ success, error, questioBody, questionId });
        },
        async deleteQuestion({ success, error, questionId }: DeleteQuestion) {
            return service.deleteQuestion({ success, error, questionId });
        },
        async submitAnswers({ success, error, answerBody, answers, questionId, answerId }: Answer) {
            return service.submitAnswer({ success, error, answerBody, answers, questionId, answerId });
        },
        async getCharpterDetails({ success, error, courseId, chapterId }: ChapterDetails) {
            return service.getCharpterDetails({ success, error, courseId, chapterId });
        },
        async getCourseGrades({ success, error, courseId, currentPage }: CourseGrade) {
            return service.getCourseGrades({ success, error, courseId, currentPage });
        },
        async getStudentQuizGrades({ success, error, courseId, studentId }: StudentQuizGrade) {
            return service.getStudentQuizGrades({ success, error, courseId, studentId });
        },
        async putSendFeeBack({ success, error, gradeId, body }: PutSendFeeBack) {
            return service.putSendFeeBack({ success, error, gradeId, body });
        },
        async getStudentCourse({ success, error, currentPage }: GetStudentCourse) {
            return service.getStudentCourse({ success, error, currentPage });
        },
        async getStudentCourseQuiz({ success, error, courseId }: GetStudentCourseQuiz) {
            return service.getStudentCourseQuiz({ success, error, courseId });
        },
        async getRentedUsers({ success, error, courseId, currentPage }: GetResntedUsers) {
            return service.getRentedUsers({ success, error, courseId, currentPage });
        },
        async postRegisterStudentToCourse({ success, error, courseId, studentIds }: ResgisterStudentToCourse) {
            return service.postRegisterStudentToCourse({ success, error, courseId, studentIds });
        },
        async deleteQuiz({ success, error, quizId }: DeleteQUiz) {
            return service.deleteQuiz({ success, error, quizId });
        },
        async deleteAnswer({ success, error, answerId }: QuestionAnswer) {
            return service.deleteAnswer({ success, error, answerId });
        },
        async resetQuiz({ success, error, quizId }: ResetQUiz) {
            return service.resetQuiz({ success, error, quizId });
        },
    }
}