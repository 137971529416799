import { AxiosError } from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { getSessionToken, getSubscriptionStatus } from "../../../data/dto/selectors";
import { productionsDataService } from "../../../data/services";
import { CategoryEntity, LearningPathEntity, ProductionEntity } from "../../../domain/entities";
import { useCaseLearningPath, useCaseProductions } from "../../../domain/useCases";
import { useCustomEffect } from "../../hooks";
import { learningPathDataService } from "../../../data/services/learningPath.data.service";
import { useModal } from "../../context/ModalContext";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Catálogo",
  },
];

export function useCatalogViewModel() {
  const token = useSelector(getSessionToken);
  const { showLoading, hideLoading } = useModal();
  const userSubscription = useSelector(getSubscriptionStatus);
  const [productions, setProductions] = useState<Array<CategoryEntity> | []>([]);
  const [podcastProductions, setPodcastProductions] = useState<CategoryEntity>();
  const [gameProductions, setGameProductions] = useState<CategoryEntity>();
  const [extraProductions, setExtraProductions] = useState<Array<ProductionEntity>>([]);
  const [listLearningPathsToRent, setlistLearningPathsToRent] = useState<Array<LearningPathEntity>>([]);
  const { getAll, getGameById, getExtra } = useCaseProductions(productionsDataService());
  const { getlistLearningPathsToRentToRent } = useCaseLearningPath(learningPathDataService());

  function successProduction(productions: Array<CategoryEntity>) {
    hideLoading();
    const videoProductionsList = productions.filter(
      (currentProduction) => currentProduction.slug !== "podcast" && currentProduction.slug !== "juegos"
    );
    const podcastProductionsList = productions.find((currentProduction) => currentProduction.slug === "podcast");
    const gameProductionsList = productions.find((currentProduction) => currentProduction.slug === "juegos");
    setProductions(videoProductionsList);
    setPodcastProductions(podcastProductionsList);
    setGameProductions(gameProductionsList);
  }

  function successGetListOfLearningPathsToRent(learningPaths: Array<LearningPathEntity>) {
    setlistLearningPathsToRent(learningPaths);
  }

  const successExtraProduction = (data: Array<ProductionEntity>) => {
    setExtraProductions(data);
  };

  function errorProduction(e: AxiosError) {
    console.log("errorProductionFiltered", e);
    hideLoading();
  }

  function errorGetListOfLearningPathsToRent(e: AxiosError) {
    console.log("errorGetListOfLearningPathsToRent", e);
    hideLoading();
  }

  /*Game card */
  const handleSuccessGameById = (data: any) => {
    if (data.url) {
      window.open(`${data.url}`, "_blank");
    }
  };

  const handleErrorGameById = (error: any) => {
    if (error.response.data.message) {
      if (!token) {
        toast.error("Debes iniciar sesión para acceder al contenido");
      } else {
        userSubscription?.stripeStatus !== "active"
          ? toast.error("Debes subscribirte para acceder al contenido")
          : toast.error(error.response.data.message);
      }
    }
  };

  const onGetGameById = (gameId: string) => {
    getGameById({
      id: gameId,
      success: handleSuccessGameById,
      error: handleErrorGameById,
    });
  };
  useCustomEffect(() => {
    showLoading();
    getAll({
      success: successProduction,
      error: errorProduction,
    });
    getExtra({
      success: successExtraProduction,
      error: errorProduction,
    });
    getlistLearningPathsToRentToRent({
      success: successGetListOfLearningPathsToRent,
      error: errorGetListOfLearningPathsToRent,
    });
  }, []);

  return {
    breadcrumbItemsElements,
    productions,
    podcastProductions,
    gameProductions,
    extraProductions,
    listLearningPathsToRent,
    onGetGameById,
  };
}
