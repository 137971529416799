import { FC } from "react";
import { StyledNotification } from "./Notification.styles";
import { ReactComponent as StoryTrainingLogo } from "../../assets/images/storyTrainingTextlessLogo.svg";
import { ReactComponent as TrashCanLogo } from "../../assets/images/trashCanIcon.svg";
import { NotificationEntity } from "../../../domain/entities";

interface NotificationProps {
  notification: NotificationEntity;
  handleDeleteNotification: (notification: NotificationEntity) => void;
}

export const Notification: FC<NotificationProps> = ({ notification, handleDeleteNotification }) => {
  return (
    <StyledNotification>
      <StoryTrainingLogo className="storyTrainingLogo" />
      <p>
        {notification.message}
        <span>{notification.date}</span>
      </p>
      <div className="bg-primary-100 grid place-content-center w-10 lg:bg-transparent lg:h-7">
        <TrashCanLogo
          className="trashCanLogo"
          onClick={() => {
            handleDeleteNotification(notification);
          }}
        />
      </div>
    </StyledNotification>
  );
};
