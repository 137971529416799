import { FC, useEffect, useState } from "react";
import { DefaultLayout } from "../../layouts";
import { Button, PageLoader } from "../../components";
import { useNavigation } from "../../hooks";
import { EmailIcon } from "../../assets/Icons";
import { authenticationDataService } from "../../../data/services";

type statusType = "pending" | "error" | "success";

export const EmailVerification: FC = () => {
  const { navigateTo, getPathParam } = useNavigation();
  const { getVerificationStatus } = authenticationDataService();
  const id = getPathParam("id");
  const hash = getPathParam("hash");
  const [status, setStatus] = useState<statusType>("pending");

  useEffect(() => {
    const verificationData = {
      id: id as string,
      hash: hash as string,
      success: () => {
        setStatus("success");
      },
      error: () => setStatus("error"),
    };
    getVerificationStatus(verificationData);
  }, []);

  return (
    <DefaultLayout>
      {status === "pending" ? (
        <PageLoader />
      ) : (
        <section className="flex flex-col items-center w-100 mx-auto my-14 text-white text-center">
          <h3
            className={`font-primaryBold text-2xl lg:text-3.5 text-center mb-10 px-8 leading-normal ${
              status === "success" ? "text-white" : "text-primary-50"
            }`}
          >
            {status === "success" ? (
              "Tu correo electrónico ha sido verificado exitosamente."
            ) : (
              <div>
                <p>¡Ocurrió un error!</p> <p>Por favor, contacta con soporte o intenta registrarte nuevamente.</p>
              </div>
            )}
          </h3>
          <EmailIcon width="80px" height="80px" color="white" className="mb-2" />
          <Button onClick={() => navigateTo("/login")}>Iniciar sesión</Button>
        </section>
      )}
    </DefaultLayout>
  );
};
