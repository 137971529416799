import { Carousel } from "../../../../components";
import { useCoursesViewModel } from "../../../../viewModels";
import { CourseCard } from "../courseCard";

export const CourseSection = () => {
  const { coursesToRent } = useCoursesViewModel();
  return (
    <div className="w-full">
      {coursesToRent.length > 0 && (
        <h2 className="font-primaryBold text-xl my-8 text-white text-center lg:text-3xl">CURSOS DISPONIBLES</h2>
      )}
      <Carousel
        slidesToShow={coursesToRent.length == 1 ? 2 : 3}
        slidesToScroll={1}
        infinite={false}
        dots={false}
        centerPadding="65px"
        responsive={[
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true,
              centerMode: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: false,
              centerMode: true,
            },
          },
        ]}
      >
        {coursesToRent.map((course, index: number) => (
          <CourseCard key={index} {...course} />
        ))}
      </Carousel>
    </div>
  );
};
