import { Http, requestHandler, responseHandler } from "../repositories/http";
import axios, { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { getSessionToken } from "../../dto/selectors";

export function httpService(): Http {
  const token = useSelector(getSessionToken);
  return {
    setBaseConfig(baseUrl: string, version: string): void {
      axios.defaults.baseURL = `${baseUrl}${version}`;
    },
    post<T>(url: string, data: T): Promise<AxiosResponse> {
      return axios.post(url, data);
    },
    patch<T>(url: string, data: T): Promise<AxiosResponse> {
      return axios.patch(url, data);
    },
    put<T>(url: string, data: T): Promise<AxiosResponse> {
      return axios.put(url, data);
    },
    async get<T>(url: string, params?: T): Promise<AxiosResponse> {
      const response = await axios.get(url, { params });
      return response;
    },
    deleteRequest<T>(url: string, params?: T): Promise<AxiosResponse> {
      return axios.delete(url, { params });
    },
  };
}
