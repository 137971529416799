import {
  BookOpen,
  Users,
  SquareCheckBig,
  Clock4,
  CircleCheckBig,
  CircleX,
  TriangleAlert,
  Download,
  Filter,
  BookCheck,
} from "lucide-react";
import {
  Breadcrumb,
  Button,
  Paginator,
  SelectControlled,
} from "../../components";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { useNavigation } from "../../hooks";
import GradebookTable from "./components/StudentCoursesTable/studentCourseTable";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useStudentViewModel } from "../../viewModels";
import StudentCoursesTable from "./components/StudentCoursesTable/studentCourseTable";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Cursos",
    url: "/courses",
  },
];

export const StudentCoursesDashboard = () => {
  const { courses, handlePageNewPage } = useStudentViewModel();
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc" as "asc" | "desc",
  });
  const handleUpdateGrade = (
    studentId: string,
    assessmentId: string,
    score: number
  ) => {};

  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">Cursos</h2>
          <div className="flex items-center gap-4"></div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          <CountCard
            title="Cursos Activos"
            icon={<BookCheck color="#E30613" width={30} height={30} />}
            count={courses ? courses?.courses.length : 0}
          />
          <CountCard
            title="Aprobado"
            icon={<CircleCheckBig color="#047857" width={30} height={30} />}
            count={
              courses
                ? courses?.courses.filter((item) => item.assessment_score > 75)
                    .length
                : 0
            }
          />
          <CountCard
            title="Fallida"
            icon={<CircleX color="#E30613" width={30} height={30} />}
            count={
              courses
                ? courses?.courses.filter((item) => item.assessment_score < 75)
                    .length
                : 0
            }
          />
        </div>

        <div className="flex items-center justify-end w-full mt-6">
          <div className="flex items-center justify-end w-[20rem] gap-6 "></div>
        </div>
        {/* Grade table */}
        <div className="my-6">
          <StudentCoursesTable
            courses={courses ? courses.courses : []}
            onUpdateGrade={handleUpdateGrade}
            sortConfig={sortConfig}
            onSort={setSortConfig}
          />
        </div>
        <div className="flex items-center justify-center py-4">
          <Paginator
            className="mx-auto mt-8"
            page={courses ? courses.current_page : 0}
            totalPages={courses ? courses.total_pages : 0}
            onPageChange={(event, page) => {
              handlePageNewPage(page);
            }}
          />
        </div>
      </div>
    </DefaultLayout>
  );
};
