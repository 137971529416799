import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useCaseCourseDetail, useCaseCourses } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { CoursesDataService } from "../../../data/services/course.data.service";
import { CoursesEntity } from "../../../domain/entities";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gradeSchema } from "../../validators/gradeSchema";
import toast from "react-hot-toast";
import { useProfileDropDown } from "../../components/ProfileDropDown/useProfileDropDown";


interface gradesInterface {
  id: number,
  name: string;
  email: string,
  completed: false,
  productionScore: number,
  assessmentScore: number,
  finalGrade: any,
  feedback: string,
  courseUserId: number
}

interface teacherStudentQuizGradeInterface {
  id: number,
  title: string,
  description: string,
  course_uuid: string,
  quiz_type: string,
  difficulty_level: string,
  passing_score: number,
  duration: number,
  is_published: true,
  created_at: string,
  updated_at: string,
  taken_at: string,
  grade: number,
  questions_count: number,
  correct_answers: number,
  feedback: null
}

interface gradeInterface {
  total_items: number
  total_pages: number
  current_page: number;
  users: any[]
}



export function useTeacherViewModel() {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(gradeSchema) });
  const { user } = useProfileDropDown();

  const { getCurrentPathName, navigateTo, getQueryParam } = useNavigation();
  const [currentCourse, setCurrentCourse] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [teacherStudentQuizGrade, setTeacherStudentQuizGrade] = useState<Array<teacherStudentQuizGradeInterface>>([])
  const [grades, setGrades] = useState<gradeInterface>();
  const [courses, setCourses] = useState<Array<CoursesEntity>
  >([]);

  const { getListOrRentedCourse } = useCaseCourses(
    CoursesDataService()
  );
  const { getCourseGrades } =
    useCaseCourseDetail(CourseDetailsDataService());
  function successGetCoursesToGrade(
    response: any
  ) {
    const studentsGrade = response.users.map((user: any) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        completed: user.completed,
        productionScore: user.production_score,
        assessmentScore: user.assessment_score,
        finalGrade: user.final_grade,
        feedback: user.feedback,
        courseUserId: user.course_user_id
      }
    });

    setGrades({
      users: studentsGrade, total_items: response.total_items,
      total_pages: response.total_pages,
      current_page: response.current_page
    })
  }

  function errorGetCoursesGrade(e: AxiosError) {
  }

  const handleStartCourse = (uuid: string) => {
  };

  const handleGetCourseGrade = async (courseId: string, page: number) => {
    setCurrentPage(page)
    setCurrentCourse(courseId)
    getCourseGrades({
      success: successGetCoursesToGrade,
      error: errorGetCoursesGrade,
      courseId: courseId,
      currentPage: page
    });
  }

  const getCoursesSuccess = async (coursesDetails: {
    courses
    : Array<CoursesEntity>
  }) => {
    if (coursesDetails.courses.length > 0) {
      setCourses(coursesDetails.courses)
      handleGetCourseGrade(coursesDetails.courses[0].uuid, currentPage)
    }
  }

  const getCoursesError = async (Error: AxiosError) => {
    toast.error("")
  }

  const onSubmit = (data: FieldValues) => {
  }

  const handlePageNewPage = (page: number) => {
    handleGetCourseGrade(currentCourse, page)
  }

  useCustomEffect(() => {
    if (user?.roles[0] &&
      user?.roles[0].slug !== "professor") {
      navigateTo("/")
    } else {
      getListOrRentedCourse({
        success: getCoursesSuccess,
        error: getCoursesError,
      })
    }

  }, []);


  const handleShowModal = (id: number) => {
    setShowModal(true)
  }

  const handleCloseModal = () => { setShowModal(false) }
  return {
    getCurrentPathName,
    navigateTo,
    handleStartCourse,
    grades,
    courses,
    handleGetCourseGrade,
    currentCourse,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    setError,
    getValues,
    errors,
    showModal,
    handleShowModal,
    handleCloseModal,
    teacherStudentQuizGrade,
    handlePageNewPage,
    currentPage
  };
}


export function useUpdateStudentScoreViewModel() {

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(gradeSchema) });

  const { putSendFeeBack } =
    useCaseCourseDetail(CourseDetailsDataService());
  const [showModal, setShowModal] = useState<boolean>(false);
  const { navigateTo } = useNavigation();
  const [gradeId, setGradeId] = useState<number>(0)


  const getSubmitFeedbackSuccess = async (data: any) => {
    window.location.reload();
  }

  const getSubmitFeedbackError = async (error: any) => {
    toast.error(error.response?.data.message)
  }
  const onSubmit = (data: FieldValues) => {

    putSendFeeBack({ success: getSubmitFeedbackSuccess, error: getSubmitFeedbackError, body: { grade: parseInt(data.score), feedback: data.feedback }, gradeId })
  }

  const handleShowModal = (id: number) => {
    setGradeId(id)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    return !showModal
  }
  return {
    navigateTo,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    setError,
    getValues,
    errors,
    showModal,
    handleShowModal,
    handleCloseModal,
  };
}