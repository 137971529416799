import React from "react";
import { Helmet as OriginalHelmet } from "react-helmet";

// Type assertion: treat the original Helmet as a functional component with proper props
const Helmet = OriginalHelmet as unknown as React.FC<React.ComponentProps<typeof OriginalHelmet>>;

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  author?: string;
  robots?: string;
  jsonLd?: { [key: string]: any } | string;
  type?: string;
  facebookAppId?: string;
  twitterSite?: string;
}

export function SEO(props: SEOProps) {
  const {
    title,
    description,
    image,
    author,
    robots,
    jsonLd,
    type,
    facebookAppId,
    twitterSite,
  } = props;

  return (
    <Helmet>
      <meta
        name="robots"
        content={robots ? robots : process.env.REACT_APP_SEO_ROBOTS}
      />
      <title>{title ? title : process.env.REACT_APP_SEO_TITLE}</title>
      <meta
        name="description"
        content={
          description ? description : process.env.REACT_APP_SEO_DESCRIPTION
        }
      />
      <meta
        name="author"
        content={author ? author : process.env.REACT_APP_SEO_AUTHOR}
      />
      {/* Open Graph Meta Tags */}
      <meta property="og:site_name" content="Storytraining" />
      <meta
        property="og:type"
        content={type ? type : process.env.REACT_APP_SEO_TYPE}
      />
      <meta
        property="og:title"
        content={title ? title : process.env.REACT_APP_SEO_TITLE}
      />
      <meta
        property="og:description"
        content={
          description ? description : process.env.REACT_APP_SEO_DESCRIPTION
        }
      />
      <meta
        property="og:image"
        content={image ? image : process.env.REACT_APP_SEO_IMAGE}
      />
      {facebookAppId && (
        <meta property="fb:app_id" content={facebookAppId} />
      )}
      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={title ? title : process.env.REACT_APP_SEO_TITLE}
      />
      <meta
        name="twitter:description"
        content={
          description ? description : process.env.REACT_APP_SEO_DESCRIPTION
        }
      />
      <meta
        name="twitter:image"
        content={image ? image : process.env.REACT_APP_SEO_IMAGE}
      />
      <meta
        name="twitter:creator"
        content={
          twitterSite
            ? twitterSite
            : process.env.REACT_APP_SEO_TWITTER_CREATOR
        }
      />
      <meta
        name="twitter:site"
        content={
          twitterSite
            ? twitterSite
            : process.env.REACT_APP_SEO_TWITTER_SITE
        }
      />
      {/* Structured data */}
      <script type="application/ld+json">
        {jsonLd
          ? `${jsonLd}`
          : process.env.REACT_APP_SEO_STRUCTURED_DATA}
      </script>
    </Helmet>
  );
}
