import { FC, ChangeEvent } from "react";
import { PaginationRenderItemParams } from "@mui/material";
import {
  StyledPaginatorContainer,
  StyledPagination,
  StyledPaginationItem,
} from "./Paginator.styles";

interface PaginatorProps {
  className?: string;
  page?: number;
  totalPages?: number;
  disableFromPage?: number; // Disable pages from this number onwards
  onPageChange?: (event: ChangeEvent<unknown>, page: number) => void;
}

export const Paginator: FC<PaginatorProps> = ({
  className,
  page = 1,
  totalPages,
  disableFromPage,
  onPageChange,
}) => {
  const handleChange = (event: ChangeEvent<unknown>, newPage: number) => {
    if (!disableFromPage || newPage < disableFromPage) {
      onPageChange?.(event, newPage);
    }
  };

  return (
    <StyledPaginatorContainer className={className}>
      <StyledPagination
        page={page}
        count={totalPages}
        onChange={handleChange}
        color="secondary"
        shape="rounded"
        renderItem={(item: PaginationRenderItemParams) => {
          const pageNumber = item.page ?? 0;
          const isDisabled =
            item.type === "previous"
              ? page === 1 // Disable "Previous" on first page
              : item.type === "next"
              ? page === totalPages // Disable "Next" on last page
              : disableFromPage
              ? pageNumber >= disableFromPage
              : false;

          return <StyledPaginationItem {...item} disabled={isDisabled} />;
        }}
      />
    </StyledPaginatorContainer>
  );
};
