import { FC } from "react";
import { StyledButton, StyledButtonRent, StyledInformationSection } from "./InformationSection.styles";
import { FolderIcon, HeartIcon } from "../../../../assets/Icons";
import { Button, RatingModal } from "../../../../components";
import { useNavigation } from "../../../../hooks";
import { LearningPathDetailEntity, PriceEntity, ProductionEntity } from "../../../../../domain/entities";
import { FieldValues } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { LearningPathDetailModel } from "../../../../../data/models/learningPathDetail.model";

interface InformationSectionProps {
  token?: string;
  title: string;
  roles?: string;
  description: string;
  progress?: number;
  onClick?: (data: any) => void;
  isProductionSaved: boolean;
  isSubscriptionActive: boolean;
  isProductionRented: boolean;
  price?: PriceEntity;
  navigateToRental: () => void;
  userCanComment: boolean;
  showModalRating: boolean;
  onSubmitRating: (data: FieldValues) => void;
  onCancelRating: (data: any) => void;
  onClickRatingButton: (data: any) => void;
  productions?: LearningPathDetailEntity;
  detailLearningPath?: LearningPathDetailModel;
}

enum CurrencySymbol {
  eur = "€",
  usd = "$",
}

export const InformationSection: FC<InformationSectionProps> = ({
  token,
  progress,
  description,
  title,
  roles,
  onClick,
  isProductionSaved,
  isProductionRented,
  isSubscriptionActive,
  price,
  navigateToRental,
  userCanComment,
  showModalRating,
  onSubmitRating,
  onCancelRating,
  onClickRatingButton,
  productions,
  detailLearningPath,
}) => {
  const { navigateTo } = useNavigation();

  const finalPrice = price ? (price?.unitAmount / 100.0).toString().replace(".", ",") : "";
  const location = useLocation().pathname;
  return (
    <StyledInformationSection>
      <div className="flex flex-col gap-4 lg:gap:0 lg:flex-row justify-end items-center py-4 lg:justify-between gap-x-4">
        {title && (
          <h2 className="flex text-left gap-x-4 items-center w-full justify-start text-2xl lg:text-3xl font-primaryBold ">
            {title}
          </h2>
        )}
      </div>
      <p className="text-base font-primaryBook ">{description}</p>
      <div className="mt-6">
        {location === "/learning-path-detail" && !detailLearningPath?.productions && (
          <div className="w-full flex flex-col gap-8 items-center justify-center my-4">
            <p className="font-primaryBold text-lg lg:text-2xl">Esta ruta de aprendizaje no tiene producción.</p>
          </div>
        )}
        {location === "/preview" && !isSubscriptionActive && (
          <Button
            className="w-full lg:w-44"
            onClick={() => {
              navigateTo("/subscriptions");
            }}
          >
            Suscríbete
          </Button>
        )}

        {isSubscriptionActive && userCanComment && (
          <Button color="secondary" onClick={onClickRatingButton} className="w-full lg:w-60">
            Calificar este título
          </Button>
        )}
      </div>
      <div className="w-full flex flex-col items-end gap-y-6">
        {!isSubscriptionActive && !isProductionRented && price && (
          <div className="w-full flex flex-col lg:flex-row lg:justify-end items-center gap-4">
            <p className="text-white text-xs font-primaryBook">{/* También puedes */}</p>
            <div className="flex gap-4 items-center">
              <StyledButtonRent onClick={navigateToRental}>Alquilar esta producción</StyledButtonRent>
              <p>
                <strong className="font-primaryBold">{`${CurrencySymbol[price?.currency]} ${finalPrice}`}</strong>
              </p>
            </div>
          </div>
        )}
        {isProductionRented && (
          <div className="w-full flex lg:justify-end">
            <div>
              <div className="flex items-center gap-8 my-4">
                <p className="text-white text-sm lg:text-lg font-semibold font-primaryBook">
                  Progreso en la ruta de aprendizaje
                </p>
                <p className="text-primary-50 text-2xl font-primaryBook font-[600]">
                  {progress ? `${Math.round(progress * 10) / 10}%` : "0%"}
                </p>
              </div>
              {progress && progress > 69 && (
                <div className="flex justify-end items-center">
                  <Button
                    className="w-full lg:w-64"
                    onClick={() => {
                      navigateTo(`/learning-path-sessions?uuid=${detailLearningPath?.uuid}`);
                    }}
                  >
                    Agendar nueva sesión
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <RatingModal showModal={showModalRating} onSubmit={onSubmitRating} onCancel={onCancelRating} />
    </StyledInformationSection>
  );
};
