import {
  BookOpen,
  Users,
  SquareCheckBig,
  Clock4,
  CircleCheckBig,
  CircleX,
  TriangleAlert,
  Download,
  Filter,
  BookCheck,
} from "lucide-react";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { useNavigation } from "../../hooks";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useStudentCourseQuizViewModel } from "../../viewModels";
import StudentCoursesTable from "./components/StudentCoursesQuizTable/studentCourseQuizTable";
import StudentCoursesQuizTable from "./components/StudentCoursesQuizTable/studentCourseQuizTable";
import { Breadcrumb } from "../../components";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Panel de estudiantes",
    url: "/student-dashboard",
  },
  {
    name: "Cuestionarios",
  },
];

export const StudentCoursesQuizDashboard = () => {
  const { courseQuiz } = useStudentCourseQuizViewModel();
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc" as "asc" | "desc",
  });

  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Libro de Calificaciones
          </h2>
          <div className="flex items-center gap-4"></div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          <CountCard
            title="Cuestionarios"
            icon={<BookCheck color="#E30613" width={30} height={30} />}
            count={courseQuiz.length}
          />
          <CountCard
            title="Aprobado"
            icon={<CircleCheckBig color="#047857" width={30} height={30} />}
            count={courseQuiz.filter((item) => item.grade > 75).length}
          />
          <CountCard
            title="Fallida"
            icon={<CircleX color="#E30613" width={30} height={30} />}
            count={courseQuiz.filter((item) => item.grade < 75).length}
          />
        </div>

        <div className="flex items-center justify-end w-full mt-6">
          <div className="flex items-center justify-end w-[20rem] gap-6 "></div>
        </div>
        {/* Grade table */}
        <div className="my-6">
          <StudentCoursesQuizTable
            coursesQuiz={courseQuiz}
            sortConfig={sortConfig}
            onSort={setSortConfig}
          />
        </div>
      </div>
    </DefaultLayout>
  );
};
