import { FC, useEffect, useState } from "react";
import { Button, TextField } from "../../../components";
import toast from "react-hot-toast";
import { Trash2 } from "lucide-react";

interface SingleQuestionSelectionFormInterface {
  submitFormHandler: (data: any) => void;
  handlePrevious: () => void;
  quizQuestion: any[];
  currentQuestionIndex: number;
  handleDelete: (
    questionId: string,
    answerIndex: number,
    questionType: string
  ) => void;
}

export const SingleSelectionForm: FC<SingleQuestionSelectionFormInterface> = ({
  submitFormHandler,
  handlePrevious,
  quizQuestion,
  currentQuestionIndex,
  handleDelete,
}) => {
  const [options, setOptions] = useState([{ text: "", id: "" }]);
  const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index].text = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { text: "", id: "" }]);
  };

  const removeOption = (index: number) => {
    if (options.length > 1) {
      setOptions(options.filter((_, i) => i !== index));
      if (correctAnswer === index) setCorrectAnswer(null);
    }
  };

  const submitQuestion = () => {
    if (options.some((option) => option.text.trim() === "")) {
      toast.error("Todas las opciones deben completarse antes de enviar.");
      return;
    }

    const optionSet = new Set(options.map((opt) => opt.text.trim()));
    if (optionSet.size !== options.length) {
      toast.error("Las opciones no pueden ser idénticas.");
      return;
    }

    if (correctAnswer === null) {
      toast.error("Por favor seleccione una respuesta correcta.");
      return;
    }

    setLoading(true);
    const newOptions = options.map((item, index) => ({
      content: item.text,
      is_correct: index === correctAnswer,
    }));

    submitFormHandler({ type: "single_selection", answers: newOptions });
    setLoading(false);
  };

  useEffect(() => {
    if (quizQuestion && quizQuestion[currentQuestionIndex].answers) {
      let options: any[] = [];
      let correctAnswer: number | null = null;
      quizQuestion[currentQuestionIndex].answers.forEach(
        (answer: any, index: number) => {
          options.push({ text: answer.content, id: answer.id });
          if (answer.is_correct) {
            correctAnswer = index;
          }
        }
      );
      setOptions(options.length >= 1 ? options : [{ text: "", id: "" }]);
      setCorrectAnswer(correctAnswer);
    }
  }, [currentQuestionIndex]);

  return (
    <div className="bg-black px-6 rounded-lg shadow-md space-y-4">
      <div className="space-y-3">
        <div className="space-y-8">
          {options.map((option, index) => (
            <div key={index} className="flex items-center space-x-2">
              <input
                type="radio"
                name="correctAnswer"
                checked={correctAnswer === index}
                onChange={() => setCorrectAnswer(index)}
                className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300"
              />
              <TextField
                className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base font-normal leading-6 text-black placeholder-gray-500"
                value={option.text}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder="Introducir opción"
              />
              {options.length > 1 && (
                <button
                  type="button"
                  onClick={() => {
                    handleDelete(
                      quizQuestion[currentQuestionIndex].question_id,
                      index,
                      "single_selection"
                    );

                    removeOption(index);
                  }}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Trash2 size={20} />
                </button>
              )}
            </div>
          ))}
        </div>
        {!quizQuestion[currentQuestionIndex]?.question_id && (
          <Button
            type="button"
            onClick={addOption}
            color="secondary"
            className="text-primary-50 my-0"
          >
            Agregar opción
          </Button>
        )}
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          onClick={handlePrevious}
          className="w-full"
          color="third"
          type="button"
          disabled={currentQuestionIndex < 1}
        >
          Previa
        </Button>
        <Button
          onClick={submitQuestion}
          className="w-full"
          type="button"
          disabled={loading}
        >
          {loading
            ? "Enviando..."
            : currentQuestionIndex + 1 < quizQuestion.length
            ? "Próxima"
            : "Entregar"}
        </Button>
      </div>
    </div>
  );
};
