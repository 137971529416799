import { BookOpen, Users, SquareCheckBig, Clock4 } from "lucide-react";
import { Button } from "../../components";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { ActivityCard } from "./components";
import { EvaluationCard } from "./components/EvaluationCard";
import { useNavigation } from "../../hooks";

export const TeacherDashboard = () => {
  const { navigateTo } = useNavigation();

 


  return (
    <DefaultLayout>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Dashboard del Profesor
          </h2>
          <div className="flex items-center gap-4">
            <Button
              onClick={() => {
                navigateTo("/teacher/assessment/create");
              }}
            >
              Nueva Evaluación
            </Button>
            <Button
              onClick={() => {
                navigateTo("/teacher/gradebook");
              }}
              color="secondary"
            >
              Calificaciones
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          {/* {dashBoardData.map((item) => (
            <CountCard {...item} />
          ))} */}
        </div>
      </div>
      <div className="block lg:flex gap-8 mx-4 lg:mx-16 my-10">
        <div className="bg-secondary-100   rounded-md w-full">
          <h2 className="text-lg lg:text-3xl text-white font-bold py-6 border-b border-solid border-gray-400 px-6">
            Evaluaciones Recientes
          </h2>
          <div className="p-6 flex flex-col gap-6">
            {/* {evaluationData.map((evaluation, index: number) => (
              <EvaluationCard {...evaluation} key={index} />
            ))} */}
          </div>
        </div>
        {/* Recent activity session */}
        <div className="bg-secondary-100 rounded-md w-full">
          <h2 className="text-lg lg:text-3xl text-white font-bold py-6 border-b border-solid border-gray-400 px-6">
            Actividad Reciente
          </h2>
          <div className="p-6">
            {/* {recentActivities.map((recentActivity, index: number) => (
              <ActivityCard {...recentActivity} key={index} />
            ))} */}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
