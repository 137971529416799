import { SquareCheckBig } from "lucide-react";
import { CheckBox } from "../../../../components";
import { FC, useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../context/LanguageContext";

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface SelectionOptionButtonProps {
  questionId: string;
  questionType: string;
  onAnswer: (questionId: string, questionType: string, answer: any) => void;
  index: number;
  option: any;
  options: any[];
  correct: boolean;
}
export const SelectionOptionButton: FC<SelectionOptionButtonProps> = ({
  questionType,
  questionId,
  onAnswer,
  index,
  option,
  options,
  correct,
}) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext?.language || "es"; // Fallback to Spanish

  return (
    <button
      key={`${index}-${language}-${option.content}`}
      onClick={() => {
        onAnswer(questionId, questionType, {
          answer_id: option.id,
          content: option.content,
          question_id: questionId,
          is_pair_id: option.is_pair_id,
        });
      }}
      className={`w-full text-left p-4 rounded-lg transition-colors flex items-center ${
        correct
          ? "bg-red-600 text-white"
          : "bg-secondary-300 hover:bg-secondary-100"
      }`}
    >
      <span className="w-10">
      {correct ? (
        <SquareCheckBig className="mr-4" />
      ) : (
        <CheckBox
          readOnly
          className={""}
          checked={correct ? true : false}
          onChange={() => {}}
        />
      )}
      </span>
      {option.content}
    </button>
  );
};
