import { PanelsTopLeft, Plus, Settings } from "lucide-react";
import {
  Button,
  SelectControlled,
  TextFieldControlled,
} from "../../../../components";
import { useNewEvaluationViewModel } from "../../../../viewModels";
import { Question } from "../Question";
import { CourseSession } from "../../../../../domain/entities";
import { useEffect } from "react";

export const NewEvaluationForm = () => {
  const {
    handleSubmit,
    control,
    onSubmit,
    getValues,
    errors,
    defficultyLevel,
    isPublished,
    detailCourse,
    navigateTo,
    startQuestion,
    questions,
  } = useNewEvaluationViewModel();

  return (
    <div className="mt-8 mb-20">
      {!startQuestion ? (
        <div className="bg-secondary-100 py-8 px-6 rounded-xl flex flex-col gap-6">
          <h3 className="text-white text-2xl lg:text-3xl font-bold">
            Nuevo cuestionario
          </h3>
          <div className="flex items-center gap-6">
            <TextFieldControlled
              id={"title"}
              name="title"
              label="Título"
              placeholder=""
              error={!!errors.title}
              helperText={errors?.title?.message}
              control={control}
              required
            />

            <TextFieldControlled
              id={"description"}
              name="description"
              label="Descripción"
              placeholder=""
              error={!!errors.description}
              helperText={errors?.description?.message}
              control={control}
              required
            />
            {/* <TextFieldControlled
              id={"timeLimit"}
              name="timeLimit"
              label="Tiempo Límite (minutos)"
              placeholder="60"
              type="number"
              error={!!errors.timeLimit}
              helperText={errors?.timeLimit?.message}
              control={control}
              required
            /> */}
          </div>
          <div className="flex items-center gap-6">
            <SelectControlled
              id={"difficultyLevel"}
              name="difficultyLevel"
              label="Nivel de dificultad"
              placeholder=""
              error={!!errors.defficultyLevel}
              helperText={errors?.defficultyLevel?.message}
              control={control}
              options={defficultyLevel}
              required
            />
            <TextFieldControlled
              id={"duration"}
              name="duration"
              label="Duración"
              placeholder=""
              type="number"
              error={!!errors.duration}
              helperText={errors?.duration?.message}
              control={control}
              required
            />
            {/* <SelectControlled
              id={"quizType"}
              name="quizType"
              label="Tipo de cuestionario"
              placeholder=""
              error={!!errors.quizType}
              helperText={errors?.quizType?.message}
              control={control}
              options={evaluationType}
              required
            /> */}
            {/* <SelectControlled
              id={"isPublished"}
              name="isPublished"
              label="Esta publicada"
              placeholder=""
              error={!!errors.isPublished}
              helperText={errors?.isPublished?.message}
              control={control}
              options={isPublished}
              required
              disabled={true}
            /> */}
          </div>
          {/* <div>
            {getValues("quizType") === "1" && (
              <SelectControlled
                id={"production"}
                name="production"
                label="Producción"
                placeholder=""
                error={!!errors.production}
                helperText={errors?.production?.message}
                control={control}
                options={courseProduction}
                required
              />
            )}
          </div> */}

          <div className="flex items-center gap-6">
            <TextFieldControlled
              id={"numberOfQuestions"}
              name="numberOfQuestions"
              label="Número de pregunta"
              placeholder="0"
              type="number"
              error={!!errors.numberOfQuestions}
              helperText={errors?.numberOfQuestions?.message}
              control={control}
              required
              min={3}
            />
            <TextFieldControlled
              id={"passingScore"}
              name="passingScore"
              label="Puntuación de aprobación"
              placeholder="70"
              type="number"
              error={!!errors.passingScore}
              helperText={errors?.passingScore?.message}
              control={control}
              required
              disabled={true}
            />
          </div>

          <div className="flex flex-col lg:flex-row-reverse items-center gap-6">
            <Button onClick={handleSubmit(onSubmit)} className="w-full">
              Guardar Evaluación
            </Button>
            <Button
              color="third"
              onClick={() => {
                navigateTo(`/course-detail?uuid=${detailCourse.uuid}`);
              }}
              className="w-full"
            >
              Cancelar
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {/* Pass quiz id that should get you the quiz question to populate the data */}
          <Question
            questionLength={getValues("numberOfQuestions")}
            questions={questions}
          />
        </div>
      )}
    </div>
  );
};
