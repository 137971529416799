import { Plus } from "lucide-react";
import { Button, TextFieldControlled } from "../../../../components";
import { FC, useEffect, useState } from "react";
import { QuestionTypeSelection } from "../QuestionTypeSelection";
import { MultipleSelectionForm } from "../../MultipleSelection";
import { SingleSelectionForm } from "../../SingleSectionForm";
import { MatchingWordForm } from "../MatchingWordForm";
import { useNewQuestionViewModel } from "../../../../viewModels";
import { stringify } from "node:querystring";
interface QuestionProps {
  questionLength: number;
  questions: any[];
}

export const Question: FC<QuestionProps> = ({ questionLength, questions }) => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    errors,
    onchangeTitle,
    questionTitle,
    setQuestionType,
    questionType,
    handleNext,
    handlePrevious,
    quizQuestions,
    handleSelectType,
    currentQuestionNumber,
    handleDeletAnswer,
  } = useNewQuestionViewModel(`${questionLength}`, questions);

  const [currentQuestion, setCurrentQuestion] = useState(currentQuestionNumber);
  useEffect(() => {
    setCurrentQuestion(currentQuestionNumber);
  }, [currentQuestionNumber]);
  return (
    <div className="bg-black py-8 lg:p-10 rounded-lg">
      <div className="flex items-center justify-between min-w-full ">
        <h3 className="text-white text-2xl lg:text-3xl font-bold px-6">
          Pregunta
          <span className="text-primary-50 text-2xl">
            {` ${currentQuestion + 1}`} /
          </span>
          <span> {questionLength}</span>
        </h3>
      </div>
      <div className="">
        <div className="p-6 flex flex-col">
          <QuestionTypeSelection
            disabled={
              quizQuestions[currentQuestionNumber]?.question_id ? true : false
            }
            questionTitle={questionTitle}
            selectedType={questionType}
            handleSelect={(type) => {
              handleSelectType(type);
            }}
          />
          <TextFieldControlled
            value={questionTitle}
            id={"title"}
            name="title"
            label="Título"
            placeholder=""
            error={!!errors.title}
            helperText={errors?.title?.message}
            control={control}
            required
            onChange={onchangeTitle}
            disabled={!questionType}
          />
        </div>

        {questionType === "multiple_selection" ? (
          <MultipleSelectionForm
            currentQuestionIndex={currentQuestionNumber}
            quizQuestion={quizQuestions}
            submitFormHandler={handleNext}
            handlePrevious={handlePrevious}
            handleDelete={handleDeletAnswer}
          />
        ) : questionType === "single_selection" ? (
          <SingleSelectionForm
            handleDelete={handleDeletAnswer}
            submitFormHandler={handleNext}
            handlePrevious={handlePrevious}
            quizQuestion={quizQuestions}
            currentQuestionIndex={currentQuestionNumber}
          />
        ) : questionType === "word_connection" ? (
          <MatchingWordForm
            handleDelete={handleDeletAnswer}
            submitFormHandler={handleNext}
            handlePrevious={handlePrevious}
            quizQuestion={quizQuestions}
            currentQuestionIndex={currentQuestionNumber}
          />
        ) : (
          null
        )}
      </div>
      {/* {questionType && (
      
      )} */}
    </div>
  );
};
