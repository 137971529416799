import { FC } from "react";
import { StyledPodcastContainer } from "./GameSection.styles";

import { CategoryEntity } from "../../../../../domain/entities";
import { Carousel, GameCard } from "../../../../components";
import { HomeModel } from "../../../../../data/models";
import { GoSubscribe } from "../GoSubscribe";

export interface GameSectionProps {
  gameProductions: CategoryEntity;
  onClickCard: (uuid: string) => void;
}

export const GameSection: FC<GameSectionProps> = ({ gameProductions, onClickCard }) => {
  const { title, description, games } = gameProductions;
  return (
    <StyledPodcastContainer>
      <h3>{title.toUpperCase()}</h3>
      <p>{description}</p>

      <div className="w-full flex lg:pt-8">
        <Carousel
          slidesToShow={4}
          slidesToScroll={1}
          infinite={false}
          dots={false}
          arrows={true}
          centerMode={false}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
                arrows: false,
                centerMode: true,
              },
            },
          ]}
        >
          {games.map((currentGame, index) => (
            <GameCard
              className={"h-full mx-auto "}
              key={`P-${index}`}
              whitSeeMore
              gameData={currentGame}
              onClickCard={onClickCard}
            />
          ))}
        </Carousel>
      </div>
    </StyledPodcastContainer>
  );
};
