import { FC, useContext } from "react";
import { convertToMinutesAndSeconds, useWindowSize } from "../../../../hooks";
import { useProfileDropDown } from "../../../../components/ProfileDropDown/useProfileDropDown";
import toast from "react-hot-toast";
import { BadgeCheck } from "lucide-react";
import { LanguageContext } from "../../../../context/LanguageContext";

interface ChapterCardProps {
  imageUrl: string;
  title: string;
  description: string;
  duration: number;
  currentPage: number;
  chapterid: string;
  productionId: string;
  isCompleted: boolean;
  showDropDown: boolean;
  handleStartChapter: (charpterId: string) => void;
}

export const ChapterCard: FC<ChapterCardProps> = ({
  imageUrl,
  title,
  description,
  currentPage,
  showDropDown,
  duration,
  chapterid,
  handleStartChapter,
  isCompleted,
  productionId,
}) => {
  const { isDesktop } = useWindowSize();
  const languageContext = useContext(LanguageContext);
  const language = languageContext?.language || "es"; // Fallback to Spanish

  return (
    <div
      onClick={() => {
        localStorage.setItem("currentPage", `${currentPage}`);
        handleStartChapter(chapterid);
        if (!showDropDown) {
          localStorage.setItem("currentProdction", `${productionId}`);
        }
      }}
    >
      <div className="flex items-center gap-4 cursor-pointer">
        <div className="flex-shrink-0 w-12 h-12">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-full object-cover rounded-lg"
            style={{ width: "3rem", height: "3rem" }}
          />
        </div>

        <div className="flex-1 min-w-0">
          <h2
            className="text-lg font-semibold text-white mb-1 leading-tight line-clamp-2 flex item-center gap-2"
            key={`${language}-${title}`}
          >
            Capítulo : {title}
            {isCompleted && (
              <span className=" items-center justify-center bg-su rounded-full ">
                <BadgeCheck color={"#5cb85c"} />
              </span>
            )}
          </h2>
          <p className="text-primary-100 text-xs text-left lg:text-sm font-primaryRegular">
            {convertToMinutesAndSeconds(duration)}
          </p>
        </div>
      </div>
    </div>
  );
};
