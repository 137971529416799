import {
  PlayCircle,
  BookOpen,
  Users,
  Award,
  Mail,
  Clapperboard,
  Disc3,
  BookCopy,
  Drama,
  School,
  Rocket,
  GraduationCap,
  SquareCheck,
  Check,
  Handshake,
  ShieldPlus,
} from "lucide-react";
import { DefaultLayout } from "../../layouts";
import { Button, Rating } from "../../components";
import { useNavigation } from "../../hooks";
import { ReactComponent as StoryTrainingLogo } from "../../assets/images/storyTrainingLogo.svg";
import TestominalImage1 from "../../assets/images/1.jpg";
import TestominalImage2 from "../../assets/images/2.jpg";
import TestominalImage3 from "../../assets/images/3.jpg";
import ProsegurLogo from "../../assets/images/prosegur.png";
import FotoMarcaLogo from "../../assets/images/foto-marca-diario.png";
import mafreLogo from "../../assets/images/LOGO-MAPFRE.png";

export const ServicePage = () => {
  const { navigateTo } = useNavigation();
  const products = [
    {
      title: "Caminos de Aprendizaje",
      description: "Certificados por la Universidad Francisco de Vitoria",
      features: [
        "Seleccionamos lo mejor de nuestro catálogo para crear un recorrido personalizado de formación.",
        "Refuerza el aprendizaje con sesiones de Zoom dirigidas por expertos.",
        "Ideal para equipos que buscan una experiencia formativa estructurada y de alto impacto.",
      ],
      icon: <GraduationCap className="text-white h-8 w-8" />,
      imageUrl: "https://source.unsplash.com/random/800x600/?education,learning",
      perfect: "Perfecto si: Buscas formación profesional certificada, dinámica y práctica.",
    },
    {
      title: "Acceso al Catálogo Completo",
      description: "La formación como nunca la imaginaste, al alcance de tu equipo",
      features: [
        "Accede a más de 20 producciones originales: series, documentales, píldoras y más",
        "Todo en un formato de suscripción estilo streaming.",
        "Disponible 24/7 para fomentar el aprendizaje continuo.",
      ],
      icon: <BookCopy className="text-white h-8 w-8" />,
      imageUrl: "https://source.unsplash.com/random/800x600/?movies,streaming",
      perfect: "Perfecto si: Necesitas sorprender a tu equipo con un evento memorable que deje huella.",
    },
    {
      title: "Eventos de Formación en Cine o Teatro",
      description: "Formación espectacular, literalmente.",
      features: [
        "Experiencias inmersivas que mezclan cine y teatro para abordar habilidades como negociación, liderazgo y comunicación.",
        "Creado por profesionales del cine y el teatro para empresas que buscan algo único",
        "Humor, emoción y aprendizaje en una combinación inolvidable.",
      ],
      icon: <Drama className="text-white h-8 w-8" />,
      imageUrl: "https://source.unsplash.com/random/800x600/?theater,cinema",
      perfect: "Perfecto si: Necesitas sorprender a tu equipo con un evento memorable que deje huella.",
    },
    {
      title: "Eventos de Formación In-Company",
      description: "Formación a medida para transformar a tu equipo.",
      features: [
        "Talleres participativos en habilidades clave como hablar en público, negociación y gestión del cambio.",
        "Diseñados para adaptarse a las necesidades específicas de tu empresa.",
        "Facilitadores expertos que combinan teoría y práctica de manera dinámica.",
      ],
      icon: <School className="text-white h-8 w-8" />,
      imageUrl: "https://source.unsplash.com/random/800x600/?corporate,training",
      perfect:
        "Perfecto si: Quieres huir de las charlas aburridas y transformar la formación en algo divertido y efectivo.",
    },
    {
      title: "Producciones a Medida",
      description: "¿Quieres algo único? Lo creamos para ti.",
      features: [
        "Diseñamos series o documentales adaptados a los valores y objetivos de tu empresa.",
        "Desde guion hasta producción, reflejamos tu identidad en cada fotograma.",
      ],
      icon: <Rocket className="text-white h-8 w-8" />,
      imageUrl: "https://source.unsplash.com/random/800x600/?custom,production",
      perfect:
        "Perfecto si: Quieres que tu equipo viva una experiencia personalizada y alineada con tu cultura empresarial.",
    },
  ];

  const whyChooseUsArray = [
    {
      title: "Lo normal ya no funciona",
      description: ` Adiós a las charlas monótonas y PowerPoints eternos. Aquí,
     aprendemos diferente.`,
      imageUrl: "https://res.cloudinary.com/drq7jke7x/image/upload/v1711035042/preview_images/fnckqovbggpuivmspv25.png",
    },
    {
      title: "Diseñado para empresas que quieren más",
      description: `Desde habilidades de negociación hasta liderazgo, cubrimos las
                  soft-skills que hacen la diferencia.`,

      imageUrl: "https://res.cloudinary.com/drq7jke7x/image/upload/v1667141477/preview_images/aasivpzhlq7dpxyv39gb.jpg",
    },
    {
      title: "Historias que conectan",
      description: `Series, películas y documentales de producción propia. Porque
                  aprendemos con historias.`,

      imageUrl: "https://res.cloudinary.com/drq7jke7x/image/upload/v1711034139/preview_images/mudfvuxuxaxifon2vwy4.png",
    },
  ];

  const trustedBy = [
    {
      name: "MAPFRE",
      logoUrl: mafreLogo,
    },
    {
      name: "Prosegur",
      logoUrl: ProsegurLogo,
    },
    {
      name: "",
      logoUrl: FotoMarcaLogo,
    },
  ];

  const Testimonies = [
    {
      image: TestominalImage1,
      name: "Paulino Archilla",
      position: "Subdirector de Formación (MAPFRE)",
      testimony:
        "Estoy encantado con el acuerdo con storytraining. Mostraron gran compromiso para entender nuestras necesidades y buscar soluciones que beneficiaran a todos. Su enfoque diferente, atrevido y divertido no es frecuente en la formación. ¡Una formación de cine!",
      rating: 3.5,
    },
    {
      image: TestominalImage2,
      name: "Ernesto Sisto",
      position: "Director Nacional (IMAN Global Consulting)",
      testimony:
        "Creemos que la diversión no está reñida con el aprendizaje, y storytraining ejemplifica esa idea. Son el compañero perfecto, complementando y enriqueciendo muchas de las soluciones que ofrecemos a nuestros clientes.",
      rating: 4.5,
    },
    {
      image: TestominalImage3,
      name: "Cristina Ros Carrero, PhD",
      position: "Estudiante de Máster en Talento Ephos",
      testimony:
        "Los vídeos de Storytraining han sido emocionantes y útiles para mi formación en la industria farmacéutica. Aprecio su calidad, sentido común y ese toque filosófico que Juan Mateo transmite. ¡Recomiendo esta plataforma a quienes busquen aprendizaje práctico y humano!",
      rating: 4,
    },
  ];

  const whatWeDOBetter = [
    {
      title: "Negociación",
      description: "prende a cerrar tratos como un verdadero estratega",
      icon: <Handshake className="text-white h-8 w-8" />,
    },
    {
      title: "Liderazgo",
      description: "Inspira a tu equipo y lidera con confianza",
      icon: <ShieldPlus className="text-white h-8 w-8" />,
    },
    {
      title: "Gestión de conflictos",
      description: "Resuelve problemas, no los evites",
      icon: <Handshake className="text-white h-8 w-8" />,
    },
    {
      title: "Inteligencia colaborativa",
      description: "Conecta, comunica y crea sinergias.",
      icon: <Handshake className="text-white h-8 w-8" />,
    },
    {
      title: "Prevención de Riesgos Laborales",
      description: "SOIFI4E: Soluciones avanzadas para entornos laborales seguros.",
      icon: <Handshake className="text-white h-8 w-8" />,
    },
  ];

  return (
    <DefaultLayout>
      <div className="min-h-screen bg-black">
        <div
          className="gradient-bg text-white"
          style={{
            backgroundImage: `url(https://res.cloudinary.com/drq7jke7x/image/upload/v1710965749/preview_images/ajpojgrlrkh3nuqacbyt.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div
            className="mx-auto px-8 py-20 flex flex-col gap-4 items-start justify-end bg-[rgba(0,0,0,.5)]"
            style={{ height: "32rem" }}
          >
            <h1 className="text-6xl font-primaryBold mb-6 text-start max-w-[70%]">
              Aprender nunca había sido tan emocionante
            </h1>
            <p className="text-xl mb-8 text-start max-w-[70%]">
              Descubre cómo transformar las habilidades de tu equipo con Storytraining, la plataforma que usa el poder
              de las historias para un aprendizaje memorable.
            </p>
          </div>
        </div>

        {/* Why Choose Us */}
        <div className="py-20 bg-secondary-300 w-full">
          <div className="lg:px-16 lg:mx-auto">
            <h2 className="text-4xl font-primaryBold text-center mb-12 text-primary-50">
              ¿Por qué elegir Storytraining?
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {whyChooseUsArray.map((item) => (
                <div
                  className="rounded-2xl shadow-lg flex flex-col items-center justify-center"
                  style={{
                    backgroundImage: `url(${item.imageUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="bg-[rgba(0,0,0,.5)] p-12">
                    <h3 className="text-2xl font-primarySemibold mb-4 text-white text-left">{item.title}</h3>
                    <p className="text-white font-primaryRegular text-left">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-black py-12">
          <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
            <h1 className="text-4xl font-primaryBold text-center mb-12 text-primary-50">
              Lo que hacemos mejor que nadie
            </h1>
            <h2 className="text-xl font-primaryRegular text-center mb-6 lg:mb-12 text-white max-w-[70%] mx-auto">
              Potenciamos tus habilidades y desarrollamos las competencias que marcan la diferencia.
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {whatWeDOBetter.slice(0, 3).map((item, index: number) => (
                <div className="bg-secondary-300 p-6 shadow-md rounded-xl hover:shadow-lg transition" key={`offer-${index}`}>
                  <div className="flex items-center justify-center h-20 w-20 bg-primary rounded-full mx-auto mb-4">
                    {item.icon}
                  </div>
                  <h3 className="text-2xl font-primarySemibold text-white mb-2">{item.title}</h3>
                  <p className="text-white font-primaryRegular text-lg">{item.description} </p>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 mx-auto lg:w-[80%] gap-8 mt-10">
              {whatWeDOBetter.slice(3, 5).map((item, index: number) => (
                <div className="bg-secondary-300 p-6 shadow-md rounded-xl hover:shadow-lg transition" key={`we-do-${index}`}>
                  <div className="flex items-center justify-center h-20 w-20 bg-primary rounded-full mx-auto mb-4">
                    {item.icon}
                  </div>
                  <h3 className="text-2xl font-primarySemibold text-white mb-2">{item.title}</h3>
                  <p className="text-white font-primaryRegular text-lg">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Products */}
        <div className="py-20 bg-secondary-200">
          <div className="container mx-auto px-12">
            <div className="flex flex-col gap-2 items-center justify-center">
              <h2 className="text-4xl font-primaryBold text-center mb-8 text-primary-50">
                Nuestros Productos: Aprendizaje a tu Medida
              </h2>
              <h2 className="text-xl font-primaryRegular text-center mb-6 lg:mb-12 text-white max-w-[70%]">
                En Storytraining, combinamos creatividad, innovación y formación para transformar habilidades en
                resultados. Descubre cómo nuestros formatos pueden llevar a tu equipo al siguiente nivel:
              </h2>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {products.slice(0, 3).map((product, index) => (
                <div key={index} className="bg-secondary-300 hover:bg-secondary-100 p-8 rounded-2xl shadow-lg">
                  <div className="flex items-center justify-center h-20 w-20 bg-primary rounded-full mx-auto mb-4">
                    {product.icon}
                  </div>
                  <h3 className="text-2xl font-primarySemibold mb-4 text-white text-center">{product.title}</h3>
                  <p className="text-gray-300 text-lg font-primarySemibold my-4 text-center">{product.description}</p>
                  <ul className="space-y-2 pl-4 list-none mt-6">
                    {product.features.map((feature, idx) => (
                      <li key={idx} className="text-gray-200 font-primaryRegular list-none flex gap-2 py-4">
                        <div>
                          <Check className="text-primary-50 " />
                        </div>
                        <p>{feature}</p>
                      </li>
                    ))}
                  </ul>
                  <p className="my-4 font-primarySemiboldItalic text-sm text-gray-400">{product.perfect}</p>
                </div>
              ))}
            </div>
            <div className="grid md:grid-cols-2 gap-8  w-full md:w-[80%] mx-auto mt-10">
              {products.slice(3, 5).map((product, index) => (
                <div key={index} className="bg-secondary-300 hover:bg-secondary-100 p-8 rounded-2xl shadow-lg">
                  <div className="flex items-center justify-center h-20 w-20 bg-primary rounded-full mx-auto mb-4">
                    {product.icon}
                  </div>
                  <h3 className="text-2xl font-primarySemibold mb-4 text-white text-center">{product.title}</h3>
                  <p className="text-gray-300 text-lg font-primarySemibold my-4 text-center">{product.description}</p>
                  <ul className="space-y-2 pl-4 list-none mt-6">
                    {product.features.map((feature, idx) => (
                      <li key={idx} className="text-gray-200 font-primaryRegular list-none flex gap-2 py-4">
                        <div>
                          <Check className="text-primary-50 " />
                        </div>
                        <p>{feature}</p>
                      </li>
                    ))}
                  </ul>
                  <p className="my-4 font-primarySemiboldItalic text-sm text-gray-400">{product.perfect}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Trusted By Section */}
        <section className="mb-16 text-center">
          <h2 className="text-4xl font-primaryBold text-center mb-10 text-white mt-6">
            ¿Quiénes ya han confiado en nosotros?
          </h2>
          <div className="flex flex-wrap justify-center gap-8 items-center">
            {trustedBy.map((company, index) => (
              <div key={index} className="text-center">
                <img src={company.logoUrl} alt={company.name} className="h-20 object-contain mb-4" />
              </div>
            ))}
          </div>
        </section>

        {/* Testimonial Section */}
        <div className="py-20 bg-secondary-100">
          <div className="container mx-auto px-12">
            {" "}
            <section className="mb-16 text-center">
              <h2 className="text-4xl font-primaryBold text-center mb-10 text-primary">
                ¿Quiénes ya han confiado en nosotros?
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                {Testimonies.map((testimony, index: number) => (
                  <div className="max-w-md w-full px-6 py-8  rounded-2xl shadow-lg bg-secondary-300 hover:bg-secondary-200">
                    <div className="flex flex-col items-center space-x-4">
                      <img className="w-20 h-20 rounded-full shadow-lg" src={testimony.image} alt="User Avatar" />
                      <div>
                        <h3 className="text-2xl font-primarySemibold text-white pt-2">{testimony.name}</h3>
                      </div>
                    </div>
                    <div className="mt-4 text-gray-200">
                      <p className="text-lg pb-4 font-primaryItalic">
                        {testimony.position} : {testimony.testimony}
                      </p>
                    </div>
                    <Rating value={testimony.rating} />
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>

        {/* Contact */}
        <div id="contact" className="gradient-bg text-white py-20">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-8">¿Listo para dar el salto?</h2>
            <p className="text-xl mb-8">Déjanos enseñarte cómo revolucionar el aprendizaje en tu empresa.</p>
            <div className="flex flex-col md:flex-row justify-center items-center gap-4">
              <a
                href="mailto:info@storytraining.eu"
                className="flex items-center bg-transparent text-primary px-8 py-3 rounded-full font-semibold hover:bg-opacity-90 transition-all"
              >
                <Mail className="mr-2" />
                info@storytraining.eu
              </a>
              <Button
                onClick={() => {
                  navigateTo("/");
                }}
              >
                Visitar sitio web
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
