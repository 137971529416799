import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues } from "react-hook-form";
import { newEvaluationSchema } from "../../validators/NewEvaluationSchema";
import { useState } from "react";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { useCaseCourseDetail } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { number } from "yup";
import { filter } from "lodash";

export const useNewQuestionViewModel = (questionLength: string, questions: any[]) => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(newEvaluationSchema) });
  const { submitQuestion, submitAnswers, deleteQuestion, deleteAnswer } =
    useCaseCourseDetail(CourseDetailsDataService());
  const { getQueryParam, navigateTo } = useNavigation();
  const courseuuid = getQueryParam("courseId");
  const quizId = getQueryParam("quiz");
  const [currentQuestionNumber, SetCurrentQuestionNumber] = useState(0)
  const [questionType, setQuestionType] = useState<string>("");
  const [questionTitle, setQuestionTitle] = useState("")
  const [quizQuestions, setQuizQuestions] = useState<any>([])
  const [storePreviousQuizQuestion, setStorePreviousQuizQuestion] = useState<any[]>([])
  const [saveQuizCurrentQuestions, setSaveQuizCurrentQuestions] = useState<any>()
  const onPostSuccessQuestion = (question: any[]) => {
    if (question[1]) {
      quizQuestions[currentQuestionNumber] = { question_id: question[1] }
      setQuizQuestions(quizQuestions)
    }
  }

  const onPostErroQuestion = (error: AxiosError) => {
    toast.error(error.message)
  }

  const onchangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionTitle(e.target.value);
  };

  const formatAnswersToFixRequestData = (data: any) => {
    let dataFormat
    if (data.type === "word_connection") {
      const answersArray = data.contents.map((item: string, index: number) => {
        return {
          content: data.contents[index],
          match_pair_content: data.matchPairContent[index]
        }
      })
      dataFormat = {
        type: "word_connection",
        title: questionTitle,
        answers: answersArray
      }
    } else if (data.type === "single_selection") {
      const answers = data.answers.map((item: any, index: number) => {
        return {
          is_correct: item.is_correct,
          content: item.content
        }
      })
      dataFormat = {
        type: "single_selection",
        title: questionTitle,
        answers: answers
      }
    } else {
      dataFormat = {
        type: "multiple_selection",
        title: questionTitle,
        answers: data.answers
      }
    }
    return dataFormat
  }


  const deleteAnswerSuccess = async (data: any) => {
  }

  const deleteAnswerError = async (error: AxiosError) => {
    console.log(error)
  }

  const handleDeletAnswer = (questionId: string,
    answerIndex: number,
    questionType: string) => {
    const question: any = storePreviousQuizQuestion.filter((item: any) => item.id === questionId)[0]
    if (questionType === "word_connection") {
      const questionOptions = question.answers.filter((item: any) => item.match_pair_id)
      const matchingQuestionOptions = question.answers.filter((item: any) => !item.match_pair_id)
      matchingQuestionOptions[answerIndex].id
      deleteAnswer({ success: deleteAnswerSuccess, error: deleteAnswerError, answerId: matchingQuestionOptions[answerIndex]?.id })
      deleteAnswer({ success: deleteAnswerSuccess, error: deleteAnswerError, answerId: questionOptions.filter((item: any) => item?.match_pair_id === matchingQuestionOptions[answerIndex]?.id)[0]?.id })
    } else {
      deleteAnswer({ success: deleteAnswerSuccess, error: deleteAnswerError, answerId: question?.answers[answerIndex]?.id })
    }
  }

  const onSubmitAnswerSuccess = async (answers: any) => {
    window.scrollTo(0, 0)
    setSaveQuizCurrentQuestions("")
    if (quizQuestions[currentQuestionNumber]) {
      if (currentQuestionNumber + 1 >= parseInt(questionLength)) {
        navigateTo(`/course-detail?uuid=${courseuuid}`)
      } else {
        if (!quizQuestions[currentQuestionNumber + 1] ||
          quizQuestions[currentQuestionNumber + 1] === undefined ||
          quizQuestions[currentQuestionNumber + 1] == null) {
          setQuestionType("")
          setQuestionTitle("")
          SetCurrentQuestionNumber(currentQuestionNumber + 1)
        } else {
          setQuestionType(quizQuestions[currentQuestionNumber + 1].type)
          setQuestionTitle(quizQuestions[currentQuestionNumber + 1].title)
          SetCurrentQuestionNumber(currentQuestionNumber + 1)
        }
      }
    } else {
      if (currentQuestionNumber + 1 >= parseInt(questionLength)) {
        navigateTo(`/course-detail?uuid=${courseuuid}`)
      } else {
        setQuestionType(quizQuestions[currentQuestionNumber + 1].type)
        setQuestionTitle(quizQuestions[currentQuestionNumber + 1].title)
        SetCurrentQuestionNumber(currentQuestionNumber + 1)
      }
    }
  }

  const onSubmitAnswerError = async (error: AxiosError) => {
  }

  const handleSelectType = (type: string) => {
    if (questionTitle && !quizQuestions[currentQuestionNumber]) {
      quizQuestions[currentQuestionNumber] = null
      setQuizQuestions(quizQuestions)
      setQuestionTitle("")
      setQuestionType(type)
    } else {
      if (!saveQuizCurrentQuestions) {
        if (quizQuestions[currentQuestionNumber]) {
          setSaveQuizCurrentQuestions(quizQuestions[currentQuestionNumber])
          quizQuestions[currentQuestionNumber] = { question_id: quizQuestions[currentQuestionNumber].question_id }
          setQuizQuestions(quizQuestions)
          setQuestionTitle("")
          setQuestionType(type)
        } else {
          setSaveQuizCurrentQuestions(quizQuestions[currentQuestionNumber])
          quizQuestions[currentQuestionNumber] = { question_id: "" }
          setQuizQuestions(quizQuestions)
          setQuestionTitle("")
          setQuestionType(type)
        }
      } else {
        if (type === saveQuizCurrentQuestions.type) {
          quizQuestions[currentQuestionNumber] = saveQuizCurrentQuestions
          setQuizQuestions(quizQuestions)
          setQuestionTitle(saveQuizCurrentQuestions.title)
          setQuestionType(type)
          setSaveQuizCurrentQuestions("")
        } else {
          setQuestionTitle("")
          setQuestionType(type)
          quizQuestions[currentQuestionNumber] = { question_id: saveQuizCurrentQuestions.question_id }
          setQuizQuestions(quizQuestions)
        }
      }
    }
  }


  const updateTheNewQuestionAndAnswerToHaveThePerviousAnswersId = (updatedData: any, originalData: any) => {
    if (updatedData.type === "single_selection" || updatedData.type === "multiple_selection") {
      const mergedAnswers = updatedData.answers.map((answer: any, index: number) => {
        const originalAnswer = originalData.answers[index]; // Get answer by index
        return {
          ...answer,
          id: originalAnswer ? originalAnswer.id : undefined,
        };
      });
      return mergedAnswers
    } else {
      const newQuestionId = originalData.id;
      const lastPreviousId = Math.min(...originalData.answers.map((a: any) => a.id));
      let newId = lastPreviousId;
      const newAnswers = updatedData.contents.flatMap((content: any, index: number) => {
        const id1 = newId++;
        const id2 = newId++;

        return [
          {
            id: id1,
            content: content,
            is_correct: true,
            question_id: newQuestionId,
            match_pair_id: id2,
          },
          {
            id: id2,
            content: updatedData.matchPairContent[index],
            is_correct: true,
            question_id: newQuestionId,
            match_pair_id: null,
          },
        ];
      });
      return newAnswers
    }
  }

  const handleNext = async (data: any) => {
    if (questionTitle) {
      const requestBody = formatAnswersToFixRequestData(data)
      const body = { type: questionType, title: questionTitle, quizId: quizId }
      if (quizQuestions[currentQuestionNumber].question_id) {
        const newAnswers = updateTheNewQuestionAndAnswerToHaveThePerviousAnswersId(data, storePreviousQuizQuestion[currentQuestionNumber])
        submitQuestion({ success: onPostSuccessQuestion, error: onPostErroQuestion, questioBody: body, questionId: quizQuestions[currentQuestionNumber].question_id })
        submitAnswers({ success: onSubmitAnswerSuccess, error: onSubmitAnswerError, answerBody: { answers: newAnswers }, answerId: "", questionId: quizQuestions[currentQuestionNumber].question_id })
      } else {
        const newQuestion: any = await submitQuestion({ success: onPostSuccessQuestion, error: onPostErroQuestion, questioBody: body, questionId: "" })
        await newQuestion?.data.length > 0 && submitAnswers({ success: onSubmitAnswerSuccess, error: onSubmitAnswerError, answerBody: { ...requestBody, question_id: newQuestion?.data[1] }, answerId: "", questionId: "" })
      }
      quizQuestions[currentQuestionNumber] = requestBody
      setQuizQuestions(quizQuestions)
    } else {
      toast.error("Por favor escriba la pregunta.");
    }
  }

  const deleteQuestionSuccess = (data: any) => {

  }

  const deleteQuestionError = (data: AxiosError) => {

  }

  const handlePrevious = async () => {
    // The reason for deleting question is because the user can change the question type without 
    // changing question title whick triggers question creation thus 
    // the reason for deleting
    const body = { type: quizQuestions[currentQuestionNumber - 1].type, title: quizQuestions[currentQuestionNumber - 1]!.title }
    SetCurrentQuestionNumber(currentQuestionNumber - 1)
    setQuestionType(body.type)
    setQuestionTitle(body.title)
  }

  useCustomEffect(() => {
    if (questions) {
      const storedQuestion = questions.map((question, index) => {
        if (question.type === "word_connection") {
          const mainContent = question.answers.filter((item: any) => item.match_pair_id !== null);
          const matchItems = question.answers.filter((item: any) => item.match_pair_id === null);
          const answers = mainContent.map((item: any) => {
            const match_pair_content = matchItems.find((matchItem: any) => matchItem.id === item.match_pair_id)
            return { content: item.content, match_pair_content: match_pair_content.content }
          })
          const questionItem = {
            answers: answers, question_id: question.id, id: question.id, title: question.text, type: "word_connection"
          }
          return questionItem
        } else if (question.type === "single_selection") {
          const answers = question.answers.map((item: any) => { return { id: "", content: item.content, is_correct: item.is_correct } })
          const questionItem = {
            answers: answers, question_id: question.id, id: question.id, title: question.text, type: "single_selection"
          }
          return questionItem
        } else {
          const answers = question.answers.map((item: any) => { return { id: "", content: item.content, is_correct: item.is_correct } })
          const questionItem = {
            answers: answers, question_id: question.id, id: question.id, title: question.text, type: "multiple_selection"
          }
          return questionItem
        }
      })
      setStorePreviousQuizQuestion(questions)
      setQuestionTitle(storedQuestion[0]?.title ? storedQuestion[0]?.title : "")
      setQuestionType(storedQuestion[0]?.type ? storedQuestion[0]?.type : "")
      setQuizQuestions(storedQuestion);

    } else {
      setQuizQuestions(new Array(parseInt(questionLength)).fill(null));
    }
  }, [])

  return {
    handleSubmit,
    control,
    setValue,
    setError,
    errors,
    getValues,
    onchangeTitle,
    setQuestionType,
    questionType,
    questionTitle,
    handleNext,
    handlePrevious,
    quizQuestions,
    currentQuestionNumber,
    handleSelectType,
    handleDeletAnswer
  };
};
