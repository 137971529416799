import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSessionToken, getSubscriptionStatus, getUser } from "../../../data/dto/selectors";
import { QuizEntity } from "../../../domain/entities";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { assessmentSchema } from "../../validators";
import { QuizDetailDataService } from "../../../data/services";
import { useCaseCourseQuiz } from "../../../domain/useCases";
import { useNavigation } from "../../hooks";


interface QuizErrorInterface {

  correct_answers: number
  failedMessage: string
  grade: number
  incorectAnswer: number;
  total_questions: number;
  passing_score: number
}

export function useQuizDetailsViewModel() {
  const { getQueryParam, navigateTo } = useNavigation();
  const quizId = getQueryParam("quiz");
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(assessmentSchema) });

  const dispatch = useDispatch();
  const token = useSelector(getSessionToken);
  const { getQuizDetails, submitQuiz } =
    useCaseCourseQuiz(QuizDetailDataService());

  const [quizDetails, setQuizDetails] =
    useState<QuizEntity>({} as QuizEntity);
  const [studentQuizAnswer, setStudentQuizAnswer] = useState<any[]>([])
  const [currentQuestionAnswer, setCurrentQuestionAnser] = useState<any>()
  const [startQuiz, setStartQuiz] = useState(false)
  const [disableNext, setDisableNext] = useState(true)
  const [showResults, setShowResult] = useState(false)
  const [showTryAgain, setShowTryAgain] = useState(false)
  interface ResultInterface { totalQuestions: number; correctAnswers: number; incorectAnswer: number; grade: number, failedMessage: string, passingScore: number }
  const [results, setResults] = useState<ResultInterface>({
    totalQuestions: 0,
    correctAnswers: 0,
    incorectAnswer: 0,
    grade: 0,
    passingScore: 0,
    failedMessage: ""
  })
  const [currentQuestion, setCurrentQuestion] = useState<number>(0)



  const onAnswer = (questionId: string, questionType: string, answer: any) => {
    setCurrentQuestionAnser({ question_id: questionId, type: questionType, answers: answer })
    setDisableNext(false)
  };

  const handleSubmitAnswer = async () => {

    if (currentQuestion + 1 < quizDetails.questions.length) {
      setStudentQuizAnswer([...studentQuizAnswer, currentQuestionAnswer])
      setCurrentQuestion(currentQuestion + 1)
      setDisableNext(true)
    } else {
      await submitQuiz({
        success: successSubmitAssesment, error: errorSubmitAssesment,
        body: { quiz_id: quizId, questions: [...studentQuizAnswer, currentQuestionAnswer] }
      })
    }
  }

  const timeEllapsed = async () => {
 
    await submitQuiz({
      success: successSubmitAssesment, error: errorSubmitAssesment,
      body: { quiz_id: quizId, questions: [...studentQuizAnswer, currentQuestionAnswer] }
    })
  }

  const successSubmitAssesment = async (assessment: any) => {
    setResults({
      totalQuestions: assessment.total_questions,
      correctAnswers: assessment.correct_answers,
      incorectAnswer: assessment.total_questions - assessment.correct_answers,
      grade: assessment.grade,
      failedMessage: "",
      passingScore: assessment.passingScore
    })
    setShowResult(true)
  }

  const errorSubmitAssesment = async (error: QuizErrorInterface) => {
    setShowTryAgain(true)

    setResults({
      totalQuestions: error.total_questions,
      correctAnswers: error.
        correct_answers,
      incorectAnswer: error.incorectAnswer,
      grade: error.grade,
      failedMessage: error.failedMessage,
      passingScore: error.
        passing_score
    })
    setShowResult(true)
  }

  const onSubmit = async (data: FieldValues) => {

  };

  const onGetQuizSuccess = (QuizDetails: QuizEntity) => {
    setQuizDetails(QuizDetails)
  }

  const errorGetCouresDestails = (error: AxiosError) => { }
  useLayoutEffect(() => {
    if (quizId) {
      getQuizDetails({ success: onGetQuizSuccess, error: errorGetCouresDestails, quizId: quizId })
    }
  }, [])


  useLayoutEffect(() => {
    setCurrentQuestionAnser(currentQuestionAnswer)
  }, [currentQuestionAnswer])


  return {
    handleSubmit,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,
    token,
    onAnswer,
    currentQuestion,

    startQuiz,
    disableNext,
    handleSubmitAnswer, quizId,
    quizDetails,
    showResults,
    results,
    timeEllapsed
  };
}
