import { useState } from "react";
import { AxiosError } from "axios";
import { HomeModel } from "../../../data/models";
import { useCustomEffect } from "../../hooks/useHookEffect";
import { useCaseHome, useCaseLearningPath, useCaseProductions, useCaseSubscriptions } from "../../../domain/useCases";
import { homeDataService, productionsDataService, subscriptionsDataService } from "../../../data/services";
import companySubscription from "../../assets/images/companySubscription.svg";
import { CategoryEntity, LearningPathEntity, SubscriptionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { getSessionToken, getSubscriptionStatus } from "../../../data/dto/selectors";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { learningPathDataService } from "../../../data/services/learningPath.data.service";
import { useModal } from "../../context/ModalContext";

export function useHomeViewModel() {
  const { navigateTo, getCurrentPathName } = useNavigation();
  const { showLoading, hideLoading } = useModal();
  const token = useSelector(getSessionToken);

  const userSubscription = useSelector(getSubscriptionStatus);

  const [homeData, setHomeData] = useState<HomeModel>({} as HomeModel);

  const [favoritesProductions, setFavoritesProductions] = useState<Array<CategoryEntity> | []>([]);
  const [productions, setProductions] = useState<Array<CategoryEntity> | []>([]);
  const [podcastProductions, setPodcastProductions] = useState<CategoryEntity>();
  const [listLearningPathsToRent, setlistLearningPathsToRent] = useState<Array<LearningPathEntity>>([]);
  const [gameProductions, setGameProductions] = useState<CategoryEntity>();
  const [subscriptionsList, setSubscriptionsList] = useState<Array<SubscriptionEntity> | []>([]);

  const { get } = useCaseSubscriptions(subscriptionsDataService());
  const { getFiltered, getAll, getGameById } = useCaseProductions(productionsDataService());

  const { getlistLearningPathsToRentToRent } = useCaseLearningPath(learningPathDataService());

  const { getHomeData, getFavorites } = useCaseHome(homeDataService());
  const productionFilter = {
    limit: 2,
  };
  function successProductionFiltered(productions: Array<CategoryEntity>) {
    const videoProductionsList = productions.filter(
      (currentProduction) => currentProduction.slug !== "podcast" && currentProduction.slug !== "juegos"
    );
    setProductions(videoProductionsList);
  }
  function errorProductionFiltered(e: AxiosError) {
    console.log("errorProductionFiltered", e);
  }

  function successGamePodcastProductions(productions: Array<CategoryEntity>) {
    const podcastProductionsList = productions.find((currentProduction) => currentProduction.slug === "podcast");
    const gameProductionsList = productions.find((currentProduction) => currentProduction.slug === "juegos");
    setPodcastProductions(podcastProductionsList);
    setGameProductions(gameProductionsList);
  }
  function errorGamePodcastProductions(e: AxiosError) {
    console.log("errorGamePodcastProductions", e);
  }

  function successGetListOfLearningPathsToRent(learningPaths: Array<LearningPathEntity>) {
    setlistLearningPathsToRent(learningPaths);
  }

  function errorGetListOfLearningPathsToRent(e: AxiosError) {
    console.log("errorGetListOfLearningPathsToRent", e);
  }

  /*Game card */
  const handleSuccessGameById = (data: any) => {
    if (data.url) {
      window.open(`${data.url}`, "_blank");
    }
  };

  const handleErrorGameById = (error: any) => {
    if (error.response.data.message) {
      if (!token) {
        toast.error("Debes iniciar sesión para acceder al contenido");
      } else {
        userSubscription?.stripeStatus !== "active"
          ? toast.error("Debes subscribirte para acceder al contenido")
          : toast.error(error.response.data.message);
      }
    }
  };

  const onGetGameById = (gameId: string) => {
    getGameById({
      id: gameId,
      success: handleSuccessGameById,
      error: handleErrorGameById,
    });
  };

  /*Handle Home data */
  const onGetHomeDataSuccess = (homeData: HomeModel) => {
    setHomeData(homeData);
    hideLoading();
  };

  /*Handle Home favorites */
  const onGetHomeFavoritesSuccess = (favoritesProductions: Array<CategoryEntity>) => {
    setFavoritesProductions(favoritesProductions);
  };

  /*Subscriptions */
  /* handle subscriptions */
  const onSuccessSubscriptionList = (data: Array<SubscriptionEntity>) => {
    setSubscriptionsList([
      {
        name: "Alquiler",
        description: "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
        items: [
          "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
          "El alquiler estará habilitado durante un periodo de 48 horas.",
        ],
        images: [subscriptionsUfo],
        tag: "",
        tyc: "terminos-y-condiciones",
        minimumTermLabel: "Valor variable según contenido",
        callToAction: () => {
          navigateTo("/rentals");
        },
        callToActionLabel: "Ver precios",
      },
      ...data.map((item: any) => ({
        name: item.name,
        description: item.description || "Sin descripción",
        images:
          item.images.length > 0
            ? item.images
            : [
                "https://files.stripe.com/links/MDB8YWNjdF8xTTJoeVpLZThGN0dHYktmfGZsX3Rlc3RfMlZKVHM2SHZMZFNlTzAyVU5Nank1YVk000A74faIZd",
              ],
        tag:
          `${item.default_price.recurring.interval_count} ${item.default_price.recurring.interval}${
            item.default_price.recurring.interval_count > 1 ? "s" : ""
          }` || "",
        tyc: "content/terminos-y-condiciones",
        items: [item.description || "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo"],
        price: item.default_price
          ? {
              id: item.default_price.id,
              unitAmount: item.default_price.unit_amount,
              currency: item.default_price.currency,
              interval: item.default_price.recurring?.interval || null,
            }
          : undefined,
      })),
      {
        name: "Empresas",
        items: [
          "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
        ],
        images: [companySubscription],
        tag: "",
        tyc: "content/terminos-y-condiciones",
        minimumTermLabel: "",
        callToActionLabel: "Contáctanos",
        callToAction: () => {
          window.location.href = "mailto:empresas@storytraining.com";
        },
      },
    ]);
  };

  const handleCallToActionCard = (priceId: string) => {
    navigateTo(`/subscription-detail?priceId=${priceId}`);
  };

  useCustomEffect(() => {
    showLoading();
    getFiltered({
      filter: productionFilter,
      success: successProductionFiltered,
      error: errorProductionFiltered,
    });
    getHomeData({
      success: onGetHomeDataSuccess,
    });
    getAll({
      success: successGamePodcastProductions,
      error: errorGamePodcastProductions,
    });
    getFavorites({
      success: onGetHomeFavoritesSuccess,
    });
    get({
      success: onSuccessSubscriptionList,
    });

    getlistLearningPathsToRentToRent({
      success: successGetListOfLearningPathsToRent,
      error: errorGetListOfLearningPathsToRent,
    });
  }, []);

  const quotes = Array(5).fill({
    quote:
      '"La educación debe verse diferente, el mundo ha cambiado.StoryTraining es una apuesta dinámica y didáctica de aprendizaje."',
    quoteBy: "Mateo",
    position: "Diseñador UX",
    course: "Curso en Management",
  });

  return {
    token,
    userSubscription,
    homeData,
    favoritesProductions,

    productions,
    podcastProductions,
    gameProductions,

    subscriptionsList,
    handleCallToActionCard,
    navigateTo,
    quotes,
    listLearningPathsToRent,
    onGetGameById,
    onSuccessSubscriptionList,
  };
}
