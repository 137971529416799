import tw from "twin.macro";
import styled from "styled-components";

export const StyledNotification = styled.div.attrs({ className: "StyledNotification" })`
  ${tw`flex flex-row justify-between w-full bg-secondary-50  my-2 lg:(w-full rounded-3xl bg-secondary-200 items-center justify-between px-6) relative`}

  .bellNotificationLogo {
    ${tw`w-4 h-4`}
  }

  .storyTrainingLogo {
    ${tw`w-10 h-10 hidden lg:(block) flex-shrink-0`}
  }

  .trashCanLogo {
    ${tw`hover:cursor-pointer  h-[18px] lg:(h-6) flex-shrink-0`}
  }

  p {
    ${tw`text-white text-left text-sm w-full break-words my-auto px-4 py-4 lg:(font-primaryRegular text-base) w-full`}
  }

  span {
    ${tw`absolute bottom-2 right-20 capitalize text-gray-100 text-xs`}
  }
`;
