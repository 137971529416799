import tw from "twin.macro";
import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import { Pagination, PaginationProps } from "@mui/material";
import { ReactNode } from "react";

export const StyledPaginatorContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`relative flex items-center justify-center px-11`}
`;

export const StyledPaginationWithChildren = styledMui(
  Pagination
)<PaginationProps>(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  color: "#fff",
  "& .MuiPagination-ul": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& > li": {
      display: "none",
    },
    "& > :first-of-type": {
      display: "list-item",
    },
    "& > :last-of-type": {
      display: "list-item",
    },
    "& .MuiPaginationItem-root": {
      // backgroundColor: "#C1101A",
      color: "#fff",
      "& .MuiSvgIcon-root": {
        // content:
        //   '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.3335L12.6667 13.0002L1 24.6668" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      },
      "& .Mui-selected": {
        backgroundColor: "#C1101A",
      },
    },
  },
}));
