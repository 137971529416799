import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useCaseCourseDetail, useCaseCourses } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { CoursesDataService } from "../../../data/services/course.data.service";
import { CoursesEntity } from "../../../domain/entities";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gradeSchema } from "../../validators/gradeSchema";
import toast from "react-hot-toast";


interface CourseInterface {
  uuid: string,
  title: string;
  description: string,
  production_num: string,
  completed: string,
  production_score: number,
  assessment_score: number,
  final_grade: number,
  feedback: number
}

interface CoursesResponse {
  current_page: number
  has_more_pages: number
  per_page: number
  total_items: number
  total_pages: number;
  courses: CourseInterface[]
}


export function useStudentViewModel() {

  const { getCurrentPathName, navigateTo, getQueryParam } = useNavigation();

  const [courses, setCourses] = useState<CoursesResponse>
    ();


  const { getStudentCourse } =
    useCaseCourseDetail(CourseDetailsDataService());

  const getCoursesSuccess = async (courses: CoursesResponse) => {
    setCourses(courses)
  }


  const getCoursesError = async (Error: AxiosError) => {
    toast.error("")
  }



  const getStudentCourseGrades = (page: number) => {
    getStudentCourse({
      success: getCoursesSuccess,
      error: getCoursesError,
      currentPage: page
    })
  }

  useCustomEffect(() => {
    getStudentCourseGrades(1)
  }, []);

  return {
    getCurrentPathName,
    navigateTo,
    courses,
    handlePageNewPage: getStudentCourseGrades
  };
}
