import {
  CheckCircle,
  XCircle,
  AlertTriangle,
  SortAsc,
  SortDesc,
} from "lucide-react";
import { GradeTableItem } from "../GradeTableItem/GradeTableItem";

interface gradesInterface {
  id: number;
  name: string;
  email: string;
  completed: false;
  productionScore: number;
  assessmentScore: number;
  finalGrade: any;
  feedback: string;
  courseUserId: number;
}

interface GradebookTableProps {
  currentCourse: string;
  students: gradesInterface[];
  onUpdateGrade: (
    studentId: string,
    assessmentId: string,
    score: number
  ) => void;
  sortConfig: {
    key: string;
    direction: "asc" | "desc";
  };
  onSort: (config: any) => void;
}

export default function GradebookTable({
  students,
  onUpdateGrade,
  sortConfig,
  onSort,
  currentCourse,
}: GradebookTableProps) {
  const handleSort = (key: string) => {
    onSort({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <SortAsc className="h-4 w-4" />
    ) : (
      <SortDesc className="h-4 w-4" />
    );
  };

  return (
    <div className="bg-secondary-100 rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-x-auto">
        {students.length > 0 ? (
          <div className="">
            <table className="min-w-full divide-y divide-gray-700 ">
              <thead className="sticky top-0">
                <tr className="bg-primary-50">
                  <th
                    className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center space-x-1">
                      <span>Estudiante</span>
                      {renderSortIcon("name")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Puntuación de producción
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Puntuación de la evaluación{" "}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Calificación final
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                    Terminada{" "}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {students.map((student) => (
                  <GradeTableItem
                    {...student}
                    currentCourse={currentCourse}
                    key={student.id}
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex items-center justify-center h-60 font-primaryBold text-white text-xl">
            <p>Ninguna usuario ha tomado este curso</p>
          </div>
        )}
      </div>
    </div>
  );
}
