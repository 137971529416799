import { AxiosError } from "axios";
import { useLayoutEffect, useRef, useState } from "react";
import { useCaseCourseDetail, useCaseProductions } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useSelector } from "react-redux";
import { getSessionToken, getSubscriptionStatus, getUser } from "../../../data/dto/selectors";
import { ChapterEntity, CourseSession, ICoursesData, LearningPathDetailEntity, PriceEntity, ProductionEntity, QuizEntity, SeasonEntity } from "../../../domain/entities";
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { productionsDataService } from "../../../data/services";
import toast from "react-hot-toast";
import { ProductionCommentsModel } from "../../../data/models";
import { useDispatch } from "react-redux";
import { CourseDetailsDataService } from "../../../data/services/courseDetails.data.service";
import { CourseDetailModel } from "../../../data/models/courseDetail.model";


export function useCreateAssementViewModel() {
  const dispatch = useDispatch();
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);
  const { getCourseDetails, postPostRentCourse } =
    useCaseCourseDetail(CourseDetailsDataService());

  const playerRef = useRef<VideoJsPlayer | null>(null);
  const { getQueryParam, navigateTo } = useNavigation();
  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });
  const [openSubcriptionModal, setOpenSubcriptionModal] = useState(false)
  const { getChapter } = useCaseProductions(productionsDataService())
  const [detailCourse, setDetailCourse] =
    useState<ICoursesData>({} as ICoursesData);

  const [userCanComment, setUserCanComment] = useState(false);

  const [detailGoldMedal, setDetailGoldMedal] =
    useState<LearningPathDetailEntity | null>();
  const [videoPlayerCurrentTime, setVideoPlayerCurrentTime] =
    useState<number>(0);
  const [showLogInModal, setShowLoginModal] = useState(false);
  const [showStartPathModal, setShowStartPathModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [nextChapter, setNextChapter] = useState<ChapterEntity>()
  const [currentChapter, setCurrentChapter] = useState<ChapterEntity>({} as any)
  const [roles, setRole] = useState<string>("")
  const courseuuid = getQueryParam("uuid");


  const [filteredUserComments, setFilteredUserComments] = useState<ProductionCommentsModel>({} as ProductionCommentsModel)

  const [videoOptions, setVideoOptions] = useState<VideoJsPlayerOptions>({
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      fullscreenToggle: false,
      currentTimeDisplay: true,
    },
    responsive: true,
    fluid: true,
  });
  const [videoTimeLeft, setVideoTimeLeft] = useState<number>(0);

  const [showNotSubscribedModal, setShowNotSubscribedModal] = useState(false);
  const [transactionFinalStatus, setTransactionFinalStatus] = useState<
    "none" | "success" | "error"
  >("none");
  const [showTransactionModal, setShowTransactionModal] = useState(false);


  // Learning paths details starts
  function calculateDurations(data: any) {
    // Initialize accumulators
    let totalDuration = 0;
    let totalTotalChapterDuration = 0;
    // Iterate through the data array
    data.forEach((item: any) => {
      // Add the top-level duration
      if (item.duration !== undefined) {
        totalDuration += item.duration;
      }
      // Add durations from chapters array if available
      if (Array.isArray(item.chapters)) {
        item.chapters.forEach((chapter: any) => {
          if (chapter.progress !== undefined) {
            totalTotalChapterDuration += chapter.progress;
          }
        });
      }
    });

    // Return the results
    return {
      totalDuration,
      totalTotalChapterDuration,
    };
  }

  // learning paths chapter
  function successGetProductionChapter(chapter: ChapterEntity) {
    if (chapter) {
      setCurrentChapter(chapter)
      handleNewVideoSource(chapter.videoUrl, chapter.imgPreview)
    }
  }

  function errorGetProductionChapter(e: AxiosError) {
    toast.dismiss()
    toast.error("Detalles del capítulo no encontrados")
  }

  const changeProduction = (productionNumber: number) => {

  }

  const onGetChapter = (chapterUuid: string) => {
    getChapter({
      success: successGetProductionChapter,
      error: errorGetProductionChapter,
      id: chapterUuid
    })
  }

  const handleNewVideoSource = (source: string, poster?: string) => {
    if (source) {
      setVideoOptions({
        ...videoOptions,
        autoplay: "play",
        sources: [
          {
            src: source,
            type: "application/x-mpegURL",
          },
        ],
        poster: poster ? poster : videoOptions.poster,
      });
    } else {
      setVideoOptions({
        ...videoOptions,
        poster: poster ? poster : videoOptions.poster,
      });
    }
    window.scrollTo(0, 0);
  };


  function handleNotSubscribedModal(value: boolean) {
    setShowNotSubscribedModal(value);
  }

  function handleTransactionModal() {
    setShowTransactionModal(false);
  }

  function handleStartPath() {
    if (token) {
      setShowStartPathModal(true);
    } else {
      setShowLoginModal(true);
    }
  }

  function successPostCourseDetails(e: any) {
  }

  function errorPostCourse(e: AxiosError) {
    if (e?.response?.status === 401) {
      setShowLoginModal(true);
    } else if (e?.response?.status === 403) {
      setShowNotSubscribedModal(true);
    }
  }

  const handleStartCourse = () => {
    if (token) {
      postPostRentCourse({
        success: successPostCourseDetails,
        error: errorPostCourse,
        courseUuid: courseuuid,
      });
    } else {
      navigateTo("/login")
    }
  };


  const handleShowModal = () => {
    setOpenSubcriptionModal(!openSubcriptionModal)
  }

  function handleRedirectToFirstProduction() {
    setSuccessModal(false);
  }

  const handlePlayerReady = (player: VideoJsPlayer) => {
    let intervalProgress: number = 0;
    let intervalCurrentTime: number = 0;
    playerRef.current = player;
    if (userSubscription?.stripeStatus !== "active") {
      player.controlBar.addClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    } else {
      player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    }
    // You can handle player events here, for example:
    player.on("pause", () => {
      const currentTime = player.currentTime();
      const timeLeft = player.duration() - player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      setVideoTimeLeft(timeLeft);
      player.clearInterval(intervalProgress);
      player.clearInterval(intervalCurrentTime);
    });

    player.on("play", () => {
      if (userSubscription?.stripeStatus !== "active") {
        player.controlBar.addClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.addClass("vjs-hidden-on-unsubscribe");
      } else {
        player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.removeClass("vjs-hidden-on-unsubscribe");
      }

      intervalProgress = player.setInterval(() => {
        const currentTime = player.currentTime();
        setVideoPlayerCurrentTime(currentTime);
      }, 5000);
    });

    player.on("dispose", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });

    player.on("ended", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });

    player.on("timeupdate", () => {
      const timeLeft = player.duration() - player.currentTime();
      setVideoTimeLeft(timeLeft);
    });
  };

  const handleChapterProgress = (
    chapterData: ChapterEntity,
    currentChapter: ChapterEntity
  ) => {
    if (currentChapter.uuid === chapterData.uuid) {
      // return chapter with the new progress
      return { ...chapterData, progress: Math.floor(videoPlayerCurrentTime) };
    } else {
      return chapterData;
    }
  };

  useCustomEffect(() => {
    // if (currentChapter?.uuid && detailLearningPath.canWatch && currentChapter.uuid) {
    //   const timeRangesChapter = currentChapter.timeRanges
    //     ? currentChapter.timeRanges
    //     : [[0, 1]];
    //   const { timeRanges } = handleAllTimeRangesArray(timeRangesChapter);
    //   handleFindCurrentChapterProgress();
    //   postChapterProgressDashboard({
    //     progress: Math.floor(videoPlayerCurrentTime),
    //     chapterUuid: currentChapter.uuid,
    //     timeRanges,
    //   });
    // }
  }, [videoPlayerCurrentTime]);

  const successGetCouresDestails = (courseDetails: CourseDetailModel) => {
    const courseProductions = courseDetails.productions.items.map((production) => {
      return {
        completed: production.completed ? production.completed : 0
        , id: production.uuid, title: production.title, duration: production.duration, description: production.description, chapter: production.chapters, quiz: production.quiz, accessmentType: "production", link: "", available: 0, imageUrl: production.imgPreview
      }
    })
    const courseAssessment = {
      completed: 0,
      available: 0
      , id: detailCourse.assessment.id, title: courseDetails.assessment.title, duration: 0, description: "", chapter: [], quiz: courseDetails.assessment.quiz, accessmentType: "assessment", link: "", imageUrl: ""
    }
    const data = {
      uuid: courseDetails.uuid,
      title: courseDetails.title,
      progress: courseDetails.progress,
      duration: 0,
      image: courseDetails.image,
      courseSection: [...courseProductions, courseAssessment],
      assessment: courseDetails.assessment,
      canWatch: courseDetails.canWatch,
      price: courseDetails.price,
      assessmentPresent: courseDetails.assessment ? true : false
    }
    setDetailCourse(data)
  }
  const errorGetCouresDestails = (error: any) => {
    // toast.error("Ocurrió un error")
  }

  useLayoutEffect(() => {
    getCourseDetails({
      success: successGetCouresDestails,
      error: errorGetCouresDestails, courseUuid: courseuuid,
      perPage: 3,
      currentPage: 1
    })
  }, [])

  useCustomEffect(() => {
    if (currentChapter?.uuid && playerRef?.current?.player_) {
      const playerOut = playerRef.current;
      playerOut.ready(function () {
        var promise = playerOut.play();
        if (promise !== undefined) {
          promise
            .then(function () {
              console.log("Autoplay started");
            })
            .catch(function (error) {
            });
        }
      });
      playerOut.currentTime(currentChapter.progress);
    }
  }, [currentChapter]);

  return {
    roles,
    sizeScreen,
    userSubscription,
    detailGoldMedal,
    showLogInModal,
    setShowLoginModal,
    showStartPathModal,
    setShowStartPathModal,
    showSuccessModal,
    navigateTo,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
    videoOptions,
    handleNewVideoSource,
    currentChapter,
    nextChapter,
    handlePlayerReady,
    videoTimeLeft,
    userCanComment,
    onChapterClick: onGetChapter,
    changeProduction,
    filteredUserComments,


    token,
    handleStartCourse, detailCourse, openSubcriptionModal, handleShowModal
  };
}
