import { StyledParagraph, StyleButton, StyledList } from "./ProfileDropDown.styles";
import avatar from "../../assets/images/Avatar.png";
import { FC } from "react";
import { useProfileDropDown } from "./useProfileDropDown";
import { useLogoutViewModel } from "../../viewModels/logoutViewModel";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

export const ProfileDropDown: FC = () => {
  const { user, userAvatar, onToggleDropDown, selectValue, navigateTo } = useProfileDropDown();

  const { onSubmit } = useLogoutViewModel();
  return (
    <>
      <div className="flex items-center ">
        <div className="relative flex items-center hidden lg:inline-block">
          <StyleButton onClick={onToggleDropDown} color="secondary" className="flex items-center whitespace-nowrap">
            <StyledParagraph color="secondary">{`¡Hola, ${user?.name.split(" ")[0]}!`}</StyledParagraph>
            <Avatar
              sx={{ width: { xs: 30, md: 55 }, height: { xs: 30, md: 55 } }}
              src={userAvatar?.fileUrl ? userAvatar?.fileUrl : avatar}
              alt={`Imagen usuario`}
              title={`Imagen usuario`}
            />
          </StyleButton>
          {selectValue && (
            <StyledList>
              <li onClick={() => navigateTo("/profile")}>Mi Perfil</li>
              <li onClick={() => navigateTo("/dashboard")}>Mi Story Training</li>
              {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
                <li onClick={() => navigateTo("/confirm-session")}>Sesiones de Aprendizaje</li>
              )}
              {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
                <li onClick={() => navigateTo("/courses")}>Cursos </li>
              )}
              {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
                <li onClick={() => navigateTo("/teacher/gradebook")}>Libro de calificaciones </li>
              )}
              {(user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "owner") ||
                (user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "regular") ||
                (user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "user" && (
                  <li onClick={() => navigateTo("/student-dashboard")}>Curso de estudiante</li>
                ))}
              {((user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "owner") ||
                (user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "regular")) && (
                <li onClick={() => navigateTo("/student-dashboard")}>Curso del Estudiante</li>
              )}
              <li onClick={onSubmit}>Cerrar Sesión</li>
            </StyledList>
          )}
        </div>
      </div>
      <div className="lg:hidden inline-block mobile-menu">
        <li onClick={() => navigateTo("/profile")}>Mi Perfil</li>
        <li onClick={() => navigateTo("/dashboard")}>Mi Story Training</li>
        {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
          <li onClick={() => navigateTo("/confirm-session")}>Sesiones de Aprendizaje</li>
        )}
        {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
          <li onClick={() => navigateTo("/courses")}>Cursos </li>
        )}
        {user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "professor" && (
          <li onClick={() => navigateTo("/teacher/gradebook")}>Libro de calificaciones </li>
        )}
        {(user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "owner") ||
          (user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "regular") ||
          (user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "user" && (
            <li onClick={() => navigateTo("/student-dashboard")}>Curso de estudiante</li>
          ))}
        {((user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "owner") ||
          (user?.roles[0] && user?.roles[0].slug && user?.roles[0].slug === "regular")) && (
          <li onClick={() => navigateTo("/student-dashboard")}>Curso del Estudiante</li>
        )}
        <li onClick={onSubmit}>Cerrar Sesión</li>
      </div>
    </>
  );
};
