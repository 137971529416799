import { FC } from "react";
import { Button, Modal, TextFieldControlled } from "../../../../components";
import { useUpdateStudentScoreViewModel } from "../../../../viewModels";

interface GradeTableItemProps {
  id: number;
  title: string;
  description: string;
  course_uuid: string;
  quiz_type: string;
  difficulty_level: string;
  passing_score: number;
  duration: number;
  is_published: true;
  created_at: string;
  updated_at: string;
  taken_at: string;
  grade: number;
  questions_count: number;
  correct_answers: number;
  feedback: null;
  grade_id: number;
}

export const TeacherStudentGradeTableItem: FC<GradeTableItemProps> = ({
  id,
  title,
  description,
  course_uuid,
  quiz_type,
  difficulty_level,
  passing_score,
  duration,
  is_published,
  created_at,
  updated_at,
  taken_at,
  grade,
  grade_id,
  questions_count,
  correct_answers,
  feedback,
}) => {
  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    showModal,
    handleShowModal,
    handleCloseModal,
  } = useUpdateStudentScoreViewModel();
  return (
    <tr key={`${id}-${id}`} className="hover:bg-gray-700">
      <td className="px-6 py-4 whitespace-nowrap">
        <div>
          <div className="text-sm font-medium text-white">{title}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {quiz_type}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {difficulty_level}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {grade}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        {feedback ? feedback : "Nil"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
        <button
          className="bg-transparent py-1 text-primary-50"
          onClick={() => {
            handleShowModal(grade_id);
          }}
        >
          Añadir comentarios
        </button>
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          backgroundColor="black"
        >
          <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-inputForm">
            <TextFieldControlled
              id={"feedback"}
              name="feedback"
              label="Comentario"
              placeholder=""
              error={!!errors.feedback}
              helperText={errors?.feedback?.message}
              control={control}
              required
            />

            <TextFieldControlled
              id={"score"}
              name="score"
              label="Puntaje"
              placeholder=""
              error={!!errors.score}
              helperText={errors?.score?.message}
              control={control}
              required
              type="number"
            />
            <Button className="w-full" onClick={handleSubmit(onSubmit)}>
              Entregar
            </Button>
          </div>
        </Modal>
      </td>
    </tr>
  );
};
