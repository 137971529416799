import tw from "twin.macro";
import styled from "styled-components";
import { Modal } from "../../components";
export interface StyledSectionProps {
  withoutPadding?: boolean;
  withoutYPadding?: boolean;
}

export const StyledHomeContainer = styled.section.attrs({
  className: "StyledHomeContainer",
})`
  ${tw`relative w-full`}

  .StyledSection:nth-child(odd) {
    ${tw`bg-secondary-300`}
  }

  .StyledSection:nth-child(even) {
    ${tw`bg-secondary-200`}
  }
`;

export const StyledSection = styled.section.attrs((props) => ({
  className: props.className,
}))<StyledSectionProps>`
  ${tw`relative w-full py-16 px-5 flex flex-col text-center items-center`}

  > h2, > article > h2 {
    ${tw`text-xl leading-9 font-primaryBold text-white sm:(text-[2rem] leading-[2.375rem])`}
    span {
      ${tw`text-primary-100`}
    }
  }

  > h3 {
    ${tw`text-sm font-primaryBold text-white sm:(text-2xl)`}
    span {
      ${tw`text-primary-100`}
    }
  }

  > p,
  > article > p {
    ${tw`max-w-[67.5rem] text-xl leading-6 text-white`}
  }
  ${({ withoutPadding }) => withoutPadding && tw`px-0 lg:px-5`}
  ${({ withoutYPadding }) => withoutYPadding && tw`py-0`}
`;

export const StyledContainerCarousel = styled.section.attrs({
  className: "StyledContainerCarousel",
})`
  ${tw`max-w-theme mx-auto w-full lg:px-10 px-0 mb-20`}
`;

export const StyledModal = styled(Modal).attrs({
  className: "StyledModal",
})`
  ${tw`place-content-start justify-center absolute w-full`}
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  .StyledInformationSection,
  .StyledSubscribedContent {
    > * {
      ${tw`max-w-favoriteDashboard mx-auto`}
    }
  }
`;
