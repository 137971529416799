import { BookOpen, Clock4, Play, Users } from "lucide-react";
import { DefaultLayout } from "../../layouts";
import { StudentSelector } from "./components";
import { VideoPreview } from "../preview/components";
import {
  convertToMinutesAndSeconds,
  useNavigation,
  useWindowSize,
} from "../../hooks";
import { useCourseDetailsDetailViewModel } from "../../viewModels";
import {
  Breadcrumb,
  Button,
  Modal,
  Paginator,
  TextFieldControlled,
} from "../../components";
import { useLocation } from "react-router";

export const RegisterUserPage = () => {
  const { getQueryParam } = useNavigation();
  const courseId = getQueryParam("course");

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Curso",
      url: `/course-detail?uuid=${courseId}`,
    },
    {
      name: "Registrar estudiante",
    },
  ];
  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StudentSelector />
    </DefaultLayout>
  );
};
