import React, { createContext, useContext, useState, ReactNode } from "react";
import { StyledBlurWall } from "../pages/preview/preview.styles";

interface ModalContextType {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  return (
    <ModalContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 backdrop-blur-lg">
          <StyledBlurWall />
          <div
            className="loader border-t-8 border-red-500 border-solid rounded-full w-24 h-24 animate-spin"
            style={{ zIndex: 9999 }}
          ></div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
